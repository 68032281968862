

export class TypologieModel {
  public id_typologie: number;
  public code: string;
  public label: string;
  public icon: string;
  public alias: string;
  public color: number;

  constructor(typologie: any) {
    this.id_typologie = typologie.id_typologie;
    this.code = typologie.code;
    this.label = typologie.label;
    this.icon = typologie.icon;
    this.alias = typologie.alias;
    this.color = typologie.color;
  }

  // Retourne une liste des typologies
  static ToTypologieArray(data: TypologieModel[] = []): TypologieModel[] {
    const typologies: TypologieModel[] = [];
    data.forEach(item => {
      const typologie: TypologieModel = new TypologieModel(item);
      typologies.push(typologie);
    });
    return typologies;
  }
}
