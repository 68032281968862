import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';
import { INCIDENT_TYPES } from '@app/config'
import {IncidentService} from "@app/services";

@Component({
  selector: 'app-incidents',
  templateUrl: './incidents.component.html',
  styleUrls: ['./incidents.component.scss']
})
export class IncidentsComponent implements OnInit {

  @Input() incidents:any[] = [];
  INCIDENT_TYPES = INCIDENT_TYPES;
  isRequesting=false;
  //selectedImage=[];
  @Output() selectedImageIncident = new EventEmitter<any>();

  constructor(private _incidentService:IncidentService) { }

  ngOnInit() {
    this.selectedImageIncident.emit([]);
  }

  setStatusInc(event,item){
    item['newStatus'] = event.target.value;
  }

  setIncident(incident){
    this.isRequesting = true;
    const params = { id_incident : incident.id_incident, statut:incident.newStatus };
    this._incidentService.updateIncidentStatus(params).subscribe(
      (response)=>{
        incident.statut=incident.newStatus;
        this.isRequesting = false;
      }
    )
  }

  setSelectedImageInc(image){
    this.selectedImageIncident.emit(image);  
  }
}
