import {Directive, HostListener, Input} from '@angular/core';

@Directive({selector: '[blockCopyPaste]'})
export class BlockCopyPasteDirective {
  @Input('blockCopyPaste') isActivated: boolean;
  constructor() {
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    this.preventEvent(e);
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    this.preventEvent(e);
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    this.preventEvent(e);
  }

  preventEvent(event: any) {
    if (this.isActivated) {
      event.preventDefault();
    }
  }
}
