import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '@app/config';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class TypeService {

  constructor(private _http: HttpClient) {
  }

  getTypes(namespace): Observable<any> {
    const route = this.getRouteByType(namespace);
    return this._http.get(route);
  }

  getRouteByType(type: string) {
    let route;
    switch (type) {
      case 'aquity':
        route = Config.api.typologie.acquity.get;
        break;
      case 'client':
        route = Config.api.typologie.client.get;
        break;
      case 'bo':
        route = Config.api.typologie.bo.get;
        break;
    }
    return route;
  }
}
