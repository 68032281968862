import {Directive, Input, TemplateRef, ViewContainerRef} from "@angular/core";
import {LocalStorageHelper } from '@app/helpers';
import {USER_SlUG} from "@app/config";

@Directive({
  selector: '[cannotAccess]'
})

// Gère l'affichage des éléments graphiques selon le role donné !negation
export class CannotAccessDirective {
  @Input('cannotAccess') set cannotAccess(role: any) {
    const userRole = LocalStorageHelper.getItem(USER_SlUG);
    if (userRole && (role == userRole.role || role.indexOf(userRole.role) !== -1)) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  };

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) {}
}
