import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false,
})
export class FilterPipe implements PipeTransform {

  transform(data: any[], compareString: string, property: string): any[] {
    let properties = property.includes(',') ? property.split(',') : [property];
    if (data.length === 0 || !compareString) {
      return data;
    }
    let filteredDatas: any[] = [];
    for (let item of data) {
      for (let key of properties) {
        if (item[key].toLowerCase().includes(compareString.toLowerCase())) {
          filteredDatas.push(item);
          break;
        }
      }
    }

    return filteredDatas;
  }

}
