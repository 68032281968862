import { Injectable } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {map} from "rxjs/operators";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class RouterService {

  constructor(
    private _router: Router,
    private _route: ActivatedRoute) {
  }

  onRouterChangeOrLoad() : Observable<any>{
    return this._router.events
      .pipe(
        map(() => this._route.snapshot),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
  }

}
