import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '@app/config';
import {Observable} from 'rxjs';
import {UserModel} from '@app/models';

@Injectable({
  providedIn: 'root',
})

export class EncombrantService {

  constructor(private _http: HttpClient) {
  }

  getEncombrants(page: number = 1): Observable<any> {
    return this._http.get(Config.api.encombrant.all + '&page=' + page);
  }

  createEncombrant(encombrant: any): Observable<any> {
    return this._http.post(Config.api.encombrant.create, encombrant);
  }

  updateEncombrant(encombrant: any): Observable<any> {
    return this._http.post(Config.api.encombrant.update, encombrant);
  }

  deleteEncombrant(encombrant): Observable<any> {
    return this._http.post(Config.api.encombrant.delete, encombrant);
  }

}
