import { Component,Input } from '@angular/core';
import { MissionService } from '../../../services/api/mission.service';
import {ToastrService} from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-comptage-gravats-modal',
  templateUrl: './comptage-gravats-modal.component.html',
  styleUrls: ['./comptage-gravats-modal.component.css']
})
export class ComptageGravatsModalComponent {
  dechets=[];
  @Input() id_tournee;

  ngOnInit() {
    this.getTourneeDechets();
  }

  constructor(private missionService: MissionService,
              private _toastr: ToastrService){
     
  }

  getTourneeDechets(){
    let params={
      'id_tournee':this.id_tournee
    };

    this.missionService.getTourneeDechets(params).subscribe(
      response => {
        this.dechets=response.payload;
      },error => {
        this._toastr.error('Erreur de récupération des dechets à compter');
      }
    );
  }

  getQte(event,id_typo_dechet){
    
    let dechet=this.getEncombrant(id_typo_dechet);
    if(event.data && !event.data.match(/^[0-9]+$/g)){
      dechet.qte=0;
      return 0;
    }
    dechet.qte=parseInt(!event.data?0:event.data);
    return true;
  }

  setComptage(){
    let dechets=this.dechets.map(enc => {
        let dechet={
           'id_typo_dechet':enc.id_typo_dechet,
           'qte':enc.qte
        };
        return dechet;
    });
    let params={
      'id_tournee':this.id_tournee,
      'dechets':dechets
    };

    this.missionService.setComptage(params).subscribe(
      response => {
        this._toastr.success('Comptage effectué avec succés');
      },error => {
        this._toastr.error('Erreur au cours du comptage');
      }
    );
  }

  incrementQte(id_typo_dechet){
    let dechet=this.getEncombrant(id_typo_dechet);
    dechet.qte+=1;
  }

  decrementQte(id_typo_dechet){
    let dechet=this.getEncombrant(id_typo_dechet);
    dechet.qte-=1;
    dechet.qte=(dechet.qte<0)?0:dechet.qte;
  }

  updateQte(id_typo_dechet){
    let dechet=this.getEncombrant(id_typo_dechet);
    dechet['toUpdate']=true;
  }

  getEncombrant(id_typo_dechet){
    let dechet=this.dechets.find(dechet=>{
      return dechet.id_typo_dechet==id_typo_dechet;
    });
    return dechet;
  }
}