// lang-es.ts

export const LANG_ES_NAME = 'es';

export const LANG_ES_TRANS = {
    'Rechercher... ex.(Numéro de voyage...)': 'Búsqueda ... Ex. (ID de viaje ...)',
    'Liste des voyages':'LISTA DE VIAJES',
    'Liste des trajets':'LISTA DE RUTAS',
    'Gestion des utilisateurs':'GESTION DE USUARIOS',
    'Gestion des clients':'GESTION CLIENTES',
    'Gestion des chauffeurs':'GESTION CONDUCTORES',
    'Gestion des tracteurs':'GESTION TRACTORAS',
    'Gestion des Remorques':'GESTION REMOLQUES',
    'Points de contrôle':'PUNTO DE CONTROL',
    'Statistiques':'ESTADISTICAS',
    'Configurations':'CONFIGURACION',
    'Déconnexion':'DESCONEXIÓN',

    'Dashboard':'DASHBOARD',
    'Pages':'PAGINAS',
    'Filtre':'FILTRO',
    'Numéro du voyage ou WPT':'NUMERO VIAJE O WPT',
    'Date et heure de départ':'FECHA FORA ORIGEN',
    'Date Départ':'FICHA ORIGEN',
    'Date Arrivée':'FICHA DESTINO',
    'Date':'Fecha',
    'Statut':'ESTATUS',
    'Complété':'COMPLETA',
    'COMPLÉTÉ':'COMPLETA',
    'Planifié':'PLANIFICADO',
    'En cours':'EN CURSO',
    'EN COURS':'EN CURSO',
    'Sans valeur':'NO VALOR',
    'N° Chauffeur,Tracteur,Remorque':'N° CONDUCTOR,TRACTORA,REMOLQUE',
    'Chauffeur':'CONDUCTOR',
    'Tracteur':'TRACTORA',
    'Remorque':'REMOLQUE',
    'Choisir':'ESCOGER',
    'Ville de Départ et Arrivée':'CIUDAD DE ORIGEN Y DESTINACIÓN',
    'Ville Départ':'CIUDAD ORIGEN',
    'Ville Arrivée':'CIUDAD DESTINACIÓN',

    'Administrateur':'ADMINISTRADOR',
    'admin':'ADMINISTRADOR',
    'user' : 'USUARRIO',
    'De':'DESDE',
    'À':'HACIA',
    'Le':'EL',
    'PLANIFIÉ':'PLANIFICADO',
    'Editer':'MODIFICAR',
    'Détails':'DETALLE',
    'Trajet':'TRAMO',
    'effectué':'HECHO',
    'En attente':'A LA ESPERA',

    'Détail du voyage':'DETALLE DEL VIAJE',
    'Détail du trajet':'DETALLE DEL VIAJE',
    'Etat du voyage':'ESTADO DE VIAJE',
    'Etat du trajet':'ESTADO DE VIAJE',
    'Type de Voyage': 'TIPO DE VIAJE',
    'Client':'CLIENTE',
    'Nature':'NATURALEZA',
    'Agrandir la carte':'AMPLIAR MAPA',
    'SUIVI DU VOYAGE':'SEGMUIMIENTO VIAJE',
    'Nombre de WPT':'NUMMERO DE PUNTOS GEOGRAFICOS',
    'DOCUMENTS WPT':'DOCUMENTOS WPT',
    'Type':'TIPO',
    'Chargement':'CARGAS',
    'Déchargement':'DESCARGA',
    'Passage':'PASAJE',
    'Liste des utilisateurs':'LISTA DE USUARIOS',
    'Ajouter un utilisateur':'AÑADIR USUARIO',
    'Recherche par matricule':'BUSQUEDA POR NOMBRE',
    'Numéro User':'NUMERO DE USUARIO',
    'Nom':'NOMBRE',
    'Prénom':'APELLIDO',
    'Login':'USUARIO',
    'Options':'OPCIONES',
    'Email':'CORREO ELECTRONICO',
    'Liste des clients':'LISTA CLIENTES',
    'Ajouter un client':'AÑADIR CLIENTE',
    'Numéro client':'CLIENTE NUMERO',
    'Nom client':'NOMBRE DE CLIENTE',


    'Lier':'ENLAZAR',
    'Recherche par nom client':'BUSQUEDA POR NOMBRE DE CLIENTE',
    'Nom d\'utilisateur':'USUARIO',
    'Mot de passe':'CONTRASEÑA',
    'Se connecter':'CONECTARSE',
    'Mot de passe oublié':'CONTRASEÑA OLVIDADA',
    'Connexion en cours':'CONEXIÓN',
    'Liste des conducteurs':'LISTA CONDUCTORES',
    'Ajouter un conducteur':'AÑADIR CONDUCTOR',

    'Numéro conducteur':'NUMERO CONDUCTOR',
    'Nom conducteur':'NOMBRE Y APELLIDO CONDUCTOR',
    'Code': 'CODIGO',
    'Code Département':'DEPARTAMENTO CODIGO',
    'Code Département Société':'DEPARTAMENTO SOCIEDAD CODIGO',
    'Recherche par nom conducteur':'BUSQUEDA POR NOMBRE DE CONDUCTOR',
    'Supprimer':'BORRAR',
    'Liste des tractors':'LISTA TRACTORAS',
    'Ajouter un tractor':'AÑADIR TRACTORA',
    'Numéro Tractor':'TRACTOR NUMERO',
    'Matricule':'MATRICULA',
    'Liste des remorques' : 'LISTA REMOLQUES',
    'Ajouter une remorque' : 'AÑADIR REMOLQUE',
    'Numéro Remorque':'REMOLQUE NUMERO',
    'Type de vehicule':'TIPO DE VEHÍCULO',
    'Point de contrôle':'PUNTO DE CONTROL',
    'Recherche par nom du point de contrôle':'BUSQUEDA POR NOMBRE DE PUNTO DE CONTROL',
    'Qualification':'CALIFICACIÓN',
    'Photo':'FOTO',
    'Description':'DESCRIPCIÓN',
    'Liste des configurations':'CONFIGURACION',
    'Fréquence de pull':'FRECUENCIA PULL',
    'Numéro urgence':'NUMERO URGENCIA',
    'Message urgence':'MENSAJE URGENCIA',
    'Enregistrer':'GUARDAR',

    'En minutes' : 'En minutos',
    'voir l\'image' : 'ver imagen',
    'Afficher' : 'MOSTRAR',
    'Vitesse' : 'velocidad',

    'Modification des données du client' : 'MODIFICAR DATOS CLIENTE',
    'Ajout d\'un nouveau client' : 'AÑADIR NUEVO CLIENTE',
    'client updated' : 'CLIENTE ACTUALIZADO',
    'Le Nom client est obligatoire' : 'NOMBRE DE CLIENTE OBLIGATORIO',
    'Fermer' : 'CERRAR',
    'Close' : 'CERRAR',

    'Modification des données du conducteur' : 'MODIFICAR DATOS CONDUCTOR',
    'Ajout d\'un nouveau conducteur' : 'AÑADIR NUEVO CONDUCTOR',
    'conductor updated' : 'CONDUCTOR ACTUALIZADO',
    'Numéro Conductor' : 'NUMERO CONDUCTOR',
    'Nom Conductor' : 'NOMBRE CONDUCTOR',
    'Code société' :  'SOCIEDAD CODIGO',
    'Le login est obligatoire' : 'USUARIO OBLIGATORIO',

    'NOUVEAU POINT DE CONTRÔLE' : 'NUEVO PUNTO DE CONTROL',
    'MIS A JOUR DU POINT DE CONTRÔLE' : 'MIS A JOUR DU POINT DE CONTRÔLE',
    'Données enregistrées' : 'DATOS REGISTRADOS',
    'Données non enregistrées' : 'DATOS NO REGISTRADOS',
    'Le nom du point de contrôle existe déjà' : 'El nombre del punto de control ya existe',
    'Nom du point de contrôle' : 'NOMBRE DE PUNTO DE CONTROL',
    'Le Nom du point de contrôle est obligatoire' : 'NOMBRE DE PUNTO DE CONTROL OBLIGATORIO',
    'Type de contrôle' : 'TIPO DE CONTROL',
    'OK' : 'OK',
    'Non OK' : 'NOK',
    'Valeur(s)' : 'VALOR (VALORES)',
    'Valeur' : 'VALOR',
    'Le type de contrôle est obligatoire' : 'EL TIPO DE CONTROL ES OBLIGATORIO',
    'Ajouter une valeur' : 'AÑADIR UN VALOR',
    'Choisir une image' : 'ESCOGER UNA FOTO',

    'Modification des données de la remorque' : 'MODIFICAR DATOS DEL REMOLQUE',
    'Ajout d\'une nouvelle remorque' : 'AÑADIR UN NUEVO REMOLQUE',
    'Remorque updated' : 'REMOLQUE ACTUALIZADO',
    'Le matricule ne doit pas dépasser 16 caractères' : 'LA MATRICULA NO DEBE TENER MAS DE 16 CARACTERES',
    'successfully' : 'EXITO',
    'ERROR' : 'ERROR',
    'INTERNAL_ERROR' : 'Error interno. Vuelva a intentarlo mas tarde !',
    'Type remorque' : 'TIPO REMOLQUE',
    'Longitude' : 'LONGITUD',
    'Latitude' : 'LATITUD',
    'incorrecte' : 'INCORRECTO',
    'Exemple' : 'EJEMPLO',
    'Choisir sur la carte' : 'ESCOGER SOBRE MAPA',

    'Modification des données du tracteur' : 'MODIFICAR DATOS TRACTORA',
    'Ajout d\'un nouveau tracteur' : 'AÑADIR UN NUEVO TRACTORA',
    'tractor updated' : 'TRACTORA ACTUALIZADO',
    'Numéro Tracteur' : 'NUMERO TRACTORA',
    'ID de l\'appareil' : 'ID DEL APARATO',
    'ID doit être alphanumérique' : 'ID DEBE SER ALFANUMERICO',

    'Modification des données du véhicule' : 'MODIFICAR DATOS VEHICULO',
    'Ajout d\'un nouveau type véhicule' : 'AÑADIR TIPO VEHICULO',
    'Type ajouté' : 'TIPO AÑADIDO',
    'Le Nom est obligatoire' : 'NOMBRE OBLIGATORIO',
    'Contacter' : 'CONTACTAR',
    'Alarme' : 'Alarma',
    'DOCUMENTS VOYAGE' : 'DOCUMENTOS VIAJE',
    'Département' : 'DEPARTAMENTO',
    'Département société':'DEPARTAMENTO SOCIEDAD',
    'Documents liés au voyage' : 'DOCUMENTOS DEL VIAJE',
    'Télécharger' : 'DESCARGAR',
    'Image' : 'Foto',
    'Liste des chauffeurs liés au voyage' : 'LISTA CONDUCTORES DEL VIAJE',
    'Liste des Tracteurs liés au voyage' : 'LISTA TRACTORAS DEL VIAJE',
    'Liste des Remorques liés au voyage' : 'LISTA REMOLQUES DEL VIAJE',
    'Liste des Clients liés au voyage' : 'LISTA CLIENTES DEL VIAJE',
    'MAP TRACKING' : 'SEGUIMIENTO EN MAPA',
    'ETAT DU WORKFLOW' : 'ESTADO DEL FLUJO DE TRABAJO',
    'Valider' : 'VALIDAR',
    'Annuler' : 'ANULAR',
    'Valider tous' : 'VALIDAR TODO',
    'Liste des chargements':'LISTA DE CARGAS',
    'Liste des déchargements':'LISTA DE DESCARGAS',
    'Chargement pour le client':'CLIENTE DE CARGA',
    'Déchargement pour le client':'CLIENTE DE DESCARGA',
    'Adresse':'DIRECCIÓN',
    'Bougez le marker pour en déduire les coordonnées' : 'Mueve el marcador para deducir las coordenadas',
    'Recherche' : 'BUSQUEDA',
    'Points de contrôle pour':'PUNTOS DE CONTROL PARA',
    'Tous les points de contrôle':'TODOS LOS PUNTOS DE CONTROL',
    'Next' : 'SIGUIENTE',
    'Previous' : 'ANTERIOR',
    'Loading' : 'Cargando',
    'TripNotStartedYet' : 'El viaje aún no ha comenzado',

    'Réclamations': 'RECLAMACIONES',
    'Depart' : 'Salida',
    'Arrivee' : 'llegada',
    'Localiser' : 'Localizar',
    'UnavailableConductorPosition' : 'Posición del conductor no disponible',
    'InvalideDate': 'Fecha inválida',


    'Référence' : 'Referencia',
    'Sélectionner' : 'Seleccionar',
    'Clôturée' : 'Cerrado',
    'A réparer' : 'Debe ser reparado',
    'Emetteur' : 'Emisor',
    'Document' : 'Documento',
    'Pas de résultats':'Sin resultados',
    'defaultPwdMobile':'Contraseña por defecto',
    'En ligne' :'En línea',
    'Hors ligne' :'Fuera de linea',
    'Messages non lus' : 'Mensajes no leídos',
    'Ecrire quelque chose ...' : 'Escribe algo ...',

  /**            Global              **/
    'semi_r':'Semi remolque',
    'chargement':'Carga',
   'dechargement':'Descarga',


  /** ======================= Permission ================================ **/
  'permission.route':'Manejo de carretera',
  'permission.new_trip':'Nuevo viaje',
  'permission.commande':'Gestión de pedidos',
  'permission.user':'Gestión de usuario',
  'permission.role':'Gestión de roles',
  'permission.client':'Gestión de clientes',
  'permission.line':'Lista de viajes',
  'permission.conductor':'Gestión del conductor',
  'permission.tractor':'Gestión de tractores',
  'permission.remorque':'Gestión de remolques',
  'permission.sr_control':'Puntos de control',
  'permission.reclamation':'Quejas',
  'permission.dispo':'Disponibilidad',
  'permission.statistic':'Estadística',
  'permission.config':'Configuraciones',
  'permission.trip':'Lista de viajes',

  'permission.lecture':'Lector',
  'permission.modification':'Cambio',
  'permission.creation':'Creación',
  'permission.suppression':'Supresión',
  'permission.list.acces':'Lista de accesos',
  'permission.prestataire':'Lista de proveedores',
  /** ======================= End Permission ================================ **/
  ' Disponibilité':' Disponibilidad',
  'Nouveau Voyage':'Nuevo viaje',
  'Disponibilité des moyens':'Disponibilidad de recursos',
  'Gestion des roles':'Gestión de roles',
  'Gestion des routes':'Manejo de carretera',
  'Liste des roles' : 'Lista de roles',

  'slug_client' : '',
};
