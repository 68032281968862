import { Component, OnInit } from '@angular/core';

declare const $:any;

@Component({
  selector: 'app-taost',
  templateUrl: './taost.component.html',
  styleUrls: ['./taost.component.scss']
})
export class TaostComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {

  }

}
