import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '@app/config';
import {Observable} from 'rxjs';
import {PrestataireModel} from '@app/models';

@Injectable({
  providedIn: 'root',
})

export class PrestataireService {

  constructor(private _http: HttpClient) {
  }

  getPrestataires(page: number = 1): Observable<any> {
    return this._http.get(Config.api.prestataire.all + '&page=' + page);
  }

  getPrestatairesForSelect(): Observable<any> {
    return this._http.get(Config.api.prestataire.list);
  }

  getPrestatairesMoyens(id_prestataire: any): Observable<any> {
    return this._http.get(Config.api.prestataire.moyen + '?id_prestataire=' + id_prestataire);
  }

  createPrestataire(prestataire: PrestataireModel): Observable<any> {
    return this._http.post(Config.api.prestataire.create, prestataire);
  }

  updatePrestataire(prestataire: PrestataireModel): Observable<any> {
    return this._http.post(Config.api.prestataire.update, prestataire);
  }

  deletePrestataire(id_prestataire: any): Observable<any> {
    return this._http.post(Config.api.prestataire.delete, id_prestataire);
  }

  dettachePrestataireMoyen(params: any): Observable<any> {
    return this._http.post(Config.api.prestataire.dettach, params);
  }

  attachPrestataireMoyen(params: any): Observable<any> {
    return this._http.post(Config.api.prestataire.attach, params);
  }

}
