import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationHelper, LocalStorageHelper} from '@app/helpers';
import {APP_NAME, ROUTES, EMAIL_REGEX} from '@app/config';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '@app/services';
import {HttpErrorResponse} from '@angular/common/http';
import {UserModel} from '@app/models';
import {Title} from '@angular/platform-browser';
import {Unsubscribe} from '@app/decorators';
import {Subscription} from 'rxjs';

@Component({
  selector: 'auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})

@Unsubscribe()
export class Auth implements OnInit, OnDestroy {

  ROUTES = ROUTES;
  loginForm: FormGroup;
  response: any;
  credentialsWrong = false;
  cannotAccess = false;
  isRequesting = false;
  subscription = new Subscription();

  constructor(
    private _navigationHelper: NavigationHelper,
    private _authService: AuthService,
    private _title: Title,
  ) {
  }

  ngOnInit() {
    //si la page de login est affichée suite à une redirection lors de la déconnexion,forcer le rechargement de la page pour fixer le probléme du lien du mot de passe oublié
    const loggedout =LocalStorageHelper.getItem('loggedout');
    if(loggedout){
       LocalStorageHelper.removeItem('loggedout');
       location.reload();
    }
    this.setTitle();
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)]),
      password: new FormControl('', [Validators.required])
    });
  }

  setTitle() {
    this._title.setTitle(APP_NAME + ' | Se connecter');
  }

  login() {
    this.isRequesting = true;
    const params = this.loginForm.value;
    const request =  this._authService.login(params).subscribe(
      (response) => this.response = response,
      (error) => this.handleError(error),
      () => this.handleResponse()
    );
    this.subscription.add(request);
  }

  handleResponse() {
    if (this.response.hasOwnProperty('token')) {
      this._authService.setUser(this.response.user as UserModel);
      this._authService.setToken(this.response.token);
      this._navigationHelper.navigateTo(ROUTES['tour'].name);
      this.isRequesting = false;
    }
  }

  handleError(errorResponse: HttpErrorResponse) {
    this.isRequesting = false;
    if (errorResponse['_response']) {
      if (errorResponse['_response'].code === '401') {
        this.credentialsWrong = true;
        setTimeout(() => this.credentialsWrong = false, 5000);
      }
      if (errorResponse['_response'].code === '403') {
        this.cannotAccess = true;
      }
    }
  }

  ngOnDestroy() {}
}
