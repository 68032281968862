import {Component, OnDestroy, OnInit} from '@angular/core';
import {finalize, map} from 'rxjs/internal/operators';
import {EMAIL_REGEX, PHONE_MASK, RESPONSE} from '@app/config';
import {closeAllModals, Required,TypeHelper} from '@app/helpers';
import {ConfirmService, MoyenService, PrestataireService} from '@app/services';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {MoyenModel, PrestataireModel} from '@app/models';
import {Unsubscribe} from '@app/decorators';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-prestataire',
  templateUrl: './prestataire.component.html',
  styleUrls: ['./prestataire.component.scss']
})

@Unsubscribe()
export class PrestataireComponent implements OnInit, OnDestroy {

  prestatairesList: PrestataireModel[];
  moyensList: MoyenModel[];
  prestataireMoyensList: MoyenModel[];
  selectMoyenList: MoyenModel[];
  selectedPrestataire: PrestataireModel;
  isLoading = true;
  prestataireForm: FormGroup;
  prestatairePagination: any;
  selectedMoyenId: any;
  isRequesting = false;
  moyenQTE = 1;
  exist = {
    email: false,
    phone: false,
    phone_invalid: false
  };
  subscription = new Subscription();
  page: number;
  PHONE_MASK = PHONE_MASK;
  moyenSelected = '';
  moyenTypes = [];

  constructor(
    private _prestataireService: PrestataireService,
    private _moyenService: MoyenService,
    private _confirmService: ConfirmService,
    private typeHelper: TypeHelper,
    private _toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.triggerConfirmationEvent();
    this.setPrestataireForm();
    this.getPrestatairesListe();
    this.getMoyens();
    this.getTypes();
  }

  triggerConfirmationEvent() {
    const request =  this._confirmService.answer$.subscribe((response) => {
      if (response && response.action === 'prestataire.delete' && response.value) {
        this.deletePrestataire(this.selectedPrestataire.id);
        this._confirmService.answer = null;
      }

      if (response && response.action === 'moyen.delete' && response.value) {
        this.dettachePrestataireMoyen();
        this._confirmService.answer = null;
      }
    });
    this.subscription.add(request);
  }

  setPrestataireForm() {
    this.exist = {
      email: false,
      phone: false,
      phone_invalid: false
    };
    if (this.selectedPrestataire === undefined) {
      this.prestataireForm = new FormGroup({
        id_prestataire: new FormControl(''),
        nom: new FormControl('', [Required.validator]),
        telephone: new FormControl('', [Required.validator, Validators.minLength(10)]),
        email: new FormControl('', [Required.validator, Validators.pattern(EMAIL_REGEX)]),
      });
    } else if (this.selectedPrestataire === null) {
      const emptyPrestataire = {
        id_prestataire: '',
        nom: '',
        telephone: '',
        email: ''
      };
      this.prestataireForm.patchValue(emptyPrestataire);
    } else {
      this.prestataireForm.patchValue(this.selectedPrestataire.toJson());
    }
  }

  getPrestatairesListe(page = 1) {
    this.isLoading = true;
    this.page = page;
    const request =  this._prestataireService.getPrestataires(this.page)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        response => {
          this.prestatairesList = PrestataireModel.ToPrestataireArray(response.payload.data);
          this.prestatairePagination = {
            id: 'old',
            itemsPerPage: response.payload.per_page,
            currentPage: response.payload.current_page,
            totalItems: response.payload.total
          };
        }
      );
    this.subscription.add(request);
  }

  getPrestataireMoyens() {
    if (this.selectedPrestataire) {
      const request =  this._prestataireService.getPrestatairesMoyens(this.selectedPrestataire.id)
        .subscribe(data => {
          this.prestataireMoyensList = MoyenModel.ToMoyenArray(data.payload.moyens);
          this.selectedPrestataire.moyens=this.prestataireMoyensList;
          this.moyensList = this.selectMoyenList.filter(item =>
            (this.prestataireMoyensList.filter(target => target.id == item.id).length) == 0
          );
        });
      this.subscription.add(request);
    }
  }

  setSelectedPrestataire(prestataire: any = null) {
    this.isRequesting = false;
    this.selectedPrestataire = prestataire ? prestataire as PrestataireModel : null;
    this.getPrestataireMoyens();
    this.setPrestataireForm();
  }

  savePrestataire() {
    this.isRequesting = true;
    const params = this.prestataireForm.value;
    let request;
    if (this.selectedPrestataire) {
      request = this._prestataireService.updatePrestataire(params);
    } else {
      delete params['id_prestataire'];
      request = this._prestataireService.createPrestataire(params);
    }

    const ask =  request
      .pipe(
        map((data: any) => this.handleResponse(data._response))
      )
      .subscribe(
        () => {
        },
        (error) => {
          this.handleError(error);
        }
      );
    this.subscription.add(ask);
  }

  deletePrestataire(id) {
    const params = {id_prestataire: id};
    const request = this._prestataireService.deletePrestataire(params)
      .pipe(
        map((data) => this.handleResponse(data._response))
      )
      .subscribe(
        () => {
        },
        (error) => {
          this._toastr.error(error._response.message);
        }
      );
    this.subscription.add(request);
  }

  handleError(error) {
    this.isRequesting = false;
    this.exist = {
      email: false,
      phone: false,
      phone_invalid: false
    };
    if (error._response.code === RESPONSE.PRESTATAIRE.ERROR.EMAIL_EXIST) {
      this.exist.email = true;
    } else if (error._response.code === RESPONSE.PRESTATAIRE.ERROR.PHONE_EXIST) {
      this.exist.phone = true;
    } else if (error._response.code === RESPONSE.PRESTATAIRE.ERROR.PHONE_MOBILE_INVALID) {
      this.exist.phone_invalid = true;
    }
  }

  handleResponse(response) {
    if ([RESPONSE.PRESTATAIRE.OK.CREATE, RESPONSE.PRESTATAIRE.OK.UPDATE, RESPONSE.PRESTATAIRE.OK.DELETE].indexOf(response.code) !== -1) {
      if (RESPONSE.PRESTATAIRE.OK.DELETE === response.code) {
        this.checkPageIfEmpty();
      }
      this.getPrestatairesListe();
      closeAllModals();
      this.prestataireForm.reset();
      this.isRequesting = false;
    }

    if ([RESPONSE.PRESTATAIRE.OK.ATTACH, RESPONSE.PRESTATAIRE.OK.DETTACH].indexOf(response.code) !== -1) {
      this.getPrestataireMoyens();
    }
    this.restForm();
  }

  showConfirm(prestataire: PrestataireModel) {
    this.setSelectedPrestataire(prestataire);
    this._confirmService.showConfirm('prestataire.delete', 'Êtes vous sûr de vouloir supprimer cet élement ?');
  }

  dettachePrestataireMoyen() {
    const params = {
      id_prestataire: this.selectedPrestataire.id,
      id_moyen: this.selectedMoyenId
    };
    const request =  this._prestataireService.dettachePrestataireMoyen(params)
      .pipe(
        map((data) => this.handleResponse(data._response))
      )
      .subscribe(
        (response) => {
          
        }, (error) => {
          this._toastr.error(error._response.message);
        },
        () => {
          const recoverMoyen: MoyenModel = this.selectMoyenList.filter(item => item.id == this.selectedMoyenId)[0];
          this.moyensList.push(recoverMoyen);
        }
      );
    this.subscription.add(request);
  }

  isFormInvalid() {
    return this.prestataireForm.invalid ||
      !this.prestataireForm.dirty ||
      (((this.prestataireForm.get('telephone').value).replace('_', '')).length !== 10);
  }

  checkPageIfEmpty() {
    if (this.prestatairesList.length === 1) {
      this.page = (this.page > 1) ? this.page - 1 : this.page;
    }
  }

  onPageChange(page: any): void {
    this.page = page;
    this.getPrestatairesListe(this.page);
  }

  // Partie Moyen
  showConfirmMoyen(moyen: MoyenModel) {
    this.setSelectedMoyenId(moyen.id);
    this._confirmService.showConfirm('moyen.delete', 'Êtes vous sûr de vouloir supprimer cet élement ?');
  }

  setSelectedMoyenId(id_moyen: number) {
    this.selectedMoyenId = id_moyen;
  }

  getMoyens() {
    const request =  this._moyenService.getMoyensForSelect()
      .subscribe(
        data => {
          this.moyensList = MoyenModel.ToMoyenArray(data.payload);
          this.selectMoyenList = MoyenModel.ToMoyenArray(data.payload);
        }
      );
    this.subscription.add(request);
  }

  attachMoyenWithPrestataire(qte: number = 1) {
    const params = {
      id_prestataire: this.selectedPrestataire.id,
      id_moyen: this.selectedMoyenId,
      qte: qte
    };
    this.moyenQTE = 1;
    const request =  this._prestataireService.attachPrestataireMoyen(params)
      .pipe(
        map((data) => this.handleResponse(data._response))
      )
      .subscribe(
        () => {
          this.moyensList = [...this.moyensList.filter(item => item.id != this.selectedMoyenId)];
        },
      );
    this.subscription.add(request);
  }

  restForm() {
    this.prestataireForm.reset();
    this.exist = {
      email: false,
      phone: false,
      phone_invalid: false
    };
  }

  toNumber(string) {
    return Number(string);
  }

  getTypes(){
    this.typeHelper.getTypes().subscribe(types=>{
        this.moyenTypes=types;
    });
    
  }

  ngOnDestroy() {}
}
