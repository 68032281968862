import { Output,EventEmitter, Component,Input } from '@angular/core';
import {IncidentService} from '@app/services';
import * as moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-edit-date-depot-modal',
  templateUrl: './edit-date-depot-modal.component.html',
  styleUrls: ['./edit-date-depot-modal.component.css']
})
export class EditDateDepotModalComponent {
  @Input() id;
  @Input() date;
  @Output() eventEditDateIncident = new EventEmitter();
  generalDate;
  time;

  constructor(private incidentService: IncidentService){

  }

  ngOnChanges(){
    this.generalDate=this.date?moment(this.date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'):null;
    this.time=this.date?moment(this.date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm'):null;
  }

  editDateDepotIncident(){
      let date=this.generalDate + ' ' + this.time;
      let params={
        'id_incident' : this.id,
        'date_depot' : moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
      };
      this.incidentService.updateDateDepot(params).subscribe(res => {
        this.eventEditDateIncident.emit({result:true,date_depot:date});
      },err => {
        this.eventEditDateIncident.emit({result:false,error:err._response.message});
      });
  }
}