import {Component, OnDestroy, OnInit} from '@angular/core';
import {finalize, map} from 'rxjs/internal/operators';
import {RESPONSE} from '@app/config';
import {closeAllModals, isValidPicture, Required,TypeHelper} from '@app/helpers';
import {ConfirmService, EncombrantService, RdvService} from '@app/services';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {EncombrantModel} from '@app/models';
import {Unsubscribe} from '@app/decorators';

@Component({
  selector: 'app-encombrant',
  templateUrl: './encombrant.component.html',
  styleUrls: ['./encombrant.component.scss']
})

@Unsubscribe()
export class EncombrantComponent implements OnInit, OnDestroy {

  encombrantsList: EncombrantModel[];
  encombrantsPagination: any;
  page: number;
  selectedEncombrant: EncombrantModel;
  isLoading = true;
  encombrantForm: FormGroup;
  encombrantTypes;
  subscription = new Subscription();
  imgData: any;
  isExtensionInvalid = false;
  isRequesting = false;
  communes: any[] = [];
  namespace: string = 'bo';
  selectedTypologies: any[] = [];
  selectedTypeEncombrant: string;

  constructor(
    private _rdvService: RdvService,
    private _encombrantService: EncombrantService,
    private _confirmService: ConfirmService,
    private typeHelper: TypeHelper
  ) {
  }

  ngOnInit() {
    this.triggerConfirmationEvent();
    this.setEncombrantForm();
    this.getEncombrantsListe();
    this.getTypes();
    this.loadData();
  }

  triggerConfirmationEvent() {
    const request = this._confirmService.answer$.subscribe((response) => {
      if (response && response.action === 'encombrant.delete' && response.value) {
        this.deleteEncombrant(this.selectedEncombrant.id);
        this._confirmService.answer = null;
      }
    });
    this.subscription.add(request);
  }


  setEncombrantForm() {
    if (this.selectedEncombrant === undefined) {
      this.encombrantForm = new FormGroup({
        id_encombrant: new FormControl(''),
        nom: new FormControl('', [Required.validator]),
        //type: new FormControl(''),
        typologies: new FormArray([]),
        description: new FormControl('', [Required.validator]),
        img_name: new FormControl(''),
        volume: new FormControl('', [Required.validator, Validators.pattern('^[+]?([0-9]{1,1})*([.,][0-9]{1,2})?$')]),
        qte_max_par_rdv: new FormControl(0, [Validators.pattern('^[0-9]*$')]),
        nbr_rdv_par_dispo: new FormControl(0, [Validators.pattern('^[0-9]*$')]),
      });
    } else if (this.selectedEncombrant === null) {
      this.imgData = null;
      const emptyEncombrant = {
        id_encombrant: null,
        nom: '',
        description: '',
        typologies: [],
        img_name: '',
        volume: '',
        qte_max_par_rdv: '',
        nbr_rdv_par_dispo: ''
      };
      this.encombrantForm.patchValue(emptyEncombrant);
    } else {
      this.encombrantForm.patchValue(this.selectedEncombrant.toJson());
      this.selectedTypologies = this.selectedEncombrant.typologies
    }
  }

  getEncombrantsListe(page = 1) {
    this.isLoading = true;
    const request =  this._encombrantService.getEncombrants(this.page)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        response => {
          this.encombrantsList = EncombrantModel.ToEncombrantArray(response.payload.data);
          this.encombrantsPagination = {
            id: 'old',
            itemsPerPage: response.payload.per_page,
            currentPage: response.payload.current_page,
            totalItems: response.payload.total
          };
        }
      );
    this.subscription.add(request);
  }

  setSelectedEncombrant(encombrant: any = null) {

    this.isRequesting = false;
    this.encombrantForm.reset();
    this.selectedEncombrant = encombrant ? encombrant as EncombrantModel : null;
    this.setEncombrantForm();
    const img_url = this.selectedEncombrant ? this.selectedEncombrant.img_name : 'assets/imgs/user.png';
    this.setImagePath(img_url);
  }

  setImagePath(img_url: any) {
    const img = document.getElementById('imagePreview');
    img.style.backgroundImage = 'url(\'' + img_url + '\')';
  }

  getTypeByCommune(commune, enc){
    if(this.selectedEncombrant){
      let x = this.selectedEncombrant.typologies.filter(t => t.id_commune == commune && t.id_encombrant == enc);
      return x.length ? x[0].typologie : null;
    }
    return null;
  }

  setTypologies(e){
    let typeCommune = e.target.value.split('|');
    let typologie = this.selectedTypologies.filter(t => t.id_commune == typeCommune[1])[0]
    if(typologie){
      typologie.typologie = typeCommune[0]
    }else{
      this.selectedTypologies.push({typologie: typeCommune[0], id_commune: typeCommune[1]})
    }
  }

  loadData() {
    this.isLoading = true;
    this._rdvService.getCommunesWithEncombrant(this.namespace)
      .subscribe(data => {
        this.communes = data.payload.communes;
      });
  }

  saveEncombrant() {
    this.isRequesting = true;
    const params = new FormData();
    this.selectedEncombrant && params.append('id_encombrant', this.selectedEncombrant.id_encombrant.toString());
    params.append('nom', this.encombrantForm.value.nom);
    params.append('description', this.encombrantForm.value.description);
    params.append('volume', this.encombrantForm.value.volume);
    params.append('qte_max_par_rdv', !this.encombrantForm.value.qte_max_par_rdv?0:this.encombrantForm.value.qte_max_par_rdv);
    params.append('nbr_rdv_par_dispo', !this.encombrantForm.value.nbr_rdv_par_dispo?0:this.encombrantForm.value.nbr_rdv_par_dispo);

    if (this.imgData && (!this.selectedEncombrant ||
      (this.selectedEncombrant && this.selectedEncombrant.img_name !== this.encombrantForm.value.img_name))) {
      params.append('img_name', this.imgData);
    }

    for(let i = 0; i < this.selectedTypologies.length; i++){
      params.append('typologies['+i+'][id_commune]', this.selectedTypologies[i].id_commune);
      params.append('typologies['+i+'][typologie]', this.selectedTypologies[i].typologie);
    }

    let request = this.selectedEncombrant ? this._encombrantService.updateEncombrant(params) : this._encombrantService.createEncombrant(params);

    const ask =  request
      .pipe(
        map((data: any) => {
          this.handleResponse(data._response);
        })
      )
      .subscribe(
        () => {
        },
        (error) => {
          this.handleError(error);
        }
      );
    this.subscription.add(ask);
  }

  handleError(error) {
    this.isRequesting = false;
  }

  deleteEncombrant(id) {
    const params = {id_encombrant: id};
    const request =  this._encombrantService.deleteEncombrant(params)
      .pipe(
        map((data) => {
          this.handleResponse(data._response);
        })
      )
      .subscribe();
    this.subscription.add(request);
  }

  handleResponse(response) {
    if ([RESPONSE.ENCOMBRANT.OK.CREATE, RESPONSE.ENCOMBRANT.OK.UPDATE, RESPONSE.ENCOMBRANT.OK.DELETE].indexOf(response.code) !== -1) {
      if (RESPONSE.ENCOMBRANT.OK.DELETE === response.code) {
        this.checkPageIfEmpty();
      }
      this.getEncombrantsListe(this.page);
      closeAllModals();
    }
  }

  showConfirm(encombrant: EncombrantModel) {
    this.setSelectedEncombrant(encombrant);
    this._confirmService.showConfirm('encombrant.delete', 'Êtes vous sûr de vouloir supprimer cet élement ?');
  }

  isFormInvalid() {
    const imgRequired = this.selectedEncombrant ? false : !this.encombrantForm.get('img_name').value;
    console.log(this.encombrantForm.invalid, imgRequired)
    return this.encombrantForm.invalid || imgRequired;
  }

  onPageChange(page: any): void {
    this.page = page;
    this.getEncombrantsListe(this.page);
  }

  checkPageIfEmpty() {
    if (this.encombrantsList.length === 1) {
      this.page = (this.page > 1) ? this.page - 1 : this.page;
    }
  }

  onFileChange(event) {
    const reader = new FileReader();
    this.isExtensionInvalid = !isValidPicture(event.srcElement.files[0]);
    if (event.srcElement.files[0] && !this.isExtensionInvalid) {
      const file = event.srcElement.files[0];
      this.imgData = file;
      reader.onload = function (e) {
        this.setImagePath(e.target.result);
      }.bind(this);
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  ngOnDestroy() {
  }

  getTypes(){
    this.typeHelper.getTypes().subscribe(types=>{
        this.encombrantTypes=types;
    });

  }
}
