import {TourneeModel} from "../models/tournee.model";
import {DepotModel} from "./depot.model";
import {MISSIONS_PLANIFICATION, MISSIONS_STATUTS, TOURNEE_STATUTS} from "@app/config";

export class MissionModel {
  public id_mission: number;
  public id_prestataire: number;
  public id_disponibilite: number;
  public start_date: string;
  public end_date: string;
  public planifiee: number;
  public tournees: TourneeModel[];
  public arrive: DepotModel;
  public depart: DepotModel;
  public prestataire: prestataire;

  constructor(mission: any) {
    this.id_mission = mission.id_mission;
    this.id_prestataire = mission.id_prestataire;
    this.id_disponibilite = mission.id_disponibilite;
    this.start_date = mission.start_date;
    this.end_date = mission.end_date;
    this.planifiee = mission.planifiee;
    this.tournees = TourneeModel.ToTourneeArray(mission.tournees);
    this.arrive = (mission.arrive) ? new DepotModel(mission.arrive) : null;
    this.depart = (mission.depart) ? new DepotModel(mission.depart) : null;
    this.prestataire = new prestataire(mission.prestataire);
  }

  // Retourne une liste des missions
  static ToMissionArray(data: MissionModel[] = []): MissionModel[] {
    const missions: MissionModel[] = [];
    data.forEach(item => {
      const mission: MissionModel = new MissionModel(item);
      missions.push(mission);
    });
    return missions;
  }

  getProgress() {
    let progress = 0;
    this.tournees.forEach(elem => {
      progress += MISSIONS_STATUTS.find(status => status.key === elem.statut).progress;
    });
    // ici j'ai créer une algrorithme qui calcule la somme de la progress de tous les tournees et puis diviser cette somme sur le count de tournees de cette mission puis multiplier sur 100 pour obtenir le pourcentage
    return (this.tournees ? (this.tournees.length ? (progress / this.tournees.length * 100).toFixed(2) : 0) : 0);
  }

  getPlanification() {
    if (this.planifiee === 1) {
      let hasEncours = this.tournees.find(t => t.statut === TOURNEE_STATUTS.EN_COURS) ? true : false;
      let hasEnAttente = this.tournees.find(t => t.statut === TOURNEE_STATUTS.EN_ATTENTE) ? true : false;
      let hasTerminee = this.tournees.find(t => t.statut === TOURNEE_STATUTS.TERMINEE) ? true : false;
      let hasAReplanif = this.tournees.find(t => t.statut === TOURNEE_STATUTS.A_REPLANIFIER) ? true : false;

      if (hasEncours || (hasEnAttente && ( hasTerminee || hasAReplanif)) ) {
        return MISSIONS_PLANIFICATION.EN_COURS;
      } else if (!hasEncours && !hasTerminee) {
        return MISSIONS_PLANIFICATION.PLANIFIER;
      } else {
        return MISSIONS_PLANIFICATION.TERMINEE;
      }
    } else if (this.planifiee === 0) {
      return MISSIONS_PLANIFICATION.NON_PLANIFIER;
    } else if (this.planifiee === 2) {
      return MISSIONS_PLANIFICATION.TERMINEE;
    }
  }

  isplanified() {
    return this.planifiee !== 1;
  }

  toJson() {
    return {
      id_mission: this.id_mission,
      id_prestataire: this.id_prestataire,
      id_disponibilite: this.id_disponibilite,
      start_date: this.start_date,
      end_date: this.end_date,
      planifiee: this.planifiee,
    };
  }
}

export class prestataire {
  public id_prestataire: number;
  public nom: string;

  constructor(prest: any) {
    if (prest) {
      this.id_prestataire = prest.id_prestataire;
      this.nom = prest.nom;
    }
  }
}
