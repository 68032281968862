import {environment} from '../../environments/environment';
import {COUNT} from './vars.config';

export class Config {

  private static url = environment.url;

  public static get api(): any {
    const user_url: string = this.url + 'utilisateur';
    const moyen_url: string = this.url + 'moyen';
    const prestataire_url: string = this.url + 'prestataire';
    const bailleur_url: string = this.url + 'bailleur';
    const gardien_url: string = this.url + 'gardien';
    const encombrant_url: string = this.url + 'encombrant';
    const terminal_url: string = this.url + 'terminal';
    const incident_url: string = this.url + 'incident';
    const typeincident_url: string = this.url + 'typeincident';
    const rdv_url: string = this.url + 'rdv';
    const mission_url: string = this.url + 'mission';
    const tournee_url: string = this.url + 'tournee';
    const notif_url: string = this.url + 'template/';
    const calendar_url: string = this.url + 'calendar/';
    const ramassage_url: string = this.url + 'ramassage/';
    const workflow_url: string = this.url + 'workflow';
    const depot_url: string = this.url + 'depot';
    const client_url: string = this.url + 'client';
    const logette_url: string = this.url + 'sig/load';
    const statistique_url: string = this.url + 'statistique/export';
    const microincident_url: string = this.url + 'microIncident';
    const typologie_url: string = this.url + 'typologie';
    const import_url: string = this.url + 'sig/import/adresse';
    return {
      logette: {
        get: logette_url + '/logette'
      },
      // Client
      client: {
        all: client_url + '/all?rows=' + COUNT,
        list: client_url + '/list',
        create: client_url + '/store',
        update: client_url + '/update',
        delete: client_url + '/delete',
        consulter: client_url + '/consulter'
      },

      // Utilisateur
      user: {
        all: user_url + '/all?rows=' + COUNT,
        create: user_url + '/store',
        update: user_url + '/update',
        delete: user_url + '/delete',
        get: user_url + '/show',
        updatepassword: this.url + 'resetPassword',
        sendPasswordResetLink: this.url + 'sendPasswordResetLink'
      },

      // Current
      account: {
        update: user_url + '/update/account',
      },

      // Incident
      incident: {
        all: incident_url + '/all?rows=' + COUNT,
        create: incident_url + '/store',
        update: incident_url + '/update',
        delete: incident_url + '/delete',
        resolve: incident_url + '/resolve',
        fusion_save: {
          tournee: this.url + 'tournee/fusion',
          ramassage: this.url + 'ramassage/fusion',
          mission: this.url + 'mission/fusion',
        },
        plannification_save: {
          tournee: this.url + 'tournee/replanifier',
          ramassage: this.url + 'ramassage/replanifier',
        },
        update_status: incident_url + '/updatestatut',
        update_typeincident: incident_url + '/updatetypeincident',
        list: this.url + 'typeincident/list',
        ramassage : this.url + 'ramassage/incident',
        tournee : this.url + 'tournee/incident',
        update_classe: incident_url + '/updateclasse',
        update_comment: incident_url + '/updatecommentaire',
        update_datedepot: incident_url + '/updatehoraire',
        annuler_replanification: this.url + 'ramassage/annuler/replanification'
      },

      // Type incident
      type_incident: {
        all: typeincident_url + '/all?rows=' + COUNT,
        create: typeincident_url + '/store',
        update: typeincident_url + '/update',
        delete: typeincident_url + '/delete'
      },

      //Micro Incident
      micro_incident: {
        create: microincident_url + '/create',
        update: microincident_url + '/update',
        delete: microincident_url + '/delete'
      },

      // Terminal
      terminal: {
        all: terminal_url + '/all?rows=' + COUNT,
        create: terminal_url + '/store',
        update: terminal_url + '/update',
        delete: terminal_url + '/delete'
      },

      // Moyen
      moyen: {
        all: moyen_url + '/all?rows=' + COUNT,
        list: moyen_url + '/list',
        create: moyen_url + '/store',
        update: moyen_url + '/update',
        delete: moyen_url + '/delete'
      },

      // Typologie
      typologie: {
        client:{
          get: this.url + 'client/typologie/get'
        },
        bo:{
          get: typologie_url + '/get'
        },
        acquity:{
          get: this.url + 'aquity/typologie/get'
        }
      },

      // Prestataire
      prestataire: {
        all: prestataire_url + '/all?rows=' + COUNT,
        list: prestataire_url + '/list',
        create: prestataire_url + '/store',
        update: prestataire_url + '/update',
        delete: prestataire_url + '/delete',
        attach: prestataire_url + '/attach',
        dettach: prestataire_url + '/dettach',
        moyen: prestataire_url + '/moyen'
      },

      bailleur: {
        all: bailleur_url + '/all',
        choicelist: bailleur_url + '/choicelist',
        create: bailleur_url + '/create',
        update: bailleur_url + '/update',
        delete: bailleur_url + '/delete',
      },

      // Encombrant
      gardien: {
        all: gardien_url + '/all',
        create: gardien_url + '/store',
        update: gardien_url + '/update',
        valider: gardien_url + '/valider',
        activer: gardien_url + '/activer',
        desactiver: gardien_url + '/desactiver',
        delete: gardien_url + '/delete',
        export: gardien_url + '/export'
      },

      // Encombrant
      encombrant: {
        all: encombrant_url + '/all?rows=' + COUNT,
        create: encombrant_url + '/store',
        update: encombrant_url + '/update',
        delete: encombrant_url + '/delete'
      },

      // Utilisateur
      depot: {
        all: depot_url + '/all?rows=' + COUNT,
        create: depot_url + '/store',
        update: depot_url + '/update',
        delete: depot_url + '/delete',
        attach: depot_url + '/attach',
        detach: depot_url + '/detach'
      },

      // Encombrant
      rdv: {
        all: rdv_url + '/all?rows=' + COUNT,
        survey: rdv_url + '/issue',
        aquity: {
          communes: this.url + 'aquity/sig/load/communes',
          adresses: this.url + 'aquity/sig/load/adresses',
          disponibilite: this.url + 'aquity/load/dispo',
          save: this.url + 'aquity/rdv/store',
          update: this.url + 'aquity/rdv/update',
          delete: this.url + 'aquity/rdv/delete', // achraf modifié 30/10/2019
          get: this.url + 'aquity/rdv/show'

        },
        bo: {
          communes: this.url + 'sig/load/communes',
          adresses: this.url + 'sig/load/adresses',
          disponibilite: this.url + 'load/dispo',
          duplicateDisponibilite: this.url + 'calendar/duplicateEvents',
          save: this.url + 'rdv/store',
          update: this.url + 'rdv/update',
          delete: this.url + 'rdv/delete', // TODO: Remove
          get: this.url + 'rdv/show',
          printQrcode: rdv_url + '/generate-pdf'
        },
        client: {
          communes: this.url + 'client/sig/load/communes',
          adresses: this.url + 'client/sig/load/adresses',
          disponibilite: this.url + 'client/load/dispo',
          img_depot: this.url + 'client/rdv/uploadPhotoDepot',
          save: this.url + 'client/rdv/store',
          update: this.url + 'client/rdv/update',
          delete: this.url + 'calendar/rdv/client/delete', // TODO: test
          get: this.url + 'client/rdv/show',
          checkRdbByEmail: this.url + 'client/rdv/check',
          survey: this.url + 'client/rdv/issue',
        },
      },

      // Mail et SMS
      notification: {
        all: notif_url + 'list?rows=' + COUNT,
        detail: notif_url + 'get',
        update: notif_url + 'update',
      },

      // Auth
      auth: {
        login: this.url + 'login',
        logout: this.url + 'auth/logout',
      },

      // Auth
      commune: {
        list: this.url + 'list/communes'
      },

      // Mission
      mission: {
        list: mission_url + '/all?rows=' + COUNT,
        get: mission_url + '/detail',
        cron: this.url + 'cron/runid/',
        detail: calendar_url + 'mission/rdv/list',
        ramassage: calendar_url + 'mission/rdv/ramassage/list',
        delete_rdv: calendar_url + 'rdv/delete', // TODO: abdessamad : 'mission/rdv/delete'
      },
      workflow: {
        show_by: workflow_url + '/show_by'
      },
      // Settings
      setting: {
        list: calendar_url + 'settings',
        update: this.url + 'calendar/optionaffichage/update',
      },
      // Ramassage
      ramassage: {
        delete: ramassage_url + 'delete',
      },
      event: {
        sync: calendar_url + 'events/sync',
        save: calendar_url + 'events/save',
        load: calendar_url + 'events/load'
      },
      // Mission
      tournee: {
        list: tournee_url + '/all?rows=' + COUNT,
        get: tournee_url + '/detail',
        dechetsACompter: tournee_url + '/getDechetsACompterByTournee',
        setComptage: tournee_url + '/comptageDechets'
      },
      // statistique
      statistique: {
        getRdvsCsv: statistique_url + '/rdvs',
        getMissionPdf: statistique_url + '/mission',
        getSyntheseTourneesCsv: statistique_url + '/syntheseTournees',
        getDetailTourneesCsv: statistique_url + '/detailTournees',
        getIncidentsTourneesCsv: statistique_url + '/incidentsTournees',
        getBilan: statistique_url + '/rapport'
      },

      adresse : {
        import : import_url
      }
    };
  }

}
