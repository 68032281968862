export class TerminalModel {
  public id: number;
  public libelle: string;
  public iemi: string;
  public prestataire: number;

  constructor(terminal: any) {
    this.id = terminal.id_terminal;
    this.libelle = terminal.libelle;
    this.iemi = terminal.iemi;
    this.prestataire = terminal.id_prestataire;
  }

  // Retourne une liste des terminals
  static ToTerminalArray(data: TerminalModel[] = []): TerminalModel[] {
    const terminals: TerminalModel[] = [];
    data.forEach(item => {
      const terminal: TerminalModel = new TerminalModel(item);
      terminals.push(terminal);
    });
    return terminals;
  }

  getNamePresatataire(prestataires: any[]) {
    const prestataire = prestataires.find(item => item.id_prestataire === this.prestataire);
    return prestataire ? prestataire.nom : 'N/A';
  }

  toJson() {
    return {
      id_terminal: this.id,
      libelle: this.libelle,
      iemi: this.iemi,
      id_prestataire: this.prestataire
    };
  }
}
