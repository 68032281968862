import {Component, OnDestroy, OnInit} from '@angular/core';
import {finalize, map} from 'rxjs/internal/operators';
import {EMAIL_REGEX, PHONE_MASK, RESPONSE} from '@app/config';
import {closeAllModals, Required,TypeHelper} from '@app/helpers';
import {ConfirmService, BailleurService, UserService} from '@app/services';
import {FormControl, FormGroup,FormArray, Validators, FormBuilder} from '@angular/forms';
import {Subscription} from 'rxjs';
import {BailleurModel,GardienModel} from '@app/models';
import {Unsubscribe} from '@app/decorators';
import {ToastrService} from 'ngx-toastr';
import { GardienService } from 'app/services/api/gardien.service';

@Component({
  selector: 'app-bailleur',
  templateUrl: './bailleur.component.html',
  styleUrls: ['./bailleur.component.scss']
})

@Unsubscribe()
export class BailleurComponent implements OnInit, OnDestroy {

  bailleursList: BailleurModel[];
  //bailleursStore: BailleurModel[];
  listCommunes: any[] = [];
  selectedBailleur: BailleurModel;
  isLoading = true;
  afficherGardienInactif : boolean = false;
  isRequesting = false;
  filterBy;

  bailleurForm = new FormGroup({
    id: new FormControl(null),
    nom: new FormControl(null, [Required.validator,Validators.minLength(3)]),
    code: new FormControl(null, [Required.validator,Validators.minLength(3)]),
    actif: new FormControl(false),
    communes : new FormArray([],[Validators.required]),
  });

  exist = {
    nom: false,
    /*phone: false,
    phone_invalid: false,*/
    code : false,
  };

  subscription = new Subscription();
  PHONE_MASK = PHONE_MASK;


  constructor(
    private _bailleurService: BailleurService,
    private _gardiensService: GardienService,
    private _confirmService: ConfirmService,
    private _userService: UserService,
    private _toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.triggerConfirmationEvent();
    this.getCommunes();
    this.getBailleursListe();
    this.setBailleurForm();
  }

  get bailleurCommuneFormArray():FormArray{
    return this.bailleurForm.get('communes') as FormArray;
  }

  fillBailleurCommuneInfo(){
    this.bailleurCommuneFormArray.clear();
    const arr = this.listCommunes.map(commune => {
      let data = this.selectedBailleur && this.selectedBailleur.communes.find(b_c => b_c.id_commune == commune.id_commune);
      this.bailleurCommuneFormArray.push(new FormGroup({
        commune_id : new FormControl(commune.id_commune),
        commune_status : new FormControl(data ? true : false),
        commune_email : new FormControl(data ? data.email : '',[Validators.pattern(EMAIL_REGEX)]),
        commune_telephone : new FormControl(data ? data.telephone : ''),
        commune_nom_contact : new FormControl(data ? data.nom_contact : ''),
        commune_fonction : new FormControl(data ? data.fonction : ''),
      }));
    });
  }

  // onCommuneChecked(e:Event){
  //   let elm = (<HTMLInputElement>(e.target));
  //   if(elm.checked){
  //     let input_ = document.getElementById(`input_${elm.value}`);
  //     if(input_){input_.focus();}
  //   }
  // }

  hasInvalidEmail(){
    return !!this.bailleurCommuneFormArray.controls.find((commune_ctrl) => {
      let ctrl = commune_ctrl as FormGroup;
      return ctrl.get('commune_status').value && (ctrl.get('commune_email').value.length == 0 || ctrl.get('commune_email').hasError('pattern'));
    });
  }


  getGardienStatClass(status){
    return status == -1 ? 'enAttente' : ( status == 1 ? 'actif' : 'inactif');
  }

  getBailleurGardiens() {
    if (this.selectedBailleur) {
      const request =  this._gardiensService.getGardiensOf(this.selectedBailleur.id)
        .subscribe(data => {
          this.selectedBailleur.gardiens_enAttente_count = 0;
          this.selectedBailleur.gardiens_actif_count = 0;
          this.selectedBailleur.gardiens_inactif_count = 0;
           this.selectedBailleur.gardiens = GardienModel.toArray(data.payload);
           this.selectedBailleur.gardiens.forEach(x => {
            if(x.actif == -1){
              this.selectedBailleur.gardiens_enAttente_count++;
            }else if(x.actif == 1){
              this.selectedBailleur.gardiens_actif_count++;
            }
            else{
              this.selectedBailleur.gardiens_inactif_count++;
            }
           });
        });
      this.subscription.add(request);
    }
  }

  setSelectedBailleur(bailleur: any = null, loadGardien = false) {
    this.isRequesting = false;
    this.selectedBailleur = bailleur ? bailleur as BailleurModel : null;
    if(loadGardien){this.getBailleurGardiens();}
    this.setBailleurForm();
  }

  setBailleurForm() {
    this.exist = {
      nom: false,
      /*phone: false,
      phone_invalid: false,*/
      code : false
    };

    if (this.selectedBailleur === undefined) {
      this.bailleurForm.reset();
      this.fillBailleurCommuneInfo();
    } else if (this.selectedBailleur === null) {
      this.bailleurForm.reset();
      this.fillBailleurCommuneInfo();
    } else {
      this.fillBailleurCommuneInfo();
      this.bailleurForm.patchValue(this.selectedBailleur);
    }
  }



  getCommunes() {
    return this._userService.getCommunes().subscribe( data => { this.listCommunes = data.payload;} );
  }

  getBailleursListe() {
    this.isLoading = true;
    const request =  this._bailleurService.getBailleurs()
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        response => {
          this.bailleursList = BailleurModel.toArray(response.payload);
        }
      );
    this.subscription.add(request);
  }


  checkCommunesValue(event,id_commune){
    let oldValue = this.bailleurForm.get('communes').value;
    oldValue = oldValue ? oldValue.split(',') : [];

    if(event.target.checked){
      oldValue.push(id_commune);
    }else{
      oldValue = oldValue.filter(x =>  x!=id_commune);
    }
    this.bailleurForm.patchValue({communes : oldValue.sort().join(',')});
    this.bailleurForm.markAsDirty();
  }

  saveBailleur() {
    this.isRequesting = true;
    let params = this.bailleurForm.value;


    params.communes = params.communes.filter(c => c.commune_status).map(c =>  ({id_commune:parseInt(c.commune_id), email: c.commune_email, telephone: c.commune_telephone, nom_contact: c.commune_nom_contact, fonction: c.commune_fonction }));
    params.actif =  params.actif ? 1 : 0;
    let request;
    if (this.selectedBailleur) {
      request = this._bailleurService.updateBailleur(params);
    } else {
      delete params['id'];
      request = this._bailleurService.createBailleur(params);
    }

    const ask =  request
      .pipe(
        map((data: any) => this.handleResponse(data._response))
      )
      .subscribe(
        () => {
        },
        (error) => {
          this.handleError(error);
        }
      );
    this.subscription.add(ask);
  }

  deleteBailleur(id) {
    const request = this._bailleurService.deleteBailleur(id)
      .pipe(
        map((data) => this.handleResponse(data._response))
      )
      .subscribe(
        () => {
          this._toastr.info('Supprimé avec succès');
        },
        (error) => {
          this._toastr.error(error._response.message);
        }
      );
    this.subscription.add(request);
  }

  triggerConfirmationEvent() {
    const request =  this._confirmService.answer$.subscribe((response) => {
      if (response && response.action === 'bailleur.delete' && response.value) {
        this.deleteBailleur(this.selectedBailleur.id);
        this._confirmService.answer = null;
      }
    });
    this.subscription.add(request);
  }

  handleError(error) {
    this.isRequesting = false;
    this.exist = {
      nom: false,
      /*phone: false,
      phone_invalid: false,*/
      code : false
    };

    if (error._response.code === RESPONSE.PRESTATAIRE.ERROR.EMAIL_EXIST) {
      //this.exist.email = true;
    } else if (error._response.code === 'BLL406_00') {
      this.exist.code = true;
    }
    else if (error._response.code === 'BLL406_01') {
      this.exist.nom = true;
    }
    else if (error._response.code === RESPONSE.PRESTATAIRE.ERROR.PHONE_MOBILE_INVALID) {
      //this.exist.phone_invalid = true;
    }
    console.log(error,this.exist);
  }

  handleResponse(response) {
    this.getBailleursListe();
    closeAllModals();
    this.bailleurForm.reset();
    this.isRequesting = false;
    this.restForm();
  }

  showConfirm(bailleur: BailleurModel) {
    this.setSelectedBailleur(bailleur);
    this._confirmService.showConfirm('bailleur.delete', 'Attention Supprimer ce bailleur implique la suppression des gardiens associés !! Êtes vous sûr de vouloir supprimer ce bailleur ? ');
  }

  isFormInvalid() {

    //let tel = (this.bailleurForm.get('telephone').value); (tel && tel.replace('_', '').length !== 10) ||
    return this.bailleurForm.invalid || !this.bailleurForm.dirty || this.hasInvalidEmail() || this.isInvalidCommuneInfos() ||
       this.bailleurForm.get('communes').value.length == 0;
  }

  isInvalidCommuneInfos(){
    let data = this.bailleurForm.get('communes').value;
    let isInvalid =  data.filter( c => c.commune_status == false).length == data.length;
    if(isInvalid) return true;
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return data.filter( c => c.commune_status == true && !c.commune_email.match(validRegex)).length ? true : false;
  }


  restForm() {
    this.bailleurForm.reset();
    this.exist = {
      nom: false,
      //phone: false,
      //phone_invalid: false,
      code : false
    };
  }

  toNumber(string) {
    return Number(string);
  }

  chercher(){

  }

  ngOnDestroy() {}
}
