import {Component, OnInit} from '@angular/core';
import {HeaderSetterHelper, LocalStorageHelper, NavigationHelper} from '@app/helpers';
import {PROD_ENV, ROUTES} from '@app/config';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  name: any;
  constructor(private _header: HeaderSetterHelper,
              private _navigationHelper: NavigationHelper) {
  }

  ngOnInit() {
    this._header.setTitleAndHeader();
  }

  isDEVEnv() {
    return environment.url.indexOf(PROD_ENV) == -1;
  }

  ngDoCheck() {
    this.name = this._header.setElementByRouteName();
  }

  logout() {
    LocalStorageHelper.clear();
    //enregistrer dans le cache l'entrée loggedout qui indique que l'user vient juste de se déconnecter
    LocalStorageHelper.setItem('loggedout', true);
    this._navigationHelper.navigateTo(ROUTES['login'].name);
  }

}
