import { Component,Input,Output,EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import {IncidentService} from '@app/services';
 
@Component({
  selector: 'app-add-incident-modal',
  templateUrl: './add-incident-modal.component.html',
  styleUrls: ['./add-incident-modal.component.css']
})
export class AddIncidentModalComponent {
  @Input() typesIncidentsList=[];
  microIncidentsList=[];
  @Input() prestatairesList=[];
  type=0;
  micro=0;
  date_incident=moment().format('YYYY-MM-DD');
  horaire_incident=moment().format('HH:mm');
  prestataire=0;
  action=0;
  code_tournee;
  code_ramassage;
  @Output() eventAddIncident = new EventEmitter();
  images=[];

  constructor(
    private _toastr: ToastrService,
    private incidentService: IncidentService
  ) {
  }

  parseNumber(variable, fixed = false) {
    return fixed ? Number(parseFloat(variable)) : Number(parseFloat(variable).toFixed(2));
  }

  getMicroIncidents(){
    let typeIncident=this.typesIncidentsList.find(type=>type.id_type_incident==this.type);
    this.microIncidentsList=typeIncident.micro_incidents;
    this.micro=0;
    if(this.getTypeTag() == 'PANNE') {
      this.code_ramassage = null;
    }
  }

  validForm(){
      let dateBeforeNow=moment(this.date_incident+' '+this.horaire_incident).format('YYYY-MM-DD HH:mm:00')<=moment().format('YYYY-MM-DD HH:mm:00');
      return this.prestataire!=0 && this.type!=0 && this.code_tournee && (this.code_ramassage || this.getTypeTag()=='PANNE') && (this.action!=0 || (this.getTypeTag()!='PANNE' && this.getTypeTag()!='ACCESS')) && this.date_incident && dateBeforeNow; 
  }

  addIncident(){
      let params={
        'id_prestataire' : this.prestataire,
        'id_type_incident' : this.type,
        'code_ramassage' : this.code_ramassage,
        'micro_incident' : this.micro==0?null:this.micro,
        'action' : this.action==0?null:this.action,
        'date' : moment(this.date_incident+' '+this.horaire_incident).format('YYYY-MM-DD HH:mm:00'),
        'code_tournee' : this.code_tournee
      };
      const formData = new FormData();
      Object.keys(params).forEach(param=>{
        if(params[param]){
          formData.append(param, params[param]); 
        }
      });
      if(this.images && this.images.length>0){
        console.log(this.images);
        for (let i=0;i<this.images.length;i++) {
          formData.append('images['+i+']', this.images[i]);
        }
      }
      this.incidentService.saveIncident(formData).subscribe(data => {
        this.eventAddIncident.emit(true);
        this.horaire_incident=moment().format('HH:mm');
        this.date_incident=moment().format('YYYY-MM-DD');
        this.action=0;
        this.code_ramassage=null;
        this.code_tournee=null;;
        this.prestataire=0;
        this.type=0;
        this.micro=0;
      },err => {
        this.eventAddIncident.emit(false);
        this._toastr.error(err._response.message);
        this.horaire_incident=moment().format('HH:mm');
        this.date_incident=moment().format('YYYY-MM-DD');
        this.action=0;
        this.code_ramassage=null;
        this.code_tournee=null;;
        this.prestataire=0;
        this.type=0;
        this.micro=0;
      });
  }

  onFileChange(event) {
    let files=event.srcElement.files;
    this.images=[];
    Object.values(files).forEach(file=>{
      let isExtensionInvalid = !this.isValidPicture(file);
      if (file && !isExtensionInvalid) {
        this.images.push(file);
      }
    });
    
  }

  isValidPicture(file) {
    if (file) {
      const extension = file.name.split('.')[1].toLowerCase();
      if (['png', 'jpg', 'jpeg'].indexOf(extension.toLowerCase()) !== -1) {
        return true;
      }
    }
    return false;
  }

  getTypeTag(){
    let typeIncident=this.typesIncidentsList.find(type=>type.id_type_incident==this.type);
    return typeIncident?typeIncident.tag_mobile:'';
  }
}