import {GardienModel} from './gardien.model';
//import {PHONE_FRANCE} from "@app/config";

export class BailleurModel {
  public id: number;
  public nom: string;
  public code: string;
  public activation_code: string;
  // public telephone: string;
  // public email: string;
  public actif: boolean;
  public gardiens_count: number = 0;
  public gardiens_actif_count: number = 0;
  public gardiens_inactif_count: number = 0;
  public gardiens_enAttente_count: number = 0;
  public gardiens: GardienModel[];
  public communes: any[];


  constructor(bailleur: any) {
    this.id = bailleur.id;
    this.nom = bailleur.nom;
    this.code = bailleur.code;
    this.activation_code = bailleur.activation_code;
    // this.telephone = bailleur.telephone;
    // this.email = bailleur.email;
    this.actif = bailleur.actif;
    this.gardiens_count = bailleur.gardiens_count;
    this.gardiens =  bailleur.gardiens ? bailleur.gardiens :  [];
    this.communes =  bailleur.communes ? bailleur.communes : [];
  }

  getGardiens($afficher_inactif=false){
    if($afficher_inactif){
      return this.gardiens;
    }
    return this.gardiens.filter(x => x.actif !== 0);
  }

  // Retourne une liste des prestataires
  static toArray(data: BailleurModel[] = []): BailleurModel[] {
    const bailleurs: BailleurModel[] = [];
    data.forEach(item => {
      const bailleur: BailleurModel = new BailleurModel(item);
      bailleurs.push(bailleur);
    });
    return bailleurs;
  }

  hasCommunes(id_commune){
    return this.communes.filter(x => x.id_commune == id_commune).length > 0;
  }

  toJson() {
    return {
      id_bailleur: this.id,
      nom: this.nom,
      code: this.code,
      activation_code: this.activation_code,
      // telephone: this.telephone,
      // email: this.email,
      actif: this.actif,
      communes : this.communesToString(),
    };
  }

  communesToString(){
    return this.communes.map(x=>x.id_commune).sort().join();
  }
}
