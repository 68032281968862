import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '@app/config';
import {Observable} from 'rxjs';
import {IncidentModel} from '@app/models';

@Injectable({
  providedIn: 'root',
})

export class IncidentService {

  constructor(private _http: HttpClient) {
  }

  getIncidents(page = 1, filter: any = null): Observable<any> {
    return this._http.post(Config.api.incident.all + '&page=' + page, filter);
  }

  getIncidentsByRamassage(id_ramassage: any): Observable<any> {
    return this._http.post(Config.api.incident.ramassage, id_ramassage);
  }

  getIncidentsByTournee(id_tournee: any): Observable<any> {
    return this._http.post(Config.api.incident.tournee, id_tournee);
  }

  getTypeIncidents(): Observable<any> {
    return this._http.get(Config.api.incident.list);
  }

  getIncidentResolve(params): Observable<any> {
    return this._http.post(Config.api.incident.resolve, params);
  }

  saveIncident(params: any): Observable<any> {
    return this._http.post(Config.api.incident.create, params);
  }

  saveFusionResolveInRamassage(params): Observable<any> {
    return this._http.post(Config.api.incident.fusion_save.ramassage, params);
  }

  saveFusionResolveInTournee(params): Observable<any> {
    return this._http.post(Config.api.incident.fusion_save.tournee, params);
  }

  savePlannificationResolveInRamassage(params): Observable<any> {
    return this._http.post(Config.api.incident.plannification_save.ramassage, params);
  }
  saveFusionResolveInMission(params): Observable<any> {
    return this._http.post(Config.api.incident.fusion_save.mission, params);
  }
  savePlannificationResolveInTournee(params): Observable<any> {
    return this._http.post(Config.api.incident.plannification_save.tournee, params);
  }

  updateIncidentStatus(params: any): Observable<any> {
    return this._http.post(Config.api.incident.update_status, params);
  }

  updateIncidentClasse(params: any): Observable<any> {
    return this._http.post(Config.api.incident.update_classe, params);
  }

  updateTypeIncident(params: any): Observable<any> {
    return this._http.post(Config.api.incident.update_typeincident, params);
  }

  updateComment(params: any): Observable<any> {
    return this._http.post(Config.api.incident.update_comment, params);
  }

  updateDateDepot(params: any): Observable<any> {
    return this._http.post(Config.api.incident.update_datedepot, params);
  }

  deleteIncident(params: any): Observable<any> {
    return this._http.post(Config.api.incident.delete, params);
  }

  annulerReplanification(params): Observable<any> {
    return this._http.post(Config.api.incident.annuler_replanification, params);
  }

}
