import { Subscription } from 'rxjs';

declare var $: any;

export function Unsubscribe(blackList = [] ) {

  return function ( constructor ) {
    const original = constructor.prototype.ngOnDestroy;

    constructor.prototype.ngOnDestroy = function () {
      for (let prop in this) {
        const property = this[prop];
        if (!blackList.includes(prop)) {
          if (property && typeof property === "object" && property instanceof Subscription) {
            property.unsubscribe();
          }
        }
      }

      original && typeof original === 'function' && original.apply(this, arguments);
    };
  }

}
