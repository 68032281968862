import {Directive, Input, TemplateRef, ViewContainerRef} from "@angular/core";
import {LocalStorageHelper } from '@app/helpers';
import {USER_SlUG} from "@app/config";

@Directive({
  selector: '[canAccess]'
})

// Gère l'affichage des éléments graphiques selon le role donné
export class CanAccessDirective {
  @Input('canAccess') set canAccess(role: any) {
    const userRole = LocalStorageHelper.getItem(USER_SlUG);
    if (userRole && (role === userRole.role || role.indexOf(userRole.role) !== -1)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  };

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) {}
}
