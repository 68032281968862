export class DepotModel {

  public id_depot: number;
  public id_prestataire: number;
  public prestataires: any[];
  public libelle: string;
  public adresse: string;
  public lat: number;
  public lon: number;

  constructor(depot: any) {
    this.id_depot = depot.id_depot;
    this.id_prestataire = depot.id_prestataire;
    this.prestataires = depot.prestataires;
    this.libelle = depot.libelle;
    this.adresse = depot.adresse;
    this.lat = depot.lat;
    this.lon = depot.lon;
  }

  // Retourne une liste des utilisateurs
  static ToDepotArray(data: DepotModel[] = []): DepotModel[] {
    const depots: DepotModel[] = [];
    data.forEach(currDepot => {
      const depot: DepotModel = new DepotModel(currDepot);
      depots.push(depot);
    });
    return depots;
  }

  toJson() {
    return {
      id_depot: this.id_depot,
      libelle: this.libelle,
      adresse: this.adresse,
      lat: this.lat,
      lon: this.lon
    };
  }
}
