import {RAMASSAGE_STATUTS, WORKFLOWLIST} from '@app/config';

export class Ramassage {

  constructor() {
  }

  public static getStatutWorkflow(ram) {
    const statut = ram ? (ram.workflows ? ram.workflows.statut_workflow : null) : null;
    let result;
    if (statut) {
      WORKFLOWLIST.forEach(elem => {
        if (statut === elem.key) {
          result = Number(((elem.value / WORKFLOWLIST.length) * 100).toFixed(2));
        }
      });
    }
    return (result ? result : 0);
  }

  public static getStatusRamassage(ramassage) {
    const found = RAMASSAGE_STATUTS.find((elem) => {
      return elem.key === ramassage.statut;
    });
    return found ? found :  {key: '', value: 'N/A', color : 'red-type', background: 'replan' };
  }

  public static getStatutRamassageArray(array) {
    let value = 0;
    if (array) {
      array.forEach(elem => {
        if(elem.statut != 'ANNULE')
           value += this.getStatutWorkflow(elem);
      });
    }
    const counter = array ? array.filter(elem => elem.statut != 'ANNULE').length : 0;
    return isNaN(value) ?  0 : (array ? ( counter ? (value / counter).toFixed(2) : 0) : 0);
  }
}
