import {TypeService} from '../services/api/type.service';
import {map} from 'rxjs/internal/operators';
import {TYPES_SlUG} from '@app/config';
import {Injectable,OnDestroy} from "@angular/core";
import {TypologieModel} from '@app/models';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class TypeHelper {
    constructor(private typeService: TypeService) {

    }

    getTypes(namespace="bo"){
        let data = localStorage.getItem(TYPES_SlUG);
        let types=namespace == "bo" && data ?JSON.parse(localStorage.getItem(TYPES_SlUG)):[];
        const subject = new BehaviorSubject<any>([]);
        subject.next(types);
        if(types.length==0){
          this.typeService.getTypes(namespace).pipe(
            map((data) => {
              localStorage.setItem(TYPES_SlUG,JSON.stringify(TypologieModel.ToTypologieArray(data.payload)));
              subject.next(TypologieModel.ToTypologieArray(data.payload));
            })
          )
          .subscribe();
          return subject;
        }
        return subject;
    }
}
