export class TemplateModel {
  public id: number;
  public code: string;
  public description: string;
  public enabled: boolean;
  public mode: string;
  public name: string;

  constructor(incident: any) {
    this.id = incident.id_template;
    this.code = incident.code;
    this.description = incident.description;
    this.enabled = !!incident.enabled;
    this.mode = incident.mode;
    this.name = incident.name;
  }

  // Retourne une liste des template
  static ToTemplateArray(data: TemplateModel[] = []): TemplateModel[] {
    const templates: TemplateModel[] = [];
    data.forEach(item => {
      const template: TemplateModel = new TemplateModel(item);
      templates.push(template);
    });
    return templates;
  }

  toJson() {
    return {
      id_template: this.id,
      code: this.code,
      description: this.description,
      enabled: this.enabled,
      mode: this.mode,
      name: this.name
    };
  }
}
