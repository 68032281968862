export class RdvModel {

  public id_rdv: number;
  public planifiee: number;
  public tournee_en_cours: number;
  public date_ramassage: string;
  public date_rdv: string;
  public code_ramassage: string;
  public nom_prestataires: string;
  public code_tournee: string;
  public id_tournee: string;
  public nom_commune: string;
  public numero: string;
  public nom_voie: string;
  public nom: string;
  public encombrants: any[];
  public prenom: string;
  public type: string;
  public statut: string;
  public id_feedback: number;
  public by_gardien: number;
  public has_img_depot: boolean = false;

  constructor(rdv: any) {
    this.id_rdv = rdv.id_rdv;
    this.planifiee = rdv.planifiee;
    this.tournee_en_cours = rdv.tournee_en_cours;
    this.date_ramassage = rdv.date_ramassage;
    this.date_rdv = rdv.date_rdv;
    this.id_tournee = rdv.id_tournee;
    this.code_ramassage = rdv.code_ramassage;
    this.nom_prestataires = rdv.nom_prestataires;
    this.code_tournee = rdv.code_tournee;
    this.encombrants = rdv.encombrants;
    this.numero = rdv.numero;
    this.nom_voie = rdv.nom_voie;
    this.nom_commune = rdv.nom_commune;
    this.nom = rdv.nom;
    this.prenom = rdv.prenom;
    this.type = rdv.type;
    this.statut = rdv.statut;
    this.id_feedback = rdv.id_feedback;
    this.by_gardien = rdv.by_gardien;
    this.has_img_depot = rdv.has_img_depot ? true : false;
  }

  // Retourne une liste des utilisateurs
  static ToRdvArray(data: RdvModel[] = []): RdvModel[] {
    const rdvs: RdvModel[] = [];
    data.forEach(currRdv => {
      const rdv: RdvModel = new RdvModel(currRdv);
      rdvs.push(rdv);
    });
    return rdvs;
  }

  toJson() {
    return {
      id_rdv: this.id_rdv,
      planifiee: this.planifiee,
      tournee_en_cours: this.tournee_en_cours,
      date_ramassage: this.date_ramassage,
      nom_commune: this.nom_commune,
      nom: this.nom,
      prenom: this.prenom,
      type: this.type,
      statut: this.statut,
      by_gardien: this.by_gardien,
      has_img_depot: this.has_img_depot
    };
  }
}
