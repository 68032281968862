import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '@app/config';
import {Observable} from 'rxjs';
import {UserModel} from '@app/models';

@Injectable({
  providedIn: 'root',
})

export class NotificationService {

  constructor(private _http: HttpClient) {
  }

  getTemplates(page = 1): Observable<any> {
    return this._http.post(Config.api.notification.all + '?page=' + page, null);
  }

  getTemplate(code: any = null): Observable<any> {
    return this._http.post(Config.api.notification.detail, code);
  }

  updateTemplate(params: any = null): Observable<any> {
    return this._http.post(Config.api.notification.update, params);
  }

}
