import { Output,EventEmitter, Component,Input } from '@angular/core';
import {IncidentService} from '@app/services';

declare var $: any;

@Component({
  selector: 'app-edit-comment-modal',
  templateUrl: './edit-comment-modal.component.html',
  styleUrls: ['./edit-comment-modal.component.css']
})
export class EditCommentModalComponent {
  @Input() id;
  @Input() commentaire;
  @Output() eventEditComIncident = new EventEmitter();

  constructor(private incidentService: IncidentService){

  }

  editComIncident(){
      let params={
        'id_incident' : this.id,
        'commentaire' : this.commentaire
      };
      this.incidentService.updateComment(params).subscribe(res => {
        this.eventEditComIncident.emit({result:true,commentaire:this.commentaire});
      },err => {
        this.eventEditComIncident.emit({result:false,error:err._response.message});
      });
  }
}