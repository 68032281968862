import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '@app/config';
import {Observable} from 'rxjs';
import {UserModel} from '@app/models';

@Injectable({
  providedIn: 'root',
})

export class UserService {

  constructor(private _http: HttpClient) {
  }

  getUsers(page = 1): Observable<any> {
    return this._http.get(Config.api.user.all + '&page=' + page);
  }

  getUser(): Observable<any> {
    return this._http.get(Config.api.user.get);
  }

  createUser(user: UserModel): Observable<any> {
    return this._http.post(Config.api.user.create, user);
  }

  updateUser(user: UserModel): Observable<any> {
    return this._http.post(Config.api.user.update, user);
  }

  updateCurrentUser(user: UserModel): Observable<any> {
    return this._http.post(Config.api.account.update, user);
  }

  deleteUser(user_id: any): Observable<any> {
    return this._http.post(Config.api.user.delete, user_id);
  }

  setPassword(params: any): Observable<any> {
    return this._http.post(Config.api.user.updatepassword, params);
  }

  getCommunes(): Observable<any> {
    return this._http.get(Config.api.commune.list);
  }

  send(params: any): Observable<any> {
    return this._http.post(Config.api.user.sendPasswordResetLink, params);
  }
}
