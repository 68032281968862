import {APP_NAME, MENU, ROUTES} from '@app/config';
import {Title} from "@angular/platform-browser";
import {Router} from "@angular/router";
import {Injectable} from "@angular/core";

@Injectable({providedIn: 'root'})
export class HeaderSetterHelper {

  private nodes: any;

  public constructor(private _title: Title, private _router: Router) {
  }

  public setTitleAndHeader() {
    this._router.events.subscribe(() => {
      this.setElementByRouteName();
      this.setPageTitle();
    });
  }

  // Affecte un titre a la page courante depuis la constante MENU
  public setPageTitle() {
    if (this.nodes) {
      if(this.nodes.fullTitle){
        this._title.setTitle(this.nodes.name);
      }else{
        if (this.nodes.sidebarName === this.nodes.name) {
          this._title.setTitle(APP_NAME + ' | ' + this.nodes.sidebarName);
        } else {
          this._title.setTitle(APP_NAME + ' | ' + this.nodes.sidebarName + ' / ' + this.nodes.name);
        }
      }

    }
  }

  // Affecte un menu breadcrumb pour une route donnée
  public getNodes() {
    const routeLink = this._router.url.split('?')[0];
    for (let KEY in ROUTES) {
      if (routeLink == ROUTES[KEY]['route']) {
        this.nodes = this.getMenuItemByRouteName(ROUTES[KEY]['name']);
        return this.nodes ? {
          name: this.nodes ? this.nodes.name : null,
          breadcrumb: this.nodes ? this.nodes.breadcrumb : null,
          hasBreadcrumb: this.nodes ? this.nodes.hasBreadcrumb : null
        } : null;
      }
    }

    return null;
  }

  public setElementByRouteName() {
    return this.getNodes() ? this.getNodes().name : null;
  }

  public setElementByRoutebreadcrumb() {
    return this.getNodes() ? this.getNodes().breadcrumb : null;
  }

  public hasBreadcrumb() {
    return this.getNodes() ? this.getNodes().hasBreadcrumb : null;
  }

  public getMenuItemByRouteName(routeName: string): any {
    for (const item of MENU) {
      if (item['routeName'] === routeName) {
        return item;
      }
    }
    return null;
  }

}
