import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '@app/config';
import {Observable} from 'rxjs';
import {UserModel} from '@app/models';

@Injectable({
  providedIn: 'root',
})

export class TerminalService {

  constructor(private _http: HttpClient) {
  }

  getTerminals(page: number = 1): Observable<any> {
    return this._http.get(Config.api.terminal.all + '&page=' + page);
  }

  createTerminal(terminal: any): Observable<any> {
    return this._http.post(Config.api.terminal.create, terminal);
  }

  updateTerminal(terminal: any): Observable<any> {
    return this._http.post(Config.api.terminal.update, terminal);
  }

  deleteTerminal(terminal): Observable<any> {
    return this._http.post(Config.api.terminal.delete, terminal);
  }

}
