import { Routes } from '@angular/router';
import {ROUTES} from '@app/config';
import {AppComponent} from './app.component';
import {AuthGuard} from '@app/guards';

export const appRoutes: Routes = [
  {path: '', redirectTo: ROUTES['tour'].path, pathMatch: 'full'},
  {path: ROUTES['tour'].path, loadChildren:'app/components/tour/tour.module#TourModule', data: { route: ROUTES['tour'].name }, canActivate: [AuthGuard]},
  {path: ROUTES['auth'].path, loadChildren:'app/components/auth/auth.module#AuthModule', data: { route: ROUTES['auth'].name }},
  {path: ROUTES['user'].path, loadChildren:'app/components/user/user.module#UserModule', data: { route: ROUTES['user'].name }, canActivate: [AuthGuard]},
  {path: ROUTES['moyen'].path, loadChildren:'app/components/moyen/moyen.module#MoyenModule', data: { route: ROUTES['moyen'].name }, canActivate: [AuthGuard]},
  {path: ROUTES['prestataire'].path, loadChildren:'app/components/prestataire/prestataire.module#PrestataireModule', data: { route: ROUTES['prestataire'].name }, canActivate: [AuthGuard]},
  {path: ROUTES['bailleur'].path, loadChildren:'app/components/bailleur/bailleur.module#BailleurModule', data: { route: ROUTES['bailleur'].name }, canActivate: [AuthGuard]},
  {path: ROUTES['gardien'].path, loadChildren:'app/components/gardien/gardien.module#GardienModule', data: { route: ROUTES['gardien'].name }, canActivate: [AuthGuard]},
  {path: ROUTES['incident'].path, loadChildren:'app/components/incident/incident.module#IncidentModule', data: { route: ROUTES['incident'].name }, canActivate: [AuthGuard]},
  {path: ROUTES['encombrant'].path, loadChildren:'app/components/encombrant/encombrant.module#EncombrantModule', data: { route: ROUTES['encombrant'].name }, canActivate: [AuthGuard]},
  {path: ROUTES['notification'].path, loadChildren:'app/components/notification/notification.module#NotificationModule', data: { route: ROUTES['notification'].name }, canActivate: [AuthGuard]},
  {path: ROUTES['scheduler'].path, loadChildren:'app/components/scheduler/scheduler.module#SchedulerModule', data: { route: ROUTES['scheduler'].name }, canActivate: [AuthGuard]},
  {path: ROUTES['terminal'].path, loadChildren:'app/components/terminal/terminal.module#TerminalModule', data: { route: ROUTES['terminal'].name }, canActivate: [AuthGuard]},
  {path: ROUTES['rdv'].path, loadChildren:'app/components/rdv/rdv.module#RdvModule', data: { route: ROUTES['rdv'].name }},
  {path: ROUTES['depot'].path, loadChildren:'app/components/depot/depot.module#DepotModule', data: { route: ROUTES['depot'].name }, canActivate: [AuthGuard]},
  {path: ROUTES['holiday'].path, loadChildren:'app/components/holiday/holiday.module#HolidayModule', data: { route: ROUTES['holiday'].name }, canActivate: [AuthGuard]},
  {path: ROUTES['client'].path, loadChildren:'app/components/client/client.module#ClientModule', data: { route: ROUTES['client'].name }, canActivate: [AuthGuard]},
  {path: ROUTES['loading'].path, loadChildren:'app/components/loading/loading.module#LoadingModule', data: { route: ROUTES['loading'].name }},
  {path: ROUTES['adresse.import'].path, loadChildren:'app/components/adresse/import/import.module#AdresseImportModule', data: { route: ROUTES['adresse.import'].name }},
  {path: '**', component : AppComponent, data: { route: ROUTES['404'].name}}
];
