import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '@app/config';
import {Observable} from 'rxjs';
import {BailleurModel} from '@app/models';

@Injectable({
  providedIn: 'root',
})

export class BailleurService {

  constructor(private _http: HttpClient) {
  }

  getBailleurs(): Observable<any> {
    return this._http.get(Config.api.bailleur.all);
  }

  getBailleursForSelect(): Observable<any> {
    return this._http.get(Config.api.bailleur.choicelist);
  }

  getBailleursGardiens(id_bailleur: number): Observable<any> {
    return this._http.get(Config.api.gardien + '?id_bailleur=' + id_bailleur);
  }

  createBailleur(bailleur: BailleurModel): Observable<any> {
    return this._http.post(Config.api.bailleur.create, bailleur);
  }

  updateBailleur(bailleur: BailleurModel): Observable<any> {
    return this._http.post(Config.api.bailleur.update, bailleur);
  }

  deleteBailleur(id_bailleur: number): Observable<any> {
    return this._http.get(Config.api.bailleur.delete +'/'+ id_bailleur);
  }

  dettacheBailleurMoyen(params: any): Observable<any> {
    return this._http.post(Config.api.bailleur.dettach, params);
  }

  attachBailleurMoyen(params: any): Observable<any> {
    return this._http.post(Config.api.bailleur.attach, params);
  }

}
