import { Injectable } from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AuthService} from './api/auth.service';
import {HandleResponse} from './handleResponse.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class HttpInteceptor implements HttpInterceptor {

  constructor(
    private _handleResponse: HandleResponse,
    private _auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this._auth.getToken();
    let newReq;
    if (token) {
      newReq = req.clone({headers: req.headers.set('Authorization', 'Bearer ' + token)});
    } else {
      newReq = req;
    }

    return next.handle(newReq).pipe(
      map((event) => {
        if (event instanceof HttpResponse) {
          this._handleResponse.handleData(event.body);
        }
        return event;
      }),
      catchError((e) => {
        this.handleError(e);
        return throwError(e.error);
      })
    );
  }

  handleError(error) {
    this._handleResponse.handleError(error);
  }
}
