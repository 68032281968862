import {typeIncidentModel} from '@app/models';

export class IncidentModel {
  public id: number;
  public description: string;
  public action: string;
  public id_tournee: number;
  public id_ramassage: number;
  public code_ramassage: number;
  public code_tournee: number;
  public a_traiter:number;
  public date_resolution: string;
  public prestataire: any[];
  public type_incident: typeIncidentModel;
  public statut: string;
  public date_depot: string;
  public commentaire: string;
  public images: Image[];
  public created_at: string;
  public id_feedback: number;
  public micro_incident: string;
  public lon: string;
  public lat: string;
  public classe: string;
  public nom_commune: string;
  public typologie: string;

  constructor(incident: any) {
    this.id = incident.id_incident;
    this.description = incident.description;
    this.action = incident.action;
    this.id_tournee = incident.id_tournee;
    this.id_ramassage = incident.id_ramassage;
    this.code_ramassage = incident.code_ramassage;
    this.code_tournee = incident.code_tournee;
    this.date_resolution = incident.date_resolution;
    this.prestataire = incident.prestataire;
    this.type_incident = incident.type_incident;
    this.statut = incident.statut;
    this.a_traiter  = incident.a_traiter;
    this.date_depot  = incident.date_depot;
    this.commentaire  = incident.commentaire;
    this.images = incident.images;
    this.created_at = incident.created_at;
    this.id_feedback = incident.id_feedback;
    this.micro_incident = incident.micro_incident;
    this.lon = incident.lon;
    this.lat = incident.lat;
    this.nom_commune = incident.nom_commune;
    this.typologie = incident.typologie;
  }

  // Retourne une liste des incidents
  static ToIncidentArray(data: IncidentModel[] = []): IncidentModel[] {
    const incidents: IncidentModel[] = [];
    data.forEach(item => {
      const incident: IncidentModel = new IncidentModel(item);
      incidents.push(incident);
    });
    return incidents;
  }

  toJson() {
    return {
      id_incident: this.id,
      description: this.description,
      action: this.action,
      id_tournee: this.id_tournee,
      id_ramassage: this.id_ramassage,
      prestataire: this.prestataire,
      id_type_incident: this.type_incident,
      statut: this.statut,
      a_traiter: this.a_traiter,
      images: this.images,
      created_at: this.created_at,
      micro_incident: this.micro_incident,
      nom_commune: this.nom_commune,
      typologie: this.typologie,
    };
  }
}

export interface Image {
  id: number;
  images: string;
}
