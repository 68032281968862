import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService, MissionService, StatistiqueService} from '@app/services';
import {finalize} from 'rxjs/operators';
import {MissionModel, Ramassage} from '@app/models';
import {FormControl, FormGroup} from '@angular/forms';
import {LocalStorageHelper, parseDate} from '@app/helpers';
import {MISSION_FILTRE, ENCOMBRANT_TYPES, MISSIONS_STATUTS, USER_ROLES, ROUTES} from '@app/config';
import {Unsubscribe} from '@app/decorators';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import {
  TypeHelper
} from '@app/helpers';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { getTypologieIcon } from 'app/helpers/business.helper';

declare var $: any;

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.scss']
})

@Unsubscribe()
export class TourComponent implements OnInit, OnDestroy {

  loadingExport = {
    'syn': false,
    'detail': false,
    'incident': false,
  }
  isLoading = true;
  public filterForm: FormGroup;
  public filterFormExport: FormGroup;
  missionList: MissionModel[] = [];
  missionsPagination: any;
  today = new Date();
  dateInput = false;
  dateEndInput = false;
  ENCOMBRANT_TYPES = ENCOMBRANT_TYPES;
  MISSIONS_STATUTS = MISSIONS_STATUTS;
  ROUTES = ROUTES;
  namespace: string;
  admin = (USER_ROLES.filter(role => role.key === 'SUPER_ADMIN' || role.key === 'ADMIN')).map(elem => elem.key);

  getTypologieIcon = getTypologieIcon;

  constructor(private _missionService: MissionService,
              private _statistiqueService: StatistiqueService,
              private typeHelper: TypeHelper,
              private _route: ActivatedRoute,
              private _authService: AuthService,
              private _toastr: ToastrService) {

  }

  ngOnInit() {
    this.setFilterForm();
    this.setFilterFormExport();
    this.getMissionsListe();
    this.setNamespace();
    // this.getTypes();
  }

  getTypes(){

    this.typeHelper.getTypes().subscribe(types=>{
      console.log(types)
      this.ENCOMBRANT_TYPES=types/*.filter(t=>t.code!='TOXIQUE')*/;
  });
  }
  setNamespace() {
    const route = this._route.snapshot.data['route'];
    switch (route) {
      case ROUTES['rdv'].name:
        this.namespace = 'bo';
        break;
      case ROUTES['rdv.aquity'].name:
        this.namespace = 'aquity';
        break;
      case ROUTES['rdv.client'].name:
        this.namespace = 'client';
        break;
    }
    if ('aquity' === this.namespace) {
      const token = this._route.snapshot.paramMap.get('token');
      this._authService.setToken(token);
    }
    this.getTypes();
  }

  setFilterFormExport() {
    this.filterFormExport = new FormGroup({
      start_date_var: new FormControl(this.today),
      start_date_limit_var: new FormControl(null),
      start_date: new FormControl(''),
      start_date_limit: new FormControl(null),
      statut: new FormControl(''),
      operateur: new FormControl('')
    });
    const storage = LocalStorageHelper.getItem(MISSION_FILTRE);
    if (storage) {
      storage['start_date_var'] = new Date();
      if (storage.start_date) {
        const start_var = storage.start_date.split('-');
        storage['start_date_var'] = moment(start_var[0] + '-' + start_var[1] + '-' + start_var[2], "DD-MM-YYYY").toDate();
      }
      storage['start_date_limit_var'] = null;
      if (storage.start_date_limit) {
        const start_var_limit = storage.start_date_limit.split('-');
        storage['start_date_limit_var'] = moment(start_var_limit[0] + '-' + start_var_limit[1] + '-' + start_var_limit[2], "DD-MM-YYYY").toDate();
      }
      this.filterForm.patchValue(storage);
    }
    this.dateInput = !!this.filterForm.value.start_date && !!this.filterForm.value.operateur;
    this.dateEndInput = !!this.filterForm.value.start_date_limit && !!this.filterForm.value.operateur;
  }
  setFilterForm() {
    this.filterForm = new FormGroup({
      code_tournee: new FormControl(''),
      type: new FormControl(''),
      start_date_var: new FormControl(this.today),
      start_date_limit_var: new FormControl(null),
      start_date: new FormControl(''),
      start_date_limit: new FormControl(null),
      statut: new FormControl(''),
      operateur: new FormControl('')
    });
    const storage = LocalStorageHelper.getItem(MISSION_FILTRE);
    if (storage) {
      storage['start_date_var'] = new Date();
      if (storage.start_date) {
        const start_var = storage.start_date.split('-');
        storage['start_date_var'] = moment(start_var[0] + '-' + start_var[1] + '-' + start_var[2], "DD-MM-YYYY").toDate();
      }
      storage['start_date_limit_var'] = null;
      if (storage.start_date_limit) {
        const start_var_limit = storage.start_date_limit.split('-');
        storage['start_date_limit_var'] = moment(start_var_limit[0] + '-' + start_var_limit[1] + '-' + start_var_limit[2], "DD-MM-YYYY").toDate();
      }
      this.filterForm.patchValue(storage);
    }
    this.dateInput = !!this.filterForm.value.start_date && !!this.filterForm.value.operateur;
    this.dateEndInput = !!this.filterForm.value.start_date_limit && !!this.filterForm.value.operateur;
  }

  saveFilterToStorage() {
    // I create this logic to ignore filtring data if the only option is the date
    // let filterallDates = false;
    // Object.keys(this.filterForm.value).forEach((key) => {
    //   if (key !== 'start_date') {
    //     if (this.filterForm.value[key]) {
    //       filterallDates = true;
    //     }
    //   }
    // });
    // if (filterallDates) {
    //   LocalStorageHelper.setItem(MISSION_FILTRE, this.filterForm.value);
    // }
    LocalStorageHelper.setItem(MISSION_FILTRE, this.filterForm.value);
  }

  getMissionsListe(page = 1) {
    this.isLoading = true;
    this.setDates();
    this.filterForm.controls['start_date_var'].disable();
    this.filterForm.controls['start_date_limit_var'].disable();
    this.saveFilterToStorage();
    this._missionService.getMissions(page, this.filterForm.value)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        response => {
          this.missionList = MissionModel.ToMissionArray(response.payload.data);
          this.missionsPagination = {
            id: 'old',
            itemsPerPage: response.payload.per_page,
            currentPage: response.payload.current_page,
            totalItems: response.payload.total
          };
        }
      );
    this.filterForm.controls['start_date_var'].enable();
    this.filterForm.controls['start_date_limit_var'].enable();
  }

  onPageChange(page: any): void {
    this.getMissionsListe(page);
  }

  onIsFilled(target) {
    switch (target) {
      case 'code_tournee':
        return this.filterForm.value.code_tournee !== '' && this.filterForm.value.code_tournee;
      case 'start_date':
        return this.dateInput;
      case 'start_date_limit':
        return this.dateEndInput;
      case 'operateur':
        let condition = !(this.filterForm.value.operateur !== '' && this.filterForm.value.operateur);
        if (condition) {
          this.filterForm.patchValue({
            start_date_var: this.today,
            start_date_limit_var: null
          });
        }
        return condition;
      default:
        return true;
    }
  }


  onClearInput(target) {
    switch (target) {
      case 'code_tournee':
        this.filterForm.patchValue(
          {
            code_tournee: ''
          }
        );
        break;
      case 'start_date':
        this.filterForm.patchValue(
          {
            start_date_var: this.today,
            start_date: '',
            operateur: ''
          }
        );
        break;
      case 'start_date_limit':
        this.filterForm.patchValue(
          {
            start_date_limit_var: null,
            start_date_limit: null,
            operateur: ''
            }
        );
        break;
      default:
        return true;
    }
    this.dateInput = !!this.filterForm.value.start_date && !!this.filterForm.value.operateur;
    this.dateEndInput = !!this.filterForm.value.start_date_limit && !!this.filterForm.value.operateur;
  }


  operatorChange() {
    if (this.filterForm.value.operateur) {
      this.filterForm.patchValue(
        {
          start_date_var: this.today,
          start_date: (this.filterForm.value.start_date_var ? parseDate(this.filterForm.value.start_date_var) : ''),
        }
      );
      this.dateInput = true;
      this.dateEndInput = true;
    } else {
      this.dateInput = false;
      this.dateEndInput = false;
    }
  }

  nbrFiltre() {
    let count = 0;
    count += (this.filterForm.value.code_tournee !== '' && this.filterForm.value.code_tournee) ? 1 : 0;
    count += (this.filterForm.value.operateur !== '') ? 1 : 0;
    count += (this.filterForm.value.type !== '') ? 1 : 0;
    count += (this.filterForm.value.statut !== '') ? 1 : 0;
    count += this.dateInput ? 1 : 0;
    return count;
  }

  // onClearAllInput() {
  //     this.filterForm.patchValue({
  //       code_tournee: '',
  //       operateur: '',
  //       type: '',
  //       statut: '',
  //       start_date_var: this.today,
  //       start_date_limit_var: null
  //     });
  //     LocalStorageHelper.removeItem(MISSION_FILTRE);
  //     this.dateInput = !!this.filterForm.value.start_date && !!this.filterForm.value.operateur;
  //     this.dateEndInput = !!this.filterForm.value.start_date_limit && !!this.filterForm.value.operateur;
  //     this.getMissionsListe();
  // }

  onClearAllInput() {
    this.filterForm = new FormGroup({
      code_tournee: new FormControl(''),
      type: new FormControl(''),
      start_date_var: new FormControl(this.today),
      start_date_limit_var: new FormControl(null),
      start_date: new FormControl(''),
      start_date_limit: new FormControl(null),
      statut: new FormControl(''),
      operateur: new FormControl('')
    });
    LocalStorageHelper.removeItem(MISSION_FILTRE);
    this.dateInput = !!this.filterForm.value.start_date && !!this.filterForm.value.operateur;
    this.dateEndInput = !!this.filterForm.value.start_date_limit && !!this.filterForm.value.operateur;
    this.getMissionsListe();
  }

  getStatutWorkflow(ramassage) {
    return Ramassage.getStatutWorkflow(ramassage);
  }

  ngOnDestroy() {
    // this.onClearAllInput();
  }

  getVille(villes){
    let result = []
    if(villes){
      villes.forEach(element => {
        result.push(element.rdv.nom_commune)
      });
      return [...new Set( result.map(obj => obj)) ].join(', ')
    }

  }

  // récupérer le fichier csv de la synthese des tournees
  getSyntheseTourneeCsv() {
    this.setDates();
    this.saveFilterToStorage();
    return this._statistiqueService.getSyntheseTourneesCsv(this.filterForm.value).subscribe(
      response => {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, 'syntheseTournees.xlsx');
      }
    );
  }
  getSyntheseTourneeCsvExport() {
    this.loadingExport.syn = true
    // this.setDatesExport();
    if(!this.setDatesExport()){
      this.loadingExport.syn = false;
      return false
    }
    this.saveFilterToStorage();
    return this._statistiqueService.getSyntheseTourneesCsv(this.filterFormExport.value).subscribe(
      response => {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, 'syntheseTournees.xlsx');
        this.loadingExport.syn = false;
      }
    );
  }

  // récupérer le fichier csv du détail des tournees
  getDetailTourneeCsvExport() {
    this.loadingExport.detail = true;
    // this.setDatesExport();
    if(!this.setDatesExport()){
      this.loadingExport.detail = false;
      return false
    }
    this.saveFilterToStorage();
    return this._statistiqueService.getDetailTourneesCsv(this.filterFormExport.value).subscribe(
      response => {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, 'syntheseRdv.xlsx');
        this.loadingExport.detail = false;
      }
    );
  }
  getDetailTourneeCsv() {
    this.setDates();
    this.saveFilterToStorage();
    return this._statistiqueService.getDetailTourneesCsv(this.filterForm.value).subscribe(
      response => {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, 'syntheseRdv.xlsx');
      }
    );
  }

  // récupérer le fichier csv des incidents des tournees
  getIncidentsTourneeCsvExport() {
    this.loadingExport.incident = true;
    // this.setDatesExport();
    if(!this.setDatesExport()){
      this.loadingExport.incident = false;
      return false
    }
    this.saveFilterToStorage();
    return this._statistiqueService.getIncidentsTourneesCsv(this.filterFormExport.value).subscribe(
      response => {
        // var downloadURL = window.URL.createObjectURL(response);
        // var link = document.createElement('a');
        // link.href = downloadURL;
        // link.download = "incidentsTournees.xlsx";
        // link.click();
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, 'incidentsTournees.xlsx');
        this.loadingExport.incident = false;
      }
    );
  }
  getIncidentsTourneeCsv() {
    this.setDates();
    this.saveFilterToStorage();
    return this._statistiqueService.getIncidentsTourneesCsv(this.filterForm.value).subscribe(
      response => {
        // var downloadURL = window.URL.createObjectURL(response);
        // var link = document.createElement('a');
        // link.href = downloadURL;
        // link.download = "incidentsTournees.xlsx";
        // link.click();
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, 'incidentsTournees.xlsx');
      }
    );
  }

  //affecter les valeurs des dates
  setDates(){
    this.filterForm.patchValue({
      start_date: this.filterForm.value.operateur ? (this.filterForm.value.start_date_var ? parseDate(this.filterForm.value.start_date_var) : '') : '',
      start_date_limit: this.filterForm.value.operateur=='between' ? (this.filterForm.value.start_date_limit_var ? parseDate(this.filterForm.value.start_date_limit_var) : null):null
    });
  }

  setDatesExport(){

    this.filterFormExport.patchValue({'operateur':'between'});
    this.filterFormExport.patchValue({
      start_date: this.filterFormExport.value.operateur ? (this.filterFormExport.value.start_date_var ? parseDate(this.filterFormExport.value.start_date_var) : '') : '',
      start_date_limit: this.filterFormExport.value.operateur=='between' ? (this.filterFormExport.value.start_date_limit_var ? parseDate(this.filterFormExport.value.start_date_limit_var) : null):null,
      // diff: diff
    });
    if(this.filterFormExport.value.start_date == '' || this.filterFormExport.value.start_date == null || this.filterFormExport.value.start_date_limit_var == '' || this.filterFormExport.value.start_date_limit_var == null){
      this._toastr.error("Merci de séléctionner au maximum 6 mois");
      return false;
    }
    let start = this.filterFormExport.value.start_date.split('-');
    let end = this.filterFormExport.value.start_date_limit.split('-');
    start = moment(moment(start[0] + '-' + start[1] + '-' + start[2], "DD-MM-YYYY").toDate())
    end = moment(moment(end[0] + '-' + end[1] + '-' + end[2], "DD-MM-YYYY").toDate())
    let diff = end.diff(start, 'days');
    if(diff < 0){
      this._toastr.error("Date fin doit être une date supérieure à la date début");
      return false;
    }
    if(diff > 366 || this.filterFormExport.value.start_date == '' || this.filterFormExport.value.start_date == null || this.filterFormExport.value.start_date_limit_var == '' || this.filterFormExport.value.start_date_limit_var == null){
      this._toastr.error("Merci de séléctionner au maximum une plage de 12 mois");
      return false;
    }
    return true;
  }

  isValidDates(){
     return this.filterForm.value.start_date_var<this.filterForm.value.start_date_limit_var;
  }
}
