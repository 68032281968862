export class ClientModel {
  public id_client: number;
  public nom: string;
  public prenom: string;
  public email: string;
  public mobile: string;
  public fixe: number;
  public sexe: string;
  public rdvs: any;

  constructor(client: any) {
    this.id_client = client.id_client;
    this.nom = client.nom;
    this.prenom = client.prenom;
    this.email = client.email;
    this.mobile = client.mobile;
    this.sexe = client.sexe;
    this.fixe = client.fixe;
  }

  // Retourne une liste des clients
  static ToClientArray(data: ClientModel[] = []): ClientModel[] {
    const clients: ClientModel[] = [];
    data.forEach(item => {
      const client: ClientModel = new ClientModel(item);
      clients.push(client);
    });
    return clients;
  }

  toJson() {
    return {
      id_client: this.id_client,
      nom: this.nom,
      prenom: this.prenom,
      email: this.email,
      mobile: this.mobile,
      fixe: this.fixe,
      sexe: this.sexe
    };
  }
}
