export class GardienModel {
  public id: number;
  public id_client: number;
  public id_bailleur: number;
  public nom_bailleur: string;
  public nom: string;
  public prenom: string;
  public email: string;
  public telephone: string;
  public actif: number;
  public sexe: string;
  public communes: any[];
  public communes_abrv_str: string = null;
  public communes_str: string = null;
  public created_at: Date = null;
  public valider_le: Date = null;
  public inactif_le: Date = null;
  public nbr_rdv: number = 0;

  constructor(client: any) {
    this.id = client.id;
    this.id_client = client.id_client;
    this.id_bailleur = client.id_bailleur;
    this.nom_bailleur = client.nom_bailleur;
    this.nom = client.nom;
    this.prenom = client.prenom;
    this.email = client.email;
    this.telephone = client.telephone;
    this.sexe = client.sexe;
    this.actif = client.actif;
    this.communes = client.communes;
    this.created_at = client.created_at;
    this.valider_le = client.valider_le;
    this.inactif_le = client.inactif_le;
    this.nbr_rdv = client.nbr_rdv;
  }

  getFullName(){
    return this.nom + " " + this.prenom;
  }
  // Retourne une liste des clients
  static toArray(data: GardienModel[] = []): GardienModel[] {
    const clients: GardienModel[] = [];
    data.forEach(item => {
      const client: GardienModel = new GardienModel(item);
      clients.push(client);
    });
    return clients;
  }

  toJson() {
    return {
      id: this.id,
      id_client: this.id_client,
      id_bailleur: this.id_bailleur,
      nom_bailleur: this.nom_bailleur,
      nom: this.nom,
      prenom: this.prenom,
      email: this.email,
      telephone: this.telephone,
      sexe: this.sexe,
      created_at : this.created_at,
      valider_le : this.valider_le,
      inactif_le : this.inactif_le,
      nbr_rdv : this.nbr_rdv,
    };
  }

  getCommunesAbrvStr(){
    this.prepareCommunesToString();
    return this.communes_abrv_str;
  }

  getCommunesStr(){
    this.prepareCommunesToString();
    return this.communes_str;
  }

  private prepareCommunesToString(){
    if(this.communes_abrv_str == null){
      let abrv = [];
      let comm_name = [];
       this.communes.forEach( x => {
        abrv.push(x.abrv_commune);
        comm_name.push(x.nom_commune);
       });
       this.communes_abrv_str = abrv.join(', ');
       this.communes_str = comm_name.join(', ');
    }
  }
}
