import {Injectable} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {DEMO_USER, RESPONSE, ROUTES} from '@app/config';
import {UserModel} from '@app/models';
import {NavigationHelper} from '@app/helpers';
import {ToastrService} from 'ngx-toastr';

@Injectable({providedIn: 'root'})
export class HandleResponse {

  constructor(private _navigationHelper: NavigationHelper,
              private _toastr: ToastrService) {
  }

  handleError(error: HttpErrorResponse) {
    const errorCodes = [
      RESPONSE.DISPONIBILITE.ERROR.RDV,
      RESPONSE.DISPONIBILITE.ERROR.ADRESSE,
      RESPONSE.RDV.ERROR.DELETE_RDV_FORBIDEN,
      RESPONSE.RDV.ERROR.CANNOT_UPDATE,
      RESPONSE.RDV.ERROR.CANNOT_DELETE,
      RESPONSE.CLIENT.ERROR.CANNOT_DELETE,
      RESPONSE.ENCOMBRANT.ERROR.CANNOT_DELETE,
      RESPONSE.RDV.ERROR.RDV_CANCELED,
      RESPONSE.RDV.ERROR.RDV_DONE_CANNOT_CANCELE,
      RESPONSE.RDV.ERROR.RDV_CANNOT_CANCELE,
      RESPONSE.RDV.ERROR.RDV_TOURNEE_DONE_CANNOT_CANCELE,
      RESPONSE.RDV.ERROR.RDV_TOURNEE_PROCESSING_CANNOT_CANCELE,
      RESPONSE.INCIDENT.ERROR.RESOLVE.RAMASSAGE,
      RESPONSE.INCIDENT.ERROR.RESOLVE.INCIDENT,
      RESPONSE.INCIDENT.ERROR.RESOLVE.TOURNEE,
      RESPONSE.INCIDENT.ERROR.RESOLVE.CANNOT_REPLANNED
    ];

    if (error.error && error.error._response) {
      if (errorCodes.indexOf(error.error._response.code) !== -1) {
        this._toastr.error(error.error._response.message);
      } else if (error.error._response.code === RESPONSE.GLOBAL.UNAUTHORIZED) {
        this.logOut();
      }
    }
    else if (error.error.hasOwnProperty('error') &&
    (['Token not provided', 'TOKEN_EXPIRED', 'TOKEN_INVALID'].indexOf(error.error.error) !== -1)) {
      this.logOut();
    }
    else if (error.status === 500) {
      this._toastr.error(error.statusText);
    }
  }

  handleData(data: any) {
    const successCodes = [
      RESPONSE.USER.OK.CREATE,
      RESPONSE.USER.OK.UPDATE,
      RESPONSE.USER.OK.DELETE,
      RESPONSE.ACCOUNT.OK.UPDATE,
      RESPONSE.MOYEN.OK.CREATE,
      RESPONSE.MOYEN.OK.UPDATE,
      RESPONSE.MOYEN.OK.DELETE,
      RESPONSE.PRESTATAIRE.OK.CREATE,
      RESPONSE.PRESTATAIRE.OK.UPDATE,
      RESPONSE.PRESTATAIRE.OK.DELETE,
      RESPONSE.ENCOMBRANT.OK.CREATE,
      RESPONSE.PRESTATAIRE.OK.UPDATE,
      RESPONSE.PRESTATAIRE.OK.DELETE,
      RESPONSE.PRESTATAIRE.OK.ATTACH,
      RESPONSE.PRESTATAIRE.OK.DETTACH,
      RESPONSE.INCIDENT.OK.CREATE,
      RESPONSE.INCIDENT.OK.UPDATE,
      RESPONSE.INCIDENT.OK.DELETE,
      RESPONSE.TERMINAL.OK.CREATE,
      RESPONSE.TERMINAL.OK.UPDATE,
      RESPONSE.TERMINAL.OK.DELETE,
      RESPONSE.DEPOT.OK.CREATE,
      RESPONSE.DEPOT.OK.UPDATE,
      RESPONSE.DEPOT.OK.DELETE,
      RESPONSE.DEPOT.OK.DETACH,
      RESPONSE.DEPOT.OK.ATTACH,
      RESPONSE.DEPOT.OK.TYPE_UPDATED,
      RESPONSE.RDV.OK.SAVE,
      RESPONSE.RDV.OK.UPDATE,
      RESPONSE.RDV.OK.DELETE,
      RESPONSE.RDV.OK.DELETE_MISSION,
      RESPONSE.TEMPLATE.OK.UPDATE,
      RESPONSE.MISSION.OK.DONE,
      RESPONSE.MISSION.OK.SUCCES,
      RESPONSE.INCIDENT.OK.RESOLVE,
      RESPONSE.INCIDENT.OK.SUCCESS
    ];

    const warningCodes = [
      RESPONSE.RDV.ERROR.DISPONIBILITE_NOT_FOUND,
      RESPONSE.MISSION.OK.EXIST,
      RESPONSE.MISSION.OK.DISPONIBILITE_NOT_FOUND,
      RESPONSE.MOYEN.OK.DISPONIBILITE,
      RESPONSE.DEPOT.OK.ALREADY_ATTACHED
    ];

    if (data._response && successCodes.indexOf(data._response.code) !== -1) {
      this._toastr.success(data._response.message);
    }

    if (data._response && warningCodes.indexOf(data._response.code) !== -1) {
      this._toastr.warning(data._response.message);
    }

    // Check if token is expired
    if (data.hasOwnProperty('error') && data.error &&
      (['Token not provided', 'TOKEN_EXPIRED', 'TOKEN_INVALID'].indexOf(data.error) !== -1)) {
      this.logOut();
    }
  }

  logOut() {
    this._navigationHelper.navigateTo(ROUTES['login'].name);
    localStorage.clear();
  }
}
