import {Component, OnDestroy, OnInit} from '@angular/core';
import {NotificationService} from '@app/services';
import {Subscription} from 'rxjs';
import {TemplateModel} from '@app/models';
import {finalize} from 'rxjs/internal/operators';
import {NavigationHelper} from '@app/helpers';
import {ROUTES} from '@app/config';
import {Unsubscribe} from '@app/decorators';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})

@Unsubscribe()
export class NotificationComponent implements OnInit, OnDestroy {
  templatesList: TemplateModel[];
  isLoading = true;
  templatePagination: any;
  subscriptions = new Subscription();
  page: number;

  constructor(
    private _templateService: NotificationService,
    private _navigationHelper: NavigationHelper
  ) { }

  ngOnInit() {
    this.getTemplatesListe();
  }

  getTemplatesListe(page = 1) {
    this.isLoading = true;
    this.page = page;
    this._templateService.getTemplates(this.page)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        response => {
          this.templatesList = TemplateModel.ToTemplateArray(response.payload.data);
          this.templatePagination = {
            id: 'old',
            itemsPerPage: response.payload.per_page,
            currentPage: response.payload.current_page,
            totalItems: response.payload.total
          };
        }
      );
  }

  onPageChange(page) {
    this.getTemplatesListe(page);
  }

  showDetail(template: TemplateModel, mode: string) {
    this._navigationHelper.navigateTo([ROUTES['notification.detail'].name, template.code], {queryParams: { mode: mode }});
  }

  groupByCode(templates: any[]) {
    const groupedTemplates = [];
    templates.forEach(t => {
      if (groupedTemplates.find(i => i.code === t.code) === undefined) {
        groupedTemplates.push(t);
      } else {
        groupedTemplates.find(i => i.code === t.code).mode = ['MAIL', 'SMS'];
      }

    });
    return groupedTemplates;
  }

  ngOnDestroy() {}
}
