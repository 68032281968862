import {Component, OnDestroy, OnInit} from '@angular/core';
import {finalize, map} from 'rxjs/internal/operators';
import {IncidentService, PrestataireService, UserService} from '@app/services';
import {Image, IncidentModel, RdvModel, typeIncidentModel} from '@app/models';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {INCIDENT_FILTRE, INCIDENT_TYPES, RESPONSE, ROUTES, USER_SlUG ,INCIDENT_A_TRAITER} from '@app/config';
import {ActivatedRoute} from '@angular/router';
import {LocalStorageHelper, NavigationHelper} from '@app/helpers';
import {Unsubscribe} from '@app/decorators';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';
import { getTypologieIcon } from 'app/helpers/business.helper';

declare var $: any;

@Component({
  selector: 'app-incident',
  templateUrl: './incident.component.html',
  styleUrls: ['./incident.component.scss']
})

@Unsubscribe()
export class IncidentComponent implements OnInit, OnDestroy {
  incidentsList: IncidentModel[];
  isLoading = true;
  incidentPagination: any;
  selectedImages: Image[];
  selectedTournee: number;
  currentTournee: any;
  selectedAction: any;
  isStatusUpdating = false;
  generatedTournee: any;
  selectedStatus: string;
  selectedIncident: IncidentModel;
  incidentsFilter: FormGroup;
  subscriptions = new Subscription();
  statusList = INCIDENT_TYPES;
  prestatairesList: any = [];
  replannedList: any = [];
  listCommunes: any[] = [];
  typesIncidentsList: any = [];
  page: number;
  isRequesting = false;
  isResponded = false;
  a_traiter=INCIDENT_A_TRAITER;
  ROUTES = ROUTES;
  isClasseUpdating = false;
  roleAdmin;
  selectedTypeIncident;
  selectedClasse="";
  typeImage='incident';
  selectedLon;
  selectedLat;
  type=0;
  action=null;
  getTypologieIcon = getTypologieIcon;

  constructor(
    private _incidentService: IncidentService,
    private _prestataireService: PrestataireService,
    private _userService: UserService,
    private _navigationHelper: NavigationHelper,
    private _route: ActivatedRoute,
    private _toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.setIncidentFormFilter();
    this.getIncidentsListe();
    this.getPrestataires();
    this.getCommunes();
    this.getTypesIncident();
    this.roleAdmin=['ADMIN', 'SUPER_ADMIN'].indexOf(LocalStorageHelper.getItem(USER_SlUG).role)!=-1?true:false;
  }



  getTypeTag(){
    let typeIncident=this.typesIncidentsList.find(type=>type.id_type_incident==this.selectedTypeIncident);
    return typeIncident?typeIncident.tag_mobile:'';
  }

  setAction(){
    if(this.getTypeTag() == "ACCESS"){
      this.action = 'RETARD';
    }else if(this.getTypeTag() == "PANNE"){
      this.action = 'A_REPLANIFIER';
    }else{
      this.action = null;
    }
  }

  setIncidentFormFilter() {
    const id_ramassage = this._route.snapshot.queryParamMap.get('ramassage');
    this.incidentsFilter = new FormGroup({
      id_ramassage: new FormControl(id_ramassage),
      statut: new FormControl(''),
      id_type_incident: new FormControl(''),
      id_prestataire: new FormControl(''),
      commune: new FormControl(''),
      code: new FormControl(''),
      code_type: new FormControl(''),
      code_tournee: new FormControl(''),
      date: new FormControl(''),
      code_ramassage: new FormControl('')
    });
    const storage = LocalStorageHelper.getItem(INCIDENT_FILTRE);
    if (storage) {
      this.incidentsFilter.patchValue(storage);
    }
  }

  resetForm() {
    this.incidentsFilter.setValue({
      id_ramassage: '',
      statut: '',
      id_type_incident: '',
      id_prestataire: '',
      commune: '',
      code_tournee: '',
      date: '',
      code: '',
      code_type: '',
      code_ramassage: ''
    });
    this.getIncidentsListe();
  }

  onIsFilled(target) {
    switch (target) {
      case 'code':
        return this.incidentsFilter.value.code !== '' && this.incidentsFilter.value.code;
      case 'commune':
        return this.incidentsFilter.value.commune !== '' && this.incidentsFilter.value.commune;
      case 'date':
        return this.incidentsFilter.value.date !== '' && this.incidentsFilter.value.date;
      case 'code_type':
        return this.incidentsFilter.value.code_type !== '' && this.incidentsFilter.value.code_type;
      default:
        return true;
    }
  }

  onClearInput(target) {
    switch (target) {
      case 'code':
        this.incidentsFilter.patchValue(
          {code: '', code_type: ''}
        );
        break;
      case 'date':
        this.incidentsFilter.patchValue({date: ''});
        break;
    }
    this.getIncidentsListe();
  }

  getFilledControls(): number {
    let count = 0;
    count = (this.incidentsFilter.get('statut').value) ? count + 1 : count;
    count = (this.incidentsFilter.get('id_type_incident').value) ? count + 1 : count;
    count = (this.incidentsFilter.get('id_prestataire').value) ? count + 1 : count;
    count = (this.incidentsFilter.get('commune').value) ? count + 1 : count;
    count = (this.incidentsFilter.get('date').value) ? count + 1 : count;
    count = (this.incidentsFilter.get('code').value) ? count + 1 : count;
    return count;
  }

  getPrestataires() {
    // todo: refactor
    const userRole = LocalStorageHelper.getItem(USER_SlUG);
    if (['ADMIN', 'MANAGER','SUPER_ADMIN'].indexOf(userRole.role) !== -1) {
      this._prestataireService.getPrestatairesForSelect()
        .subscribe(data => this.prestatairesList = data.payload);
    }
  }

  getTypesIncident() {
    this._incidentService.getTypeIncidents()
      .subscribe(data => this.typesIncidentsList = data.payload);
  }

  getIncidentsListe(page = 1) {
    this.isLoading = true;
    this.page = page;
    const params = {...this.incidentsFilter.value};
    params['date'] = (this.incidentsFilter.value.date) ? moment(this.incidentsFilter.value.date, 'YYYY-MM-DD').format('YYYY-MM-DD') : null;
    params['code_ramassage'] = (this.incidentsFilter.value.code_type === 'ramassage' && this.incidentsFilter.value.code) ? this.incidentsFilter.value.code : null;
    params['code_tournee'] = (this.incidentsFilter.value.code_type === 'tournee' && this.incidentsFilter.value.code) ? this.incidentsFilter.value.code : null;
    LocalStorageHelper.setItem(INCIDENT_FILTRE, params);
    this._incidentService.getIncidents(this.page, params)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        response => {
          this.incidentsList = IncidentModel.ToIncidentArray(response.payload.data);
          this.incidentsList.sort((a, b) => (a.id < b.id) ? 1 : -1)
          this.incidentPagination = {
            id: 'old',
            itemsPerPage: response.payload.per_page,
            currentPage: response.payload.current_page,
            totalItems: response.payload.total
          };
        }
      );

  }

  onPageChange(page) {
    this.getIncidentsListe(page);
  }

  setSelected(images: Image[],incident) {
    this.selectedImages = images;
    this.selectedLon = incident.lon;
    this.selectedLat = incident.lat;
  }

  setSelectedAction(action: string, params,code:any) {
    this.isResponded = false;
    this.selectedAction = { action: action, params: params,code:code };
  }

  goToRdvDetail(code_rdv: any) {
    this._navigationHelper.navigateTo(ROUTES['rdv'].name, {queryParams: {code: code_rdv}});
  }

  goToTourneeDetail(id_tournee: any) {
    this._navigationHelper.navigateTo([ROUTES['tour.tournee'].name, id_tournee]);
  }

  confirmAnnulation(incident){
    this.selectedIncident = incident;
  }

  annulerReplanification(incident){
    this.selectedIncident = incident;
    const params = {
      id_incident: incident.id,
      id_tournee: incident.id_tournee,
      id_ramassage: incident.id_ramassage,
    };
    let query = this._incidentService.annulerReplanification(params);
    query
      .pipe(
        map((data: any) => {
          this.handleResponse(data._response, data.payload);
        })
      )
      .subscribe();
  }

  saveActionwithParams() {
    if (this.isActionValid()) {
      this.isResponded = false;
      if (this.selectedAction.action === 'MERGE' && this.selectedAction.code!=null) {
        this.saveIncidentResolveFusion(this.selectedAction.params);
      } else if(this.selectedAction.action === 'MERGE' && this.selectedAction.code==null){
        this.saveIncidentResolveFusionMission(this.selectedAction.params);
      }
      else {
        this.saveIncidentResolvePlannification(this.selectedAction.params);
      }
      this.isResponded = true;
    }
  }

  getCommunes() {
    this._userService.getCommunes().subscribe(
      data => this.listCommunes = data.payload
    );
  }

  isActionValid() {
    return this.selectedAction && this.selectedAction.action && this.selectedAction.params;
  }

  getIncidentResolve(incident: IncidentModel) {
    this.replannedList = [];
    this.currentTournee = null;
    this.selectedAction = null;
    this.selectedIncident = incident;
    this.isResponded = false;
    this.isRequesting = true;
    const params = { id_tournee: incident.id_tournee };
    this._incidentService.getIncidentResolve(params).subscribe(
      (data) => {
        this.replannedList = data.payload.solutions;
        this.currentTournee = data.payload.tournee;
      }
    );
    this.isRequesting = false;
  }

  saveIncidentResolveFusion(id_tournee) {
    const params = {
      id_incident: this.selectedIncident.id,
      id_tournee: this.selectedIncident.id_tournee,
      id_tournee_fusion: id_tournee,
      id_ramassage: this.selectedIncident.id_ramassage,
    };
    let query;
    if (this.selectedIncident.id_ramassage) {
      query = this._incidentService.saveFusionResolveInRamassage(params);
    } else {
      query = this._incidentService.saveFusionResolveInTournee(params);
    }
    query
      .pipe(
        map((data: any) => {
          this.handleResponse(data._response, data.payload);
        })
      )
      .subscribe();
  }
  saveIncidentResolveFusionMission(id_mission) {
    const params = {
      id_incident: this.selectedIncident.id,
      id_tournee: this.selectedIncident.id_tournee,
      id_mission_fusion: id_mission,
    };
    let query = this._incidentService.saveFusionResolveInMission(params);
    query
      .pipe(
        map((data: any) => {
          this.handleResponse(data._response, data.payload);
        })
      )
      .subscribe();
  }
  saveIncidentResolvePlannification(date: string) {
    const params = {
      id_incident: this.selectedIncident.id,
      id_tournee: this.selectedIncident.id_tournee,
      id_ramassage: this.selectedIncident.id_ramassage,
      date: date
    };

    let query;
    if (this.selectedIncident.id_ramassage) {
      query = this._incidentService.savePlannificationResolveInRamassage(params);
    } else {
      query = this._incidentService.savePlannificationResolveInTournee(params);
    }
    query
      .pipe(
        map((data: any) => {
          this.handleResponse(data._response, data.payload);
        })
      )
      .subscribe();
  }

  handleResponse(data, payload = null) {
    if(data.code == RESPONSE.INCIDENT.OK.REPLANIFICATION_ANNULER){
      this._toastr.success(data.message);
      this.selectedIncident.statut = 'ANNULE';
    }else if(data.code == RESPONSE.INCIDENT.ERROR.REPLANIFICATION_ANNULER){
      this._toastr.error(data.message);
    }else{
      if ([RESPONSE.INCIDENT.OK.SUCCESS, RESPONSE.INCIDENT.OK.RESOLVE].indexOf(data.code) !== -1) {
        this.generatedTournee = payload.tournee;
        this.showSuccessPopup();
        this.selectedIncident.statut = 'RESOLU';
        this.selectedIncident.date_resolution = this.generatedTournee.created_at;
      } else if (RESPONSE.INCIDENT.OK.UPDATE) {
        this.generatedTournee = payload && payload.tournee ? payload.tournee : (payload && payload.mission ?payload.mission:null);
        this.showSuccessPopup();
        this.incidentsList.find(elt => elt.id === this.selectedIncident.id).statut = this.selectedStatus;
        this.selectedIncident.statut = 'RESOLU';
        this.selectedStatus = null;
        this.isStatusUpdating = false;
      }
    }

  }

  showSuccessPopup() {
    $('#Plannification').modal('hide');
    $('#info').modal('show');
  }

  setSelectedStatus(status, incident: IncidentModel) {
    this.selectedIncident = incident;
    this.selectedStatus = status;
  }

  updateStatus() {
    this.isStatusUpdating = true;
    const params = { id_incident: this.selectedIncident.id, statut: this.selectedStatus};
    this._incidentService.updateIncidentStatus(params)
      .pipe(
        map((data: any) => {
          this.handleResponse(data._response);
        })
      )
      .subscribe();
  }

  toArray(object: any) {
    const result = Object.keys(object).map((key) => {
      return {date: key, tournee: object[key]};
    });
    return result;
  }

  getDateTimeFrom(date: string) {
    moment.locale('fr');
    const time = {start: moment("07:59", "HH:mm"), end: moment("12:00", "HH:mm")};
    let horaires = '14:00 à 18:00';
    if (moment(moment(date).format("HH:mm"), "HH:mm").isBetween(time.start, time.end, 'hours')) {
      horaires = '8:00 à 12:00';
    }
    const datetime = moment(date, 'YYYY-MM-DD HH:mm');
    return datetime.format("DD/MM/YYYY") + ' du ' + horaires;
  }
  //pour exclure la tournée courant lors de la fusion
  notCurrentTourne(code){
    if(code!==null){
      return this.currentTournee.code != code;
    }
    return true;
  }

  ngOnDestroy() {
    // this.resetForm()

  }

  updateClasse(id_incident) {
    this.isClasseUpdating = true;
    const params = { id_incident: id_incident, micro_incident: this.selectedClasse};
    this._incidentService.updateIncidentClasse(params)
      .pipe(
        map((data: any) => {
          let incident=this.incidentsList.find(elt => elt.id === this.selectedIncident.id);
          incident.micro_incident = this.selectedClasse;
          incident.statut = data.payload.statut;
          this.selectedIncident.micro_incident = this.selectedClasse;
          this.selectedIncident.statut = incident.statut;
        })
      )
      .subscribe();
  }

  updateTypeIncident(id_incident,type) {
    const params = { id_incident: id_incident, id_type_incident: type, action: this.action};
    this._incidentService.updateTypeIncident(params)
      .pipe(
        map((data: any) => {
          let index=this.incidentsList.indexOf(this.incidentsList.find(elt => elt.id === this.selectedIncident.id));
          this.incidentsList[index]=new IncidentModel(data.payload);;
        })
      )
      .subscribe();
  }

  deleteIncident(id_incident){
    const params = { id_incident: id_incident};
    this._incidentService.deleteIncident(params)
      .pipe(
        map((data: any) => {
          this.incidentsList=this.incidentsList.filter(elt => elt.id !== this.selectedIncident.id);
        })
      )
      .subscribe();
  }

  setSelectedIncident(incident){
    this.selectedIncident = incident;
    this.selectedTypeIncident = incident.type_incident.id_type_incident;
    this.selectedClasse = incident.micro_incident || "";
    this.action = (incident.action == "null" || incident.action == null) ? null : incident.action;
    console.log('hhh',this.getTypeTag(), incident, this.action)
    // if(this.getTypeTag() == "ACCESS"){
    //   this.action = 'RETARD';
    // }else if(this.getTypeTag() == "PANNE"){
    //   this.action = 'A_REPLANIFIER';
    // }else{
    //   this.action = null;
    // }
  }

  actionExists(incident){
    return incident.action === 'RETARD' || incident.action === 'ARRET_TEMPORAIRE' || incident.action === 'A_REPLANIFIER';
  }

  addIncident(event){
    if(event){
      this.getIncidentsListe(this.page);
    }
  }

  refreshIncidents(event){
    if(event){
      let incidents=this.incidentsList.filter(elt => elt.type_incident.id_type_incident == event.id_type_incident);
      incidents=incidents.map(function(incident){
        incident.type_incident.micro_incidents=event.micro_incidents;
        return incident;
      });
    }
  }

  getTime(date){
     return moment(date).format("HH:mm");
  }

  editComIncident(event){
    if(event.result){
      this.selectedIncident.commentaire=event.commentaire;
      this._toastr.success('Modification du commentaire effectué avec succés');
    }else{
      this._toastr.error(event.err);
    }
  }

  editDateIncident(event){
    if(event.result){
      this.selectedIncident.date_depot=event.date_depot;
      this._toastr.success('Modification de la date de dépot effectué avec succés');
    }else{
      this._toastr.error(event.err);
    }
  }
}
