// Modules
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {OWL_DATE_TIME_FORMATS, OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime';
import {DateTimeAdapter, OWL_DATE_TIME_LOCALE, OwlDateTimeIntl} from 'ng-pick-datetime';
import {NativeDateTimeAdapter} from 'ng-pick-datetime/date-time/adapter/native-date-time-adapter.class';
// Pipes
import {Platform, PlatformModule} from '@angular/cdk/platform';
import {
  TranslatePipe, ReversePipe, IfNullPipe, TruncatePipe, RemoveUnderscorePipe, ImplodeArrayPipe,FilterPipe
} from '@app/pipes';
import {CanAccessDirective,CannotAccessDirective, RouterlinkCustom, BlockCopyPasteDirective} from '@app/directives';
import {HttpClientModule} from '@angular/common/http';
import {API_KEY} from '@app/config';
import {ToastrModule} from 'ngx-toastr';
import {NgxPaginationModule} from 'ngx-pagination';
import {BreadcrumbComponent, IncidentsComponent, SpinnerComponent, TaostComponent} from '@app/components';
import {FrenchIntl} from '@app/helpers';
import {TextMaskModule} from 'angular2-text-mask';
import { AgmCoreModule } from '@agm/core';            // @agm/core
import { AgmDirectionModule } from 'agm-direction';   // agm-direction
export const MY_MOMENT_FORMATS = {
  parseInput: 'l LT',
  fullPickerInput: 'l LT',
  datePickerInput: 'l',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'DD/MM/YYYY HH:mm',
  timeOnly: 'HH:mm',
};
import {ImagesIncidentWorkflowComponent} from '../shared/modals/images-incident-workflow/images-incident-workflow.component';
import {GestionMicroIncidentsModalComponent} from '../shared/modals/gestion-micro-incidents-modal/gestion-micro-incidents-modal.component';
import {AddIncidentModalComponent} from '../shared/modals/add-incident-modal/add-incident-modal.component';
import {DuplicateDispoModalComponent} from '../shared/modals/duplicate-dispo-modal/duplicate-dispo-modal.component';
import {ComptageGravatsModalComponent} from '../shared/modals/comptage-gravats-modal/comptage-gravats-modal.component';
import {EditCommentModalComponent} from '../shared/modals/edit-comment-modal/edit-comment-modal.component';
import {EditDateDepotModalComponent} from '../shared/modals/edit-date-depot-modal/edit-date-depot-modal.component';
// import { RemoveUnderscorePipe } from 'app/pipes/remove-underscore.pipe';

@NgModule({
  declarations: [
    RemoveUnderscorePipe,
    TranslatePipe,
    ImplodeArrayPipe,
    FilterPipe,
    ReversePipe,
    IfNullPipe,
    TruncatePipe,
    RouterlinkCustom,
    CanAccessDirective,
    CannotAccessDirective,
    BlockCopyPasteDirective,
    // Components
    TaostComponent,
    BreadcrumbComponent,
    SpinnerComponent,
    IncidentsComponent,
    ImagesIncidentWorkflowComponent,
    GestionMicroIncidentsModalComponent,
    DuplicateDispoModalComponent,
    AddIncidentModalComponent,
    ComptageGravatsModalComponent,
    EditCommentModalComponent,
    EditDateDepotModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    OwlDateTimeModule,
    TextMaskModule,
    OwlNativeDateTimeModule,
    ReactiveFormsModule,
    PlatformModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: API_KEY,
      libraries: ['geometry', 'visualization', 'drawing', 'places']
    }),
    AgmDirectionModule,
    NgxPaginationModule
  ],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: DateTimeAdapter, useClass: NativeDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE, Platform]},
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'fr'},
    {provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
    {provide: OwlDateTimeIntl, useClass: FrenchIntl},
  ],
  exports: [
    // Components
    TaostComponent,
    BreadcrumbComponent,
    SpinnerComponent,
    IncidentsComponent,
    ImagesIncidentWorkflowComponent,
    GestionMicroIncidentsModalComponent,
    AddIncidentModalComponent,
    DuplicateDispoModalComponent,
    ComptageGravatsModalComponent,
    EditCommentModalComponent,
    EditDateDepotModalComponent,
    // Pipes
    RemoveUnderscorePipe,
    TranslatePipe,
    ReversePipe,
    IfNullPipe,
    FilterPipe,
    TruncatePipe,
    RouterlinkCustom,
    CanAccessDirective,
    CannotAccessDirective,
    BlockCopyPasteDirective,
    // Modules
    CommonModule,
    FormsModule,
    TextMaskModule,
    ReactiveFormsModule,
    HttpClientModule,
    PlatformModule,
    AgmCoreModule,
    AgmDirectionModule,
    ToastrModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxPaginationModule
  ]
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
