import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
declare var $: any;

@Injectable({
  providedIn: 'root'
})

export class ConfirmService {

  answer$ = new BehaviorSubject<any>({});
  message$ = new BehaviorSubject<any>({});
  constructor() { }

  showConfirm(action: string, message: string) {
    this.message$.next({message: message, action: action});
    this.answer$.next(null);
    $('#confirm').modal('show');
  }

  closeConfirm() {
    $('#confirm').modal('hide');
    this.answer$.next(null);
  }

  set answer(answer: any) {
    this.answer$.next(answer);
  }

  get answer(): any {
    return this.answer$.getValue();
  }
}
