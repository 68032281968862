import {MoyenModel} from './moyen.model';
import {PHONE_FRANCE} from "@app/config";

export class PrestataireModel {
  public id: number;
  public nom: string;
  public telephone: string;
  public email: string;
  public moyens: MoyenModel[];

  constructor(prestataire: any) {
    this.id = prestataire.id_prestataire;
    this.nom = prestataire.nom;
    this.telephone = prestataire.telephone;
    this.email = prestataire.email;
    this.moyens = MoyenModel.ToMoyenArray(prestataire.moyens);
  }

  // Retourne une liste des prestataires
  static ToPrestataireArray(data: PrestataireModel[] = []): PrestataireModel[] {
    const prestataires: PrestataireModel[] = [];
    data.forEach(item => {
      const prestataire: PrestataireModel = new PrestataireModel(item);
      prestataires.push(prestataire);
    });
    return prestataires;
  }

  getFirstCharacterInName(): string {
    return this.nom.toUpperCase().charAt(0);
  }

  getMoyensCount(moyens: any[] = this.moyens): number {
    let count = 0;
    moyens.forEach(moyen => {
      const qte = moyen.qte ? moyen.qte : 0;
      count = count + Number.parseInt(qte);
    });
    return count;
  }

  getMoyensVolumeTotalFor(isToxique: boolean, moyens: any[] = this.moyens,types: any[] =[]): number {
    const typologies=types.map((type)=>{
      return type.code;
    });
    const type = (isToxique) ? ['TOXIQUE'] : typologies.filter(t=>t!='TOXIQUE');
    let volume = 0;
    moyens.forEach(moyen => {
      if (type.indexOf(moyen.type)!=-1) {
        const qte = moyen.qte ? moyen.qte : 0;
        volume = volume + (Number.parseFloat(moyen.volume) * Number.parseInt(qte));
      }
    });
    return volume;
  }

  getMoyensPoidsTotal(): number {
    let poids = 0;
    this.moyens.forEach(moyen => {
      poids = poids + Number.parseFloat(moyen.poids_max);
    });
    return poids;
  }

  toJson() {
    return {
      id_prestataire: this.id,
      nom: this.nom,
      telephone: this.telephone,
      telephone_show: this.telephone.replace(PHONE_FRANCE, ''),
      email: this.email
    };
  }
}
