import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {MENU, ROUTES, USER_SlUG} from '@app/config';
import {LocalStorageHelper, NavigationHelper} from '@app/helpers';


@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {

  route: any;
  constructor(private _navigationHelper: NavigationHelper) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot ) {
    this.route = route;
    return this.isAuthenticated();
  }

  // Chech si 'utilisateur est connecté
  isAuthenticated() {
    const currentUser = LocalStorageHelper.getItem(USER_SlUG);
    if (currentUser && currentUser.role) {
      if (this.isUnauthorized(currentUser)) {
        this.redirectToAuthorizedRoute(currentUser);
        return false;
      }
      return true;
    }
    this._navigationHelper.navigateTo(ROUTES['login'].name);
    return false;
  }

  // Chech si la route demandé est non authorisée
  isUnauthorized(user: any) {
    const route = MENU.find(item => item.roles.indexOf(user.role) !== -1 && item.routeName === this.route.data.route);
    return route === undefined; // route === undefined;
  }

  // Redirege l'utilisateur vers sa page d'accueil
  redirectToAuthorizedRoute(user: any) {
    const routeName = MENU.find(item => item.roles.indexOf(user.role) !== -1).routeName;
    this._navigationHelper.navigateTo(routeName);
  }
}
