import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Config} from '@app/config';
import {ClientModel} from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private _http: HttpClient) {
  }

  getClients(page = 1, params = {}): Observable<any> {
    return this._http.post(Config.api.client.list + '?page=' + page, params);
  }

  updateClient(client: ClientModel): Observable<any> {
    return this._http.post(Config.api.client.update, client);
  }

  deleteClient(id_client: any): Observable<any> {
    return this._http.post(Config.api.client.delete, id_client);
  }

  consulterClient(id_client: any): Observable<any> {
    return this._http.post(Config.api.client.consulter, id_client);
  }
}
