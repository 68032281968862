export class JourFeries {

    private static _instance: JourFeries = null;
    public years = [];

    private constructor() {

    }

    public static getInstance() {
        if (!JourFeries._instance) {
            JourFeries._instance = new JourFeries();
        }
        return JourFeries._instance;
    }

    public format(date) {
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        const yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        return yyyy + '-' + mm + '-' + dd;
    }

    public estJourFerie(dateobject_or_yyyy_mm_dd) {
        let an, date_str = null;
        if (typeof dateobject_or_yyyy_mm_dd == 'object') {
            date_str = this.format(dateobject_or_yyyy_mm_dd);
            an = dateobject_or_yyyy_mm_dd.getFullYear();
        } else {
            date_str = dateobject_or_yyyy_mm_dd;
            an = dateobject_or_yyyy_mm_dd.split('-')[0];
        }
        let jfs = this.get(an);
        for (let key in jfs) {
            if (date_str == key) {
                return jfs[key];
            }
            // if (date_str < key) {
            //     return null;
            // }
        }
        return null;
    }

    public get(an) {
        if (this.years.hasOwnProperty(an)) {
            return this.years[an];
        }
        let JourAn = new Date(an, Number('00'), Number('01'));
        let FeteTravail = new Date(an, Number('04'), Number('01'));
        let Victoire1945 = new Date(an, Number('04'), Number('08'));
        let FeteNationale = new Date(an, Number('06'), Number('14'));
        let Assomption = new Date(an, Number('07'), Number('15'));
        let Toussaint = new Date(an, Number('10'), Number('01'));
        let Armistice = new Date(an, Number('10'), Number('11'));
        let Noel = new Date(an, Number('11'), Number('25'));
        let G = an % 19;
        let C = Math.floor(an / 100);
        let H = (C - Math.floor(C / 4) - Math.floor((8 * C + 13) / 25) + 19 * G + 15) % 30;
        let I = H - Math.floor(H / 28) * (1 - Math.floor(H / 28) * Math.floor(29 / (H + 1)) * Math.floor((21 - G) / 11));
        let J = (an * 1 + Math.floor(an / 4) + I + 2 - C + Math.floor(C / 4)) % 7;
        let L = I - J;
        let MoisPaques = 3 + Math.floor((L + 40) / 44);
        let JourPaques = L + 28 - 31 * Math.floor(MoisPaques / 4);
        let Paques = new Date(an, MoisPaques - 1, JourPaques);
        let LundiPaques = new Date(an, MoisPaques - 1, JourPaques + 1);
        let Ascension = new Date(an, MoisPaques - 1, JourPaques + 39);
        let Pentecote = new Date(an, MoisPaques - 1, JourPaques + 50);
        let jf = [];
        jf[this.format(JourAn)] = {date: JourAn, nom: 'Jour de l &#39;An'};
        jf[this.format(FeteTravail)] = {date: FeteTravail, nom: 'Fête du travail'};
        jf[this.format(Victoire1945)] = {date: Victoire1945, nom: 'Victoire 1945'};
        jf[this.format(FeteNationale)] = {date: FeteNationale, nom: 'Fête nationale'};
        jf[this.format(Assomption)] = {date: Assomption, nom: 'Assomption'};
        jf[this.format(Toussaint)] = {date: Toussaint, nom: 'Toussaint'};
        jf[this.format(Armistice)] = {date: Armistice, nom: 'Armistice 1918'};
        jf[this.format(Noel)] = {date: Noel, nom: 'Noël'};
        jf[this.format(LundiPaques)] = {date: LundiPaques, nom: 'Pâques'};
        jf[this.format(Ascension)] = {date: Ascension, nom: 'Ascension'};
        jf[this.format(Pentecote)] = {date: Pentecote, nom: 'Pentecôte'};
        this.years[an] = jf;
        return jf;
    }
}
