import { Component,Input } from '@angular/core';
import {DEFAULT_POSITION,PHOTO_MAP_MARKER,RDV_MAP_MARKER} from '@app/config';
import {Image} from '@app/models';
 
@Component({
  selector: 'app-images-incident-workflow',
  templateUrl: './images-incident-workflow.component.html',
  styleUrls: ['./images-incident-workflow.component.css']
})
export class ImagesIncidentWorkflowComponent {
  icon_photo = PHOTO_MAP_MARKER;
  icon_rdv = RDV_MAP_MARKER;
  markersMap: any[] = [];
  _currentTab : string = 'photo';
  // google maps zoom level
  zoom: number = 8;
  defaultPosition = DEFAULT_POSITION;
  @Input() selectedImages: Image[];
  @Input() typeImage;
  @Input() lon;
  @Input() lat;

  ngOnInit() {
    const CURRENT_POSITION = {
      lat: this.lat,
      lng: this.lon
    }
    this.defaultPosition = this.lat && this.lon?CURRENT_POSITION:DEFAULT_POSITION;
    
  }

  changeTab(data){
    this._currentTab = data;
    console.log(this.lat);
  }

  parseNumber(variable, fixed = false) {
    return fixed ? Number(parseFloat(variable)) : Number(parseFloat(variable).toFixed(2));
  }

  stringToNumber(variable){
    return Number(variable);
  }
}