import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Config} from '@app/config';
import {GardienModel} from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class GardienService {

  constructor(private _http: HttpClient) {
  }

  getGardiensOf(id_bailleur: number): Observable<any> {
    return this._http.get(Config.api.gardien.all + '?id_bailleur=' + id_bailleur);
  }

  getGardiens(page = 1, params = {}): Observable<any> {
    return this._http.get(Config.api.gardien.all + '?page=' + page, { params: params });
  }

  accepterDemande(gardien: GardienModel): Observable<any> {
    return this._http.post(Config.api.gardien.valider + '/' + gardien.id,{actif:1});
  }

  refuserDemande(gardien: GardienModel): Observable<any> {
    return this._http.post(Config.api.gardien.valider + '/' + gardien.id,{actif:0});
  }

  activerCompte(gardien: GardienModel): Observable<any> {
    return this._http.get(Config.api.gardien.activer + '/' + gardien.id);
  }

  desactiverCompte(gardien: GardienModel): Observable<any> {
    return this._http.get(Config.api.gardien.desactiver + '/' + gardien.id);
  }

  consulterGardien(id_client: any): Observable<any> {
    return this._http.post(Config.api.gardien.consulter, id_client);
  }

  exporterGardiens(params = {}): Observable<any> {
    return this._http.get(Config.api.gardien.export, { params: params , responseType: 'blob' });
  }
}
