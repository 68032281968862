import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService, UserService} from '@app/services';
import {closeAllModals, Required} from '@app/helpers';
import {EMAIL_REGEX, PHONE_MASK, RESPONSE} from '@app/config';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {map} from 'rxjs/internal/operators';
import {UserModel} from '@app/models';

declare var $: any;

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {

  userForm: FormGroup;
  title = '';
  breadcrumb = '';
  setPass = false;
  isLoading = true;
  exist = {
    email: false,
    phone: false,
    phone_invalid: false,
    password_incorrect: false
  };
  page: number;
  subscriptions = new Subscription();
  PHONE_MASK = PHONE_MASK;
  isRequesting = false;
  isOldPasswordWrong = false;
  passwordRequired = false;
  mismatchPassword = false;
  isTheSamePassword = false;
  currentUser: UserModel;

  constructor(
    private _userService: UserService,
    private _authService: AuthService,
  ) {
  }

  ngOnInit() {
    this.setUserForm();
    $('#editProfileTarget').click( () => {
      this.getUser();
    });
  }

  setUserForm() {
    this.exist = {
      email: false,
      phone: false,
      phone_invalid: false,
      password_incorrect: false
    };
    this.userForm = new FormGroup({
      id_utilisateur: new FormControl(''),
      nom: new FormControl('', [Required.validator]),
      prenom: new FormControl('', [Required.validator]),
      email: new FormControl('', [Required.validator, Validators.pattern(EMAIL_REGEX)]),
      telephone: new FormControl('', [Required.validator, Validators.minLength(10)]),
      old_password: new FormControl(''),
      password: new FormControl(''),
      re_password: new FormControl(''),
    });
  }

  saveUser() {
    this.isRequesting = true;
    const params = this.userForm.value;
    const request = this._userService.updateCurrentUser(params)
      .pipe(
        map((data: any) => {
          this.handleResponse(data);
        })
      )
      .subscribe(
        () => {
          window.location.reload();
        },
        (error) => {
          this.isRequesting = false;
          this.handleError(error);
        }
      );
    this.subscriptions.add(request);
  }

  handleError(error) {
    this.exist = {
      email: false,
      phone: false,
      phone_invalid: false,
      password_incorrect: false
    };
    if (error._response && error._response.code === RESPONSE.ACCOUNT.ERROR.EMAIL_EXIST) {
      this.exist.email = true;
    } else if (error._response && error._response.code === RESPONSE.ACCOUNT.ERROR.MOBILE_INVALID) {
      this.exist.phone_invalid = true;
    } else if (error._response && error._response.code === RESPONSE.ACCOUNT.ERROR.PASSWORD_INCORRECT) {
      this.exist.password_incorrect = true;
    }
  }

  handleResponse(data) {
    if (RESPONSE.ACCOUNT.OK.UPDATE === data._response.code) {
      this.currentUser = new UserModel(data.payload);
      this.setUser();
      closeAllModals();
    }
  }

  isFormInvalid() {
    this.passwordRequired = ((this.userForm.get('old_password').value || this.userForm.get('password').value || this.userForm.get('re_password').value)
      && (!this.userForm.get('old_password').value || !this.userForm.get('password').value || !this.userForm.get('re_password').value));
    this.mismatchPassword = (!this.passwordRequired && (this.userForm.get('password').value !== this.userForm.get('re_password').value));
    this.isTheSamePassword = (!this.passwordRequired && !this.mismatchPassword &&
      (this.userForm.get('password').value && this.userForm.get('old_password').value && this.userForm.get('password').value === this.userForm.get('old_password').value));
    return this.userForm.invalid || !this.userForm.dirty || this.passwordRequired || this.mismatchPassword || this.isTheSamePassword;
  }

  resetForm() {
    this.userForm.reset();
  }

  setUser() {
    this._authService.setUser(this.currentUser);
  }

  getUser() {
    this.isRequesting = false;
    this.isLoading = true;
    this._authService.getAccount().subscribe(
      (response) => {
        this.currentUser = new UserModel(response.payload);
        this.userForm.patchValue(this.currentUser.toJson());
      },
      () => {},
      () => {
        this.isLoading = false;
      }
    );
  }

  ngOnDestroy() {}
}
