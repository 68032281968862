export const RESPONSE = {
    GLOBAL: {
        UNAUTHORIZED: '403_00'
    },
    USER: {
        OK: {
            ALL: 'UTI200_00',
            GET: 'UTI200_01',
            CREATE: 'UTI200_02',
            UPDATE: 'UTI200_03',
            DELETE: 'UTI200_04',
            EMAIL_SEND: 'EML200_00',
            PASSWORD_RESET: 'EML200_01'
        },
        ERROR: {
            ALL: '',
            GET: 'UTI404_00',
            CREATE: '',
            UPDATE: 'UTI404_00',
            DELETE: 'UTI404_00',
            EMAIL_EXIST: 'UTI406_00',
            PHONE_EXIST: 'UTI406_01',
            PHONE_MOBILE_INVALID: 'MOB406_00',
            EMAIL_NOT_FOUND: 'EML404_00',
            TOKEN_EMAIL_ERROR: 'EML404_01'
        }

    },
    ACCOUNT: {
        OK: {
            UPDATE: 'UTI200_03'
        },
        ERROR: {
            EMAIL_EXIST: 'UTI406_00',
            MOBILE_INVALID: 'MOB406_00',
            PASSWORD_INCORRECT: 'UTI_400_01'
        },
    },
    MOYEN: {
        OK: {
            ALL: 'MOY200_00',
            GET: 'MOY200_01',
            CREATE: 'MOY200_02',
            UPDATE: 'MOY200_03',
            DELETE: 'MOY200_04',
            DISPONIBILITE: 'MOY200_03_01'
        },
        ERROR: {
            ALL: '',
            GET: 'MOY404_00',
            CREATE: '',
            UPDATE: 'MOY404_00',
            DELETE: 'MOY404_00',
            NOT_AUTHORIZED: 'MOY403_00'
        }
    },
    CLIENT: {
      OK: {
        ALL: 'CLI200_00',
        GET: 'CLI200_01',
        UPDATE: 'CLI200_03',
        DELETE: 'CLI200_04',
      },
      ERROR: {
        ALL: '',
        GET: 'CLI404_00',
        UPDATE: 'CLI404_00',
        DELETE: 'CLI404_00',
        CANNOT_DELETE: 'CLI403_00',
        RDV_NOT_FOUND : 'CLI404_01',
        PHONE_MOBILE_INVALID : 'MOB406_00'
      }
    },
    TERMINAL: {
        OK: {
            ALL: 'TER200_00',
            GET: 'TER200_01',
            CREATE: 'TER200_02',
            UPDATE: 'TER200_03',
            DELETE: 'TER200_04',
        },
        ERROR: {
            ALL: '',
            GET: 'TER404_00',
            CREATE: '',
            UPDATE: 'TER404_00',
            DELETE: 'TER404_00',
            EXIST: 'TER406_00'
        }
    },
    PRESTATAIRE: {
        OK: {
            ALL: 'PRS200_00',
            GET: 'PRS200_01',
            CREATE: 'PRS200_02',
            UPDATE: 'PRS200_03',
            DELETE: 'PRS200_04',
            ATTACH: 'PRS200_05',
            DETTACH: 'PRS200_10',
            LIST: 'PRS200_06',
        },
        ERROR: {
            ALL: '',
            GET: 'PRS404_00',
            CREATE: '',
            UPDATE: 'PRS404_00',
            DELETE: 'PRS404_00',
            ATTACH: 'PRS404_00',
            DETTACH: 'PRS200_09',
            LIST: 'PRS404_00',
            EMAIL_EXIST: 'UTI406_00',
            PHONE_EXIST: 'UTI406_01',
            PHONE_MOBILE_INVALID : 'MOB406_00'
        }
    },
    INCIDENT: {
        OK: {
            ALL: 'INC200_00',
            GET: 'INC200_01',
            CREATE: 'INC200_02',
            UPDATE: 'INC200_03',
            DELETE: 'INC200_04',
            IMAGE: 'INC200_05',
            RESOLVE: 'RAM200_06',
            SUCCESS: 'RAM200_10',
            REPLANIFICATION_ANNULER: 'REP200_00',
        },
        ERROR: {
            ALL: '',
            GET: 'INC404_00',
            CREATE: '',
            UPDATE: 'INC404_00',
            DELETE: 'INC404_00',
            RESOLVE: {
                RAMASSAGE: 'RAM404_00',
                TOURNEE: 'TOR404_00',
                INCIDENT: 'INC404_00',
                CANNOT_REPLANNED: 'RAM403_01'
            },
            REPLANIFICATION_ANNULER: 'REPRROR500_00',
        }
    },
    ENCOMBRANT: {
        OK: {
            ALL: 'ENC200_00',
            GET: 'ENC200_01',
            CREATE: 'ENC200_02',
            UPDATE: 'ENC200_03',
            DELETE: 'ENC200_04'
        },
        ERROR: {
            ALL: '',
            GET: 'ENC404_00',
            CREATE: '',
            UPDATE: 'ENC404_00',
            DELETE: 'ENC404_00',
            CANNOT_DELETE: 'ENC403_00',
        }
    },
    RDV: {
        OK: {
            AUTH: 'A200_00',
            SAVE: 'RDV200_02',
            UPDATE: 'RDV200_03',
            DELETE: 'RDV200_04',
            DELETE_MISSION: 'RDV200_04x1'
        },
        ERROR: {
            DISPONIBILITE_NOT_FOUND: 'DIS200_01',
            NOT_FOUND: 'RDV404_00',
            CANNOT_UPDATE: 'RDV403_00_01',
            CANNOT_DELETE: 'RDV403_00_02',
            AUTH: 'AT401_01',
            DELETE_RDV_FORBIDEN: 'RDV403_00',
            RDV_CANCELED: 'RDV403_02',
            RDV_DONE_CANNOT_CANCELE: 'RDV403_02x2',
            RDV_CANNOT_CANCELE: 'RDV403_02x3',
            RDV_TOURNEE_DONE_CANNOT_CANCELE: 'RDV403_02x4',
            RDV_TOURNEE_PROCESSING_CANNOT_CANCELE: 'RDV403_02x5',
        }
    },
    DEPOT: {
        OK: {
            ALL: 'DEP200_00',
            GET: 'DEP200_01',
            CREATE: 'DEP200_02',
            UPDATE: 'DEP200_03',
            DELETE: 'DEP200_04',
            ATTACH: 'DEP200_06',
            DETACH: 'DEP200_07',
            CANNOT_ATTACH: 'DEP200_01x2',
            ALREADY_ATTACHED: 'DEP200_06x1',
            TYPE_UPDATED: 'DEP200_01x1'
        },
        ERROR: {
            DISPONIBILITE_NOT_FOUND: 'DEP200_01',
        }
    },
    DISPONIBILITE: {
        ERROR: {
            MAX: 'DISMAX403_01',
            NONE: 'DIS403_00',
            RDV: 'DIS404_00',
            ADRESSE: 'A404_00'
        }
    },
    TEMPLATE: {
        OK: {
            UPDATE: 'TM200_02'
        }
    },
    TOURNEE: {
        ERROR: {
            GET: 'TOR404_00'
        }
    },
    MISSION: {
        OK: {
            EXIST: 'PT200_00',
            DISPONIBILITE_NOT_FOUND: 'PT200_01',
            SUCCES: 'PT200_02',
            DONE: 'PT200_03'
        },
        ERROR: {
            GET: 'MIS404_00'
        }
    },
};
