import {Component, OnInit} from '@angular/core';
import {MENU, ROUTES, USER_SlUG} from '@app/config';
import {LocalStorageHelper, NavigationHelper} from '@app/helpers';
import {Router} from '@angular/router';
import {UserModel} from '@app/models';
import {AuthService} from '@app/services';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  nodes: any[] = [];
  user: UserModel;
  currentRoute: string;

  constructor(private _navigationHelper: NavigationHelper,
              private _authService: AuthService,
              private _router: Router) {
  }

  ngOnInit() {
    this.getMenuItems();
    this.setCurrentRouteName();
    this._router.events.subscribe(() => {
      this.setCurrentRouteName();
    });
    this.getUser();
  }

  getUser() {
    this.user = this._authService.getUser();
  }

  setCurrentRouteName() {
    const routeLink = this._router.url;
    for (let KEY in ROUTES) {
      const regex = new RegExp('\^' + ROUTES[KEY]['route'] + '[\\/]?([[0-9a-zA-Z\-]*]*$)');
      if (regex.test(routeLink)) {
        this.currentRoute = ROUTES[KEY]['name'];
      }
    }
  }

  hasCurrentItem(currentItem: any) {
    const route = currentItem.children.find((item) => item.routeName === this.currentRoute);
    return route && route.routeName === this.currentRoute;
  }

  menuHasRole(roles: any[]) {
    const user = LocalStorageHelper.getItem(USER_SlUG);
    return user && roles.indexOf(user.role) !== -1;
  }

  // Recupère les menus a partir de la liste MENU
  getMenuItems() {
    this.nodes = [];
    let i = 0;
    for (var element in MENU) {
      if (MENU[i]['isMenu']) {
        const menu = {...MENU[i]};
        menu['children'] = MENU.filter((item) => item.parentMenu === MENU[i].parentMenu);
        this.nodes.push(menu);
      }
      i++;
    }
  }
}
