import { Component,Input,Output,EventEmitter } from '@angular/core';
import {MicroIncidentService} from '@app/services';
import {map} from 'rxjs/internal/operators';
import { ToastrService } from 'ngx-toastr';
 
@Component({
  selector: 'app-gestion-micro-incidents-modal',
  templateUrl: './gestion-micro-incidents-modal.component.html',
  styleUrls: ['./gestion-micro-incidents-modal.component.css']
})
export class GestionMicroIncidentsModalComponent {
  @Input() typesIncidentsList=[];
  selectedTypeIncidentId:number=0;
  selectedTypeIncident:any;
  selectedMicroIncidentId:number=0;
  selectedMicroIncident:any;
  selectedLabelMicroIncident:string;
  microIncidentsList=[];
  enableEdit=false;
  enableAdd=false;
  addedMicroIncident:string;
  @Output() eventIncidents = new EventEmitter();

  constructor(
    private microIncidentService: MicroIncidentService,
    private _toastr: ToastrService
  ) {
  }

  parseNumber(variable, fixed = false) {
    return fixed ? Number(parseFloat(variable)) : Number(parseFloat(variable).toFixed(2));
  }

  getMicroIncidents(){
    let typeIncident=this.typesIncidentsList.find(type=>type.id_type_incident==this.selectedTypeIncidentId);
    this.microIncidentsList=typeIncident.micro_incidents;
    this.selectedMicroIncidentId=0;
    this.enableEdit=false;
    this.enableAdd=false;
  }

  enableAddMicro(){
    this.enableEdit=false;
    this.enableAdd=true;
    this.selectedLabelMicroIncident=null;
    this.addedMicroIncident=null;
  }

  enableEditMicro(){
    this.enableEdit=true;
    this.enableAdd=false;
    this.addedMicroIncident=null;
    let typeIncident=this.typesIncidentsList.find(type=>type.id_type_incident==this.selectedTypeIncidentId);
    let microIncident=typeIncident.micro_incidents.find(micro=>micro.id_micro_incident==this.selectedMicroIncidentId);
    this.selectedLabelMicroIncident=microIncident.label;
  }

  cancelMicro(){
    this.enableEdit=false;
    this.enableAdd=false;
  }

  addOrUpdateMicro() {
      if(this.enableAdd){
          this.microIncidentService.createMicroIncident({'id_type_incident':this.selectedTypeIncidentId,'label':this.addedMicroIncident})
            .subscribe(data => {
                if(this.selectedTypeIncidentId){
                    let typeIncident=this.typesIncidentsList.find(type=>type.id_type_incident==this.selectedTypeIncidentId);
                    typeIncident.micro_incidents.push(data.payload);
                    this.eventIncidents.emit(typeIncident);
                }else{
                    this.eventIncidents.emit(false);
                }
                this.enableAdd=false;
                this._toastr.success("Le micro incident est ajouté avec succés");
            },err => {
                this.eventIncidents.emit(false);
                this._toastr.error("Erreur d'ajout du micro incident");
            });
      }else if(this.enableEdit){
        this.microIncidentService.updateMicroIncident({'id_micro_incident':this.selectedMicroIncidentId,'label':this.selectedLabelMicroIncident})
            .subscribe(data => {
            if(this.selectedTypeIncidentId){
                let typeIncident=this.typesIncidentsList.find(type=>type.id_type_incident==this.selectedTypeIncidentId);
                let microIncident=typeIncident.micro_incidents.find(micro=>micro.id_micro_incident==this.selectedMicroIncidentId);
                microIncident.label=this.selectedLabelMicroIncident;
                this.eventIncidents.emit(typeIncident);
            }else{
              this.eventIncidents.emit(false);
            }
            this.enableEdit=false;
            this._toastr.success("Le micro incident est modifié avec succés");
          },err => {
            this.eventIncidents.emit(false);
            this._toastr.error("Erreur de modification du micro incident");
        });
      }
  }

  deleteMicro() {
    if(this.selectedMicroIncidentId && this.selectedMicroIncidentId!=0){
        this.microIncidentService.deleteMicroIncident({'id_micro_incident':this.selectedMicroIncidentId})
        .subscribe(data => {
            if(this.selectedTypeIncidentId){
              let typeIncident=this.typesIncidentsList.find(type=>type.id_type_incident==this.selectedTypeIncidentId);
              let microIncident=typeIncident.micro_incidents.find(micro=>micro.id_micro_incident==this.selectedMicroIncidentId);
              typeIncident.micro_incidents.splice(typeIncident.micro_incidents.indexOf(microIncident),typeIncident.micro_incidents.indexOf(microIncident)+1);
              this.selectedMicroIncidentId=0;
              this.eventIncidents.emit(typeIncident);
            }else{
              this.eventIncidents.emit(false);
            }
            this._toastr.success("Le micro incident est supprimé avec succés");
          },err => {
            this.eventIncidents.emit(false);
            this._toastr.error("Erreur de suppression du micro incident");
        });
    }
  }

  fillMicroIncident(){
    let typeIncident=this.typesIncidentsList.find(type=>type.id_type_incident==this.selectedTypeIncidentId);
    this.selectedMicroIncident=typeIncident.micro_incidents.find(micro=>micro.id_micro_incident==this.selectedMicroIncidentId);
  }
}