import {PHONE_FRANCE, USER_ROLES} from '@app/config';

export class UserModel {
  public id: number;
  public nom: string;
  public prenom: string;
  public prestataire: number;
  public intitule: string;
  public email: string;
  public telephone: string;
  public bloquer: string;
  public role: string;
  public password: string;
  public token_aquity: string;
  public nom_commune: string;

  constructor(user: any) {
    if (user) {
      this.id = user.id_utilisateur;
      this.nom = user.nom;
      this.prenom = user.prenom;
      this.prestataire = user.id_prestataire;
      this.intitule = user.intitule;
      this.email = user.email;
      this.telephone = user.telephone;
      this.bloquer = user.bloquer;
      this.role = user.role;
      this.password = '';
      this.token_aquity = user.token_aquity;
      this.nom_commune = user.nom_commune;
    }
  }

  // Retourne une liste des utilisateurs
  static ToUserArray(data: UserModel[] = []): UserModel[] {
    const users: UserModel[] = [];
    data.forEach(currUser => {
      const user: UserModel = new UserModel(currUser);
      users.push(user);
    });
    return users;
  }

  getFullName(): string {
    return this.prenom.charAt(0).toUpperCase() + this.prenom.slice(1) + ' ' + this.nom.toUpperCase();
  }

  getFirstCharacterInName(): string {
    return this.prenom.toUpperCase().charAt(0);
  }

  getRole() {
    return USER_ROLES.find(role => role.key === this.role).value;
  }

  toJson() {
    return {
      id_utilisateur: this.id,
      nom: this.nom,
      prenom: this.prenom,
      id_prestataire: this.prestataire,
      nom_commune: this.nom_commune,
      intitule: this.intitule,
      email: this.email,
      telephone: this.telephone.replace(PHONE_FRANCE, '0'),
      bloquer: this.bloquer,
      telephone_show: this.telephone.replace(PHONE_FRANCE, '0'),
      password: '',
      re_password: '',
      role: this.role
    };
  }
}
