import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '@app/config';
import {Observable} from 'rxjs';
import {DepotModel} from '@app/models';

@Injectable({
  providedIn: 'root',
})

export class DepotService {

  constructor(private _http: HttpClient) {
  }

  getDepots(page = 1): Observable<any> {
    return this._http.get(Config.api.depot.all + '&page=' + page);
  }

  createDepot(depot: DepotModel): Observable<any> {
    return this._http.post(Config.api.depot.create, depot);
  }

  updateDepot(depot: DepotModel): Observable<any> {
    return this._http.post(Config.api.depot.update, depot);
  }

  deleteDepot(id_depot: any): Observable<any> {
    return this._http.post(Config.api.depot.delete, id_depot);
  }

  attachDepot(params: any): Observable<any> {
    return this._http.post(Config.api.depot.attach, params);
  }

  detachDepot(params: any): Observable<any> {
    return this._http.post(Config.api.depot.detach, params);
  }

}
