import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '@app/config';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})

export class StatistiqueService {

  constructor(private _http: HttpClient) {
  }

  // récupérer le fichier csv des rdvs
  getRdvsCsv(params: any): Observable<any> {
    return this._http.post(Config.api.statistique.getRdvsCsv, params, { responseType: 'blob'});
  }

  // récupérer le fichier pdf de la mission ou tournée
  getMissionPdf(params: any): Observable<any> {
    return this._http.post(Config.api.statistique.getMissionPdf, params, { responseType: 'blob'});
  }

  // récupérer le fichier csv de la synthese des tournées
  getSyntheseTourneesCsv(params: any): Observable<any> {
    return this._http.post(Config.api.statistique.getSyntheseTourneesCsv, params, { responseType: 'blob'});
  }

  // récupérer le fichier csv du détail des tournées
  getDetailTourneesCsv(params: any): Observable<any> {
    return this._http.post(Config.api.statistique.getDetailTourneesCsv, params, { responseType: 'blob'});
  }

  // récupérer le fichier csv des incidents des tournées
  getIncidentsTourneesCsv(params: any): Observable<any> {
    return this._http.post(Config.api.statistique.getIncidentsTourneesCsv, params, { responseType: 'blob'});
  }

  getBilan(params: any): Observable<any> {
    return this._http.post(Config.api.statistique.getBilan, params, { responseType: 'blob'});
  }

}
