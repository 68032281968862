import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '@app/config';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class MissionService {

  constructor(private http: HttpClient) {
  }

  getMissions(page: number = 1, filter: any = {}): Observable<any> {
    return this.http.post(Config.api.mission.list + '&page=' + page, filter);
  }

  getMission(params: any): Observable<any> {
    return this.http.post(Config.api.mission.get, params);
  }

  getTournee(params: any): Observable<any> {
    return this.http.post(Config.api.tournee.get, params);
  }

  runCron(id_mission): Observable<any> {
    return this.http.get(Config.api.mission.cron  + id_mission);
  }

  getLastPosition(tournee) {
     // return this.http.get(Config.api.mission.cron  + id_mission);
  }

  workflowShowBy(params: any): Observable<any> {
    return this.http.post(Config.api.workflow.show_by, params);
  }

  getTourneeDechets(params: any): Observable<any> {
    return this.http.post(Config.api.tournee.dechetsACompter, params);
  }

  setComptage(params: any): Observable<any> {
    return this.http.post(Config.api.tournee.setComptage, params);
  }
}
