import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'implodeArray'
})
export class ImplodeArrayPipe implements PipeTransform {

  transform(value: any): any {
    return [...new Set(value)];
  }

}
