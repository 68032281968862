import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '@app/config';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class MicroIncidentService {

  constructor(private _http: HttpClient) {
  }

  createMicroIncident(params: any): Observable<any> {
    return this._http.post(Config.api.micro_incident.create, params);
  }

  updateMicroIncident(params: any): Observable<any> {
    return this._http.post(Config.api.micro_incident.update, params);
  }

  deleteMicroIncident(params: any): Observable<any> {
    return this._http.post(Config.api.micro_incident.delete, params);
  }

}