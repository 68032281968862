export function getTypologieIcon(typologie,color = ''){
  switch (typologie) {
    case 'NON_TOXIQUE':
      return 'icon-non-toxic';
      break;
    case 'TOXIQUE':
      return 'icon-toxic';
      break;
    case 'BENNE':
      return 'icon-benne';
      break;
    case 'PLATEAU':
      return 'icon-plateau';
      break;
    case 'TEXTILE':
      return 'icon-textile'+(color ? '-'+color : '');
      break;
    default:
      break;
  }
}
