import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserModel} from '@app/models';
import {ConfirmService, PrestataireService, UserService} from '@app/services';
import {finalize, map} from 'rxjs/internal/operators';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {RESPONSE, USER_ROLES, PHONE_MASK, EMAIL_REGEX} from '@app/config';
import {Subscription} from 'rxjs';
import {closeAllModals, Required} from '@app/helpers';
import {Unsubscribe} from '@app/decorators';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})

@Unsubscribe()
export class UserComponent implements OnInit, OnDestroy {

  usersList: UserModel[];
  selectedUser: UserModel;
  isLoading = true;
  userForm: FormGroup;
  userPagination: any;
  title = '';
  breadcrumb = '';
  exist = {
    email: false,
    phone: false,
    phone_invalid: false
  };
  page: number;
  userRoles = USER_ROLES;
  prestatairesList: any = [];
  communesList: any = [];
  subscription = new Subscription();
  PHONE_MASK = PHONE_MASK;
  isRequesting = false;
  confirmation_password = null;
  
  constructor(
    private _userService: UserService,
    private _prestataireService: PrestataireService,
    private _confirmService: ConfirmService
  ) {
  }

  ngOnInit() {
    this.triggerConfirmationEvent();
    this.setUserForm();
    this.getUsersListe();
    this.getPrestataires();
    this.getCommunes();
  }

  triggerConfirmationEvent() {
    const request =  this._confirmService.answer$.subscribe((response) => {
      if (response && response.action === 'user.delete' && response.value) {
        this.deleteUser(this.selectedUser.id);
        this._confirmService.answer = null;
      }
    });
    this.subscription.add(request);
  }

  setUserForm() {
    this.exist = {
      email: false,
      phone: false,
      phone_invalid: false
    };
    if (this.selectedUser === undefined) {
      this.userForm = new FormGroup({
        id_utilisateur: new FormControl(''),
        nom: new FormControl('', [Required.validator]),
        prenom: new FormControl('', [Required.validator]),
        intitule: new FormControl('', [Required.validator]),
        password: new FormControl(''),
        re_password: new FormControl(''),
        email: new FormControl('', [Required.validator, Validators.pattern(EMAIL_REGEX)]),
        role: new FormControl('', [Required.validator]),
        id_prestataire: new FormControl(''),
        nom_commune: new FormControl(''),
        bloquer: new FormControl('0'),
        telephone: new FormControl('', [Required.validator, Validators.minLength(10)]),
      });
    } else if (this.selectedUser === null) {
      this.userForm.get('password').setValidators(Required.validator);
      this.userForm.get('re_password').setValidators(Required.validator);
      this.userForm.updateValueAndValidity();
      const emptyUser = {
        id_utilisateur: '',
        nom: '',
        prenom: '',
        id_prestataire: '',
        nom_commune: '',
        intitule: '',
        email: '',
        telephone: '',
        bloquer: 0,
        password: '',
        re_password: '',
        role: ''
      };
      this.userForm.setValue(emptyUser);
    } else {
      this.userForm.get('password').clearValidators();
      this.userForm.get('re_password').clearValidators();
      this.userForm.updateValueAndValidity();
      this.userForm.patchValue(this.selectedUser.toJson());
    }
  }

  getUsersListe(page = 1) {
    this.page = page;
    this.isLoading = true;
    const request =  this._userService.getUsers(this.page)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        response => {
          this.usersList = UserModel.ToUserArray(response.payload.data);
          this.userPagination = {
            id: 'old',
            itemsPerPage: response.payload.per_page,
            currentPage: response.payload.current_page,
            totalItems: response.payload.total
          };
        }
      );
    this.subscription.add(request);
  }

  setSelectedUser(user: any = null) {
    this.isRequesting = false;
    this.selectedUser = user ? user as UserModel : null;
    this.setUserForm();
  }

  setPrestataireValidator() {
    if (this.userForm.get('role').value === 'PRESTATAIRE') {
      this.userForm.get('id_prestataire').setValidators(Required.validator);
      this.userForm.updateValueAndValidity();
    } else {
      this.userForm.get('id_prestataire').clearValidators();
      this.userForm.updateValueAndValidity();
    }

    if (this.userForm.get('role').value === 'POLICE') {
      this.userForm.get('nom_commune').setValidators(Required.validator);
      this.userForm.updateValueAndValidity();
    } else {
      this.userForm.get('nom_commune').clearValidators();
      this.userForm.updateValueAndValidity();
    }
  }

  getPrestataires() {
    const request =  this._prestataireService.getPrestatairesForSelect()
      .subscribe(data => this.prestatairesList = data.payload);
    this.subscription.add(request);
  }

  getCommunes() {
    const request =  this._userService.getCommunes()
      .subscribe(data => this.communesList = data.payload);
    this.subscription.add(request);
  }

  saveUser() {
    this.isRequesting = true;
    const params = this.userForm.value;
    let request;
    delete params['re_password'];
    if (this.selectedUser) {
      if (!this.userForm.get('password').value) {
        delete params['password'];
      }
      request = this._userService.updateUser(params);
    } else {
      delete params['id_utilisateur'];
      request = this._userService.createUser(params);
    }

    const ask =  request
      .subscribe(
        (data) => {
          this.handleResponse(data._response);
        },
        (error) => {
          this.isRequesting = false;
          this.handleError(error);
        }
      );
    this.subscription.add(ask);
  }

  deleteUser(id) {
    const params = {id: id};
    const request =  this._userService.deleteUser(params)
      .pipe(
        map((data) => {
          this.handleResponse(data._response);
        })
      )
      .subscribe();
    this.subscription.add(request);
  }

  handleError(error) {
    this.exist = {
      email: false,
      phone: false,
      phone_invalid: false
    };
    if (error._response.code === RESPONSE.USER.ERROR.EMAIL_EXIST) {
      this.exist.email = true;
    } else if (error._response.code === RESPONSE.USER.ERROR.PHONE_EXIST) {
      this.exist.phone = true;
    } else if (error._response.code === RESPONSE.USER.ERROR.PHONE_MOBILE_INVALID) {
      this.exist.phone_invalid = true;
    }
  }

  handleResponse(response) {
    if ([RESPONSE.USER.OK.CREATE, RESPONSE.USER.OK.UPDATE, RESPONSE.USER.OK.DELETE].indexOf(response.code) !== -1) {
      if (RESPONSE.USER.OK.DELETE === response.code) {
        this.checkPageIfEmpty();
      }
      this.getUsersListe(this.page);
      closeAllModals();
      this.resetForm();
    }
  }

  showConfirm(user: UserModel) {
    this.setSelectedUser(user);
    this._confirmService.showConfirm('user.delete', 'Êtes vous sûr de vouloir supprimer cet élement ?');
  }

  isFormInvalid() {
    return this.userForm.invalid || !this.userForm.dirty || (this.userForm.get('role').value === 'PRESTATAIRE'
      && !this.userForm.get('id_prestataire').value)
      || (this.userForm.get('password').value !== this.userForm.get('re_password').value)
      || !(this.userForm.get('telephone').value &&
        (((this.userForm.get('telephone').value).replace('_', '')).length === 10));
  }

  checkPageIfEmpty() {
    if (this.usersList.length === 1) {
      this.page = (this.page > 1) ? this.page - 1 : this.page;
    }
  }

  copyToken() {
    let copyText = document.querySelector("#input_token_aquity") as HTMLInputElement;
    copyText.select();
    document.execCommand("copy");
  }

  onPageChange(page: any): void {
    this.page = page;
    this.getUsersListe(this.page);
  }

  resetForm() {
    this.isRequesting = false;
    this.userForm.reset();
  }

  ngOnDestroy() {}
}
