import { Component, OnInit } from '@angular/core';
import {ConfirmService} from "@app/services";

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  message: string;
  action: any;

  constructor(private _confirm: ConfirmService) { }

  ngOnInit() {
    this._confirm.message$.subscribe(
      (data) => {
        if (data) {
          this.message = data.message;
          this.action = data.action;
        }
      }
    );
  }

  onConfirmYes(value: boolean) {
    this._confirm.answer = { value: value, action: this.action};
    this._confirm.closeConfirm();
  }

}
