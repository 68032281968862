import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Config} from '@app/config';

@Injectable({
  providedIn: 'root'
})
export class LogetteService {

  constructor(private _http: HttpClient) {
  }

  getLogettes(): Observable<any> {
    return this._http.get(Config.api.logette.get);
  }
}
