export class typeIncidentModel {
    public id_type_incident: number;
    public nom: string;
    public tag_mobile: string;
    public action: string;
    public img_name: string;
    public mutable: number;
    public a_traiter:number;
    public micro_incidents:any;
  
    constructor(incident: any) {
      this.id_type_incident = incident.id_type_incident;
      this.nom = incident.nom;
      this.action = incident.action;
      this.tag_mobile = incident.tag_mobile;
      this.mutable = incident.mutable;
      this.a_traiter = incident.a_traiter;
    }
  }
  