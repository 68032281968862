import {Component, OnDestroy, OnInit} from '@angular/core';
import {finalize, map} from 'rxjs/internal/operators';
import {BailleurService, ConfirmService, GardienService, UserService} from '@app/services';
import {BailleurModel, GardienModel} from '@app/models';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {RESPONSE, ROUTES} from '@app/config';
import {Unsubscribe} from '@app/decorators';
import * as moment from 'moment';
import {ToastrService} from 'ngx-toastr';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-gardien',
  templateUrl: './gardien.component.html',
  styleUrls: ['./gardien.component.scss']
})

@Unsubscribe()
export class GardienComponent implements OnInit, OnDestroy {
  gardiensList: GardienModel[];
  gardiensFiltredList: GardienModel[];
  bailleursList: BailleurModel[];
  isLoading = true;
  gardienPagination: any;
  listCommunes: any[];

  selectedGardien: GardienModel;
  gardiensFilter: FormGroup;
  subscription = new Subscription();
  etatsGardien:any[] = [{actif:1,label:'Actif'}, {actif:0,label:'Inactif'},{actif:-1,label:'En attente'}];
  page: number;
  ROUTES = ROUTES;

  constructor(
    private _gardienService: GardienService,
    private _toastr: ToastrService,
    private _userService: UserService,
    private _bailleurService : BailleurService,
    private _confirmService: ConfirmService,

    ) {}

  ngOnInit() {
    this.setGardienFormFilter();
    this.getCommunes();
    this.getBailleurs();
    this.getGardiensListe();
    //this.filterGardienList();
    this.triggerConfirmationEvent();
  }

  getCommunes() {
    this._userService.getCommunes().subscribe(
      data => this.listCommunes = data.payload
    );
  }

  getBailleurs() {
    this._bailleurService.getBailleursForSelect().subscribe(
      data => this.bailleursList = data.payload
    );
  }

  setGardienFormFilter() {
    this.gardiensFilter = new FormGroup({
      bailleur_id: new FormControl(''),
      etat: new FormControl(''),
      actif: new FormControl(''),
      commune: new FormControl(''),
      date: new FormControl('')
    });
  }

  resetForm() {
    this.gardiensFilter.setValue({
      bailleur_id : '',
      etat: '',
      actif: '',
      commune: '',
      date: '',
    });
    this.getGardiensListe();
  }

  onIsFilled(target) {
    switch (target) {
      case 'bailleur_id':
        return this.gardiensFilter.value.bailleur_id !== '' && this.gardiensFilter.value.bailleur_id;
      case 'commune':
        return this.gardiensFilter.value.commune !== '' && this.gardiensFilter.value.commune;
      case 'etat':
        return this.gardiensFilter.value.etat !== '' && this.gardiensFilter.value.etat;
      case 'date':
        return this.gardiensFilter.value.date !== '' && this.gardiensFilter.value.date;
      default:
        return true;
    }
  }

  onClearInput(target) {
    switch (target) {
      case 'date':
        this.gardiensFilter.patchValue({date: ''});
        break;
    }
    this.getGardiensListe();
  }

  getFilledControls(): number {
    let count = 0;
    count = (this.gardiensFilter.get('etat').value) ? count + 1 : count;
    count = (this.gardiensFilter.get('bailleur_id').value) ? count + 1 : count;
    count = (this.gardiensFilter.get('commune').value) ? count + 1 : count;
    count = (this.gardiensFilter.get('date').value) ? count + 1 : count;
    //count = (this.gardiensFilter.get('actif').value) ? count + 1 : count;
    return count;
  }

  getFilledControlsName(): string[] {
    let controls:any[] = [];
    if(this.gardiensFilter.get('bailleur_id').value) controls.push({'key':'bailleur_id','value':this.gardiensFilter.get('bailleur_id').value});
    if(this.gardiensFilter.get('commune').value) controls.push({'key':'commune','value':this.gardiensFilter.get('commune').value});
    if(this.gardiensFilter.get('date').value) controls.push({'key':'date','value':this.gardiensFilter.get('date').value});
    if(this.gardiensFilter.get('etat').value) controls.push({'key':'etat','value':this.gardiensFilter.get('etat').value});
    return controls;
  }

  getGardiensListe() {
    this.isLoading = true;

    const params = {...this.gardiensFilter.value};
    params['date'] = (this.gardiensFilter.value.date) ? moment(this.gardiensFilter.value.date, 'YYYY-MM-DD').format('YYYY-MM-DD') : null;
    params['detail'] = 1;

    this._gardienService.getGardiens(this.page, params)
      .pipe( finalize(() => this.isLoading = false) )
      .subscribe(
        response => {
          this.gardiensList = GardienModel.toArray(response.payload);
          this.gardiensList.sort((a, b) => (a.created_at < b.created_at) ? 1 : -1);
          this.filterGardienList();
        }
      );
  }

  filterGardienList(page = 1){
    this.page = page;
    let filters:any[] = this.getFilledControlsName();
    this.gardiensFiltredList = this.gardiensList;
    filters.forEach( item => {
     if(item.key == 'etat'){
       this.gardiensFiltredList = this.gardiensFiltredList.filter(x => x.actif === parseInt(item.value))
      }
     if(item.key == 'bailleur_id'){
      this.gardiensFiltredList = this.gardiensFiltredList.filter(x => x.id_bailleur ==  item.value)
     }
     if(item.key == 'date'){
      this.gardiensFiltredList = this.gardiensFiltredList.filter(x => moment(x.created_at, 'YYYY-MM-DD').format('YYYY-MM-DD') ==  moment(item.value, 'YYYY-MM-DD').format('YYYY-MM-DD'))
     }
     if(item.key == 'commune'){
      this.gardiensFiltredList = this.gardiensFiltredList.filter(x => x.communes.find( c => c.id_commune ==  item.value))
     }

    });

    this.gardienPagination = {
      id: 'old',
      itemsPerPage: 30,
      currentPage: this.page,
      totalItems: this.gardiensFiltredList.length
    };
  }

  exporterGardiens(){

    return this._gardienService.exporterGardiens().subscribe(
      response => {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        FileSaver.saveAs(blob, 'gardien-liste.xlsx');
      }
    );
  }

  validerDemande(gardien: GardienModel) {
    this.setSelectedGardien(gardien);
    this._confirmService.showConfirm('gardien.accepter', 'Êtes vous sûr de vouloir valider ce compte Gardien ? ');
  }

  refuserDemande(gardien: GardienModel) {
    this.setSelectedGardien(gardien);
    this._confirmService.showConfirm('gardien.refuser', 'Êtes vous sûr de vouloir rejeter ce compte Gardien ?');
  }

  activerCompte(gardien: GardienModel) {
    this.setSelectedGardien(gardien);
    this._confirmService.showConfirm('gardien.activer', 'Êtes vous sûr de vouloir activer ce compte Gardien ?');
  }

  desactiverCompte(gardien: GardienModel) {
    this.setSelectedGardien(gardien);
    this._confirmService.showConfirm('gardien.desactiver', 'Êtes vous sûr de vouloir désactiver ce compte Gardien ? Ce dernier ne pourra plus se connecter !');
  }

  triggerConfirmationEvent() {
    const request =  this._confirmService.answer$.subscribe((response) => {
      let query = null;
      if (response && response.action === 'gardien.accepter' && response.value) {
        //console.log('gardien.accepter')
        query = this._gardienService.accepterDemande(this.selectedGardien);
        this._confirmService.answer = null;
      }else if (response && response.action === 'gardien.refuser' && response.value) {
        //console.log('gardien.refuser')
        query = this._gardienService.refuserDemande(this.selectedGardien);
        this._confirmService.answer = null;
      }else if (response && response.action === 'gardien.activer' && response.value) {
        //console.log('gardien.activer')
        query = this._gardienService.activerCompte(this.selectedGardien);
        this._confirmService.answer = null;
      }else if (response && response.action === 'gardien.desactiver' && response.value) {
        //console.log('gardien.accepter')
        query = this._gardienService.desactiverCompte(this.selectedGardien);
        this._confirmService.answer = null;
      }

      if(query){
        query//.pipe(map((data) => this.handleResponse(data)))
        .subscribe(
          (data) => {this.handleResponse(data)},
          (error) => {this._toastr.error(error._response.message);}
        );
      }

    });

    this.subscription.add(request);
  }

  onPageChange(page) {
    this.filterGardienList(page);
  }

  handleResponse(data:any) {
    if(data._response.code == '200_00'){
      this._toastr.success('Enregistré');
      // /= new GardienModel(data.payload)
      this.gardiensList = this.gardiensList.map(x => {
        if(x.id == data.payload.id){
          //console.log('found',x,data.payload)
          return new GardienModel(data.payload);
        }
        return x;
      });
      this.filterGardienList(this.page);
    }else if(data._response.code == 'GRD404_00'){
      this._toastr.error(data.message);
    }else{
      this._toastr.error(data.message);
    }

  }

  ngOnDestroy() {
    // this.resetForm()
  }

  setSelectedGardien(gardien){
    this.selectedGardien = gardien;
  }

  getGardienStatClass(status){
    return status == -1 ? 'pending' : ( status == 1 ? 'done' : 'closed');
  }

  getGardienDate(gardien){
    return gardien.actif == -1 ? gardien.created_at : ( gardien.actif == 1 ? gardien.valider_le : gardien.inactif_le);
  }

  getGardienDateTitle(gardien){
    return gardien.actif == -1 ? 'Inscrit le' : ( gardien.actif == 1 ? 'Validé le' : 'Désactivé le');
  }

}
