import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeUnderscore'
})
export class RemoveUnderscorePipe implements PipeTransform {

  transform(value): any {
    var re = /_/gi; 
    return value.replace(re, " "); 
  }

}
