import {MISSIONS_STATUTS, USER_ROLES} from "@app/config";

export class TourneeModel {

  public id_tournee: number;
  public id_mission: number;
  public id_terminal: number;
  public id_depot_depart: number;
  public id_depot_arrive: number;
  public code: string;
  public terminal_libelle: string;
  public start_date: number;
  public end_date: string;
  public statut: string;
  public type: string;
  public arrive: any;
  public depart: any;
  public rdv: any;

  constructor(tournee: any) {
    this.id_tournee = tournee.id_tournee;
    this.id_mission = tournee.id_mission;
    this.arrive = tournee.arrive;
    this.depart = tournee.depart;
    this.id_terminal = tournee.id_terminal;
    this.terminal_libelle = tournee.terminal_libelle;
    this.id_depot_depart = tournee.id_depot_depart;
    this.id_depot_arrive = tournee.id_depot_arrive;
    this.code = tournee.code;
    this.start_date = tournee.start_date;
    this.end_date = tournee.end_date;
    this.statut = tournee.statut;
    this.type = tournee.type;
    this.rdv = tournee.rdv;
  }

  // Retourne une liste des moyens
  static ToTourneeArray(data: TourneeModel[] = []): TourneeModel[] {
    const tournees: TourneeModel[] = [];
    data.forEach(item => {
      const tournee: TourneeModel = new TourneeModel(item);
      tournees.push(tournee);
    });
    return tournees;
  }

  getStatut() {
    return MISSIONS_STATUTS.find(status => status.key === this.statut).value;
  }

  getTarget() {
    return MISSIONS_STATUTS.find(status => status.key === this.statut).target;
  }

  toJson() {
    return {
      id_tournee: this.id_tournee,
      id_mission: this.id_mission,
      id_terminal: this.id_terminal,
      terminal_libelle: this.terminal_libelle,
      id_depot_depart: this.id_depot_depart,
      id_depot_arrive: this.id_depot_arrive,
      code: this.code,
      start_date: this.start_date,
      end_date: this.end_date,
      statut: this.statut,
      type: this.type,
      rdv: this.rdv
    };
  }
}
