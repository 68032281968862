import {MOYEN_TYPES} from '@app/config';
import { Prestataire } from 'app/components/scheduler/models/prestataire';


export class MoyenModel {
  public id: number;
  public libelle: string;
  public type: string;
  public volume: string;
  public poids_max: string;
  public qte: number;
  public prestataires: Prestataire[];

  constructor(moyen: any) {
    this.id = moyen.id_moyen;
    this.libelle = moyen.libelle;
    this.type = moyen.type;
    this.volume = moyen.volume;
    this.poids_max = moyen.poids_max;
    this.qte = moyen.qte ? moyen.qte : 0;
    this.prestataires = moyen.prestataires && moyen.prestataires.length ? moyen.prestataires : [];
  }

  // Retourne une liste des moyens
  static ToMoyenArray(data: MoyenModel[] = []): MoyenModel[] {
    const moyens: MoyenModel[] = [];
    data.forEach(item => {
      const moyen: MoyenModel = new MoyenModel(item);
      moyens.push(moyen);
    });
    return moyens;
  }

  getType(types): string {
    let type=types.find(moyen => moyen.code === this.type);
    return type?type.label:'';
  }

  toJson() {
    return {
      id_moyen: this.id,
      libelle: this.libelle,
      type: this.type,
      volume: this.volume,
      poids_max: this.poids_max,
      id_prestataire: this.prestataires.length ? this.prestataires[0].id_prestataire : null
    };
  }
}
