import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Config} from '@app/config';
import {Observable} from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({providedIn: 'root'})

export class RdvService {

  constructor(private _http: HttpClient) {
  }

  getRdv(namespace: string, code_rdv: string, verification_email:string): Observable<any> {
    const route = this.getRouteByType(namespace);
    let checkEmailQueryParam = typeof verification_email === 'string' ? '&check_email='+verification_email : '';
    return this._http.get(route.get + '?code_rdv=' + code_rdv + checkEmailQueryParam);
  }

  getRdvs(page: number = 1, filter: any = {}): Observable<any> {
    return this._http.post(Config.api.rdv.all + '&page=' + page, filter);
  }

  getCommunesWithEncombrant(namespace: string): Observable<any> {
    const route = this.getRouteByType(namespace);
    return this._http.get(route.communes);
  }

  getAddressByCode(params: any): Observable<any> {
    const route = this.getRouteByType(params['namespace']);
    return this._http.post(route.adresses, params);
  }

  getDisponibilites(params: any): Observable<any> {
    const route = this.getRouteByType(params['namespace']);
    return this._http.post(route.disponibilite, params);
  }

  saveRdv(params: any): Observable<any> {
    const route = this.getRouteByType(params['namespace']);
    return this._http.post(route.save, params);
  }

  deleteRdv(params: any): Observable<any> {
    const route = this.getRouteByType(params['namespace']);
    return this._http.post(route.delete, params);
  }

  updateRdv(params: any): Observable<any> {
    const route = this.getRouteByType(params['namespace']);
    return this._http.post(route.update, params);
  }

  getRouteByType(type: string) {
    let route;
    switch (type) {
      case 'client':
        route = Config.api.rdv.client;
        break;
      case 'bo':
        route = Config.api.rdv.bo;
        break;
      case 'aquity':
        route = Config.api.rdv.aquity;
        break;
    }
    return route;
  }

  getRDVSurvey(rdv_code: any): Observable<any> {
    return this._http.get(Config.api.rdv.survey + '/' + rdv_code);
  }

  setRDVSurvey(params): Observable<any> {
    return this._http.post(Config.api.rdv.client.survey, params);
  }

  downloadPDF(params:any): Observable<any> {
    return this._http.post(Config.api.rdv.bo.printQrcode, params ,{
      responseType: "blob"
    })
    .pipe(map((res:any) => {
        return new Blob([res], {type: 'application/pdf'});
    }));
  }

  duplicateDisponibilites(params: any): Observable<any> {
    return this._http.post(Config.api.rdv.bo.duplicateDisponibilite, params);
  }

  checkRDVByCodeAndEmail(rdv_code: string,email:string): Observable<any> {
    return this._http.post(Config.api.rdv.client.checkRdbByEmail,{code : rdv_code , email : email});
  }

  uploadImgDepot(params: any): Observable<any> {
    return this._http.post(Config.api.rdv.client.img_depot, params);
  }

}
