import {Component, OnDestroy, OnInit} from '@angular/core';
import {TerminalModel} from '@app/models';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {ConfirmService, PrestataireService, TerminalService} from '@app/services';
import {finalize, map} from 'rxjs/internal/operators';
import {closeAllModals, Required} from '@app/helpers';
import {RESPONSE} from '@app/config';
import {Unsubscribe} from '@app/decorators';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss']
})

@Unsubscribe()
export class TerminalComponent implements OnInit, OnDestroy {
  terminalsList: TerminalModel[];
  selectedTerminal: TerminalModel;
  isLoading = true;
  isRequesting = false;
  terminalForm: FormGroup;
  terminalPagination: any;
  imeiExist = false;
  subscription = new Subscription();
  prestatairesList: any = [];
  page: number;

  constructor(
    private _terminalService: TerminalService,
    private _prestataireService: PrestataireService,
    private _confirmService: ConfirmService
  ) {
  }

  ngOnInit() {
    this.triggerConfirmationEvent();
    this.setTerminalForm();
    this.getTerminalsListe();
    this.getPrestataires();
  }

  triggerConfirmationEvent() {
    const request =  this._confirmService.answer$.subscribe((response) => {
      if (response && response.action === 'terminal.delete' && response.value) {
        this.deleteTerminal(this.selectedTerminal.id);
        this._confirmService.answer = null;
      }
    });
    this.subscription.add(request);
  }

  setTerminalForm() {
    if (this.selectedTerminal === undefined) {
      this.terminalForm = new FormGroup({
        id_terminal: new FormControl(''),
        libelle: new FormControl('', [Required.validator]),
        id_prestataire: new FormControl('', [Required.validator]),
        iemi: new FormControl('', [Required.validator]),
      });
    } else if (this.selectedTerminal === null) {
      const emptyTerminal = {
        id_terminal: '',
        libelle: '',
        id_prestataire: '',
        iemi: ''
      };
      this.terminalForm.setValue(emptyTerminal);
    } else {
      this.terminalForm.setValue(this.selectedTerminal.toJson());
    }
  }

  getTerminalsListe(page = 1) {
    this.isLoading = true;
    this.page = page;
    const request =  this._terminalService.getTerminals(this.page)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        (response) => {
          this.terminalsList = TerminalModel.ToTerminalArray(response.payload.data);
          this.terminalPagination = {
            id: 'old',
            itemsPerPage: response.payload.per_page,
            currentPage: response.payload.current_page,
            totalItems: response.payload.total
          };
        }
      );
    this.subscription.add(request);
  }

  getPrestataires() {
    const request =  this._prestataireService.getPrestatairesForSelect()
      .subscribe(data => this.prestatairesList = data.payload);
    this.subscription.add(request);
  }

  setSelectedTerminal(terminal: any = null) {
    this.isRequesting = false;
    this.selectedTerminal = terminal ? terminal as TerminalModel : null;
    this.setTerminalForm();
  }

  saveTerminal() {
    this.isRequesting = true;
    const params = this.terminalForm.value;
    let request;
    if (this.selectedTerminal) {
      request = this._terminalService.updateTerminal(params);
    } else {
      delete params['id_terminal'];
      request = this._terminalService.createTerminal(params);
    }

    const ask =  request
      .pipe(
        map((data: any) => {
          this.handleResponse(data._response);
        })
      )
      .subscribe(
        () => {
        },
        (error) => {
          this.isRequesting = false;
          this.handleError(error);
        }
      );
    this.subscription.add(ask);
  }

  handleError(error) {
    this.imeiExist = error._response.code === RESPONSE.TERMINAL.ERROR.EXIST;
    this.isRequesting = false;
  }

  deleteTerminal(id) {
    const params = {id_terminal: id};
    const request =  this._terminalService.deleteTerminal(params)
      .pipe(
        map((data) => {
          this.handleResponse(data._response);
        })
      )
      .subscribe();
    this.subscription.add(request);
  }

  handleResponse(response) {
    if ([RESPONSE.TERMINAL.OK.CREATE, RESPONSE.TERMINAL.OK.UPDATE, RESPONSE.TERMINAL.OK.DELETE].indexOf(response.code) !== -1) {
      if (RESPONSE.TERMINAL.OK.DELETE === response.code) {
        this.checkPageIfEmpty();
      }
      this.getTerminalsListe(this.page);
      closeAllModals();
      this.resetForm();
    }
  }

  showConfirm(terminal: TerminalModel) {
    this.setSelectedTerminal(terminal);
    this._confirmService.showConfirm('terminal.delete', 'Êtes vous sûr de vouloir supprimer cet élement ?');
  }

  isFormInvalid() {
    return this.terminalForm.invalid || !this.terminalForm.dirty;
  }

  checkPageIfEmpty() {
    if (this.terminalsList.length === 1) {
      this.page = (this.page > 1) ? this.page - 1 : this.page;
    }
  }

  copyToken() {
    let copyText = document.querySelector("#input") as HTMLInputElement;
    copyText.select();
    document.execCommand("copy");
  }

  onPageChange(page: any): void {
    this.page = page;
    this.getTerminalsListe(this.page);
  }

  resetForm() {
    this.imeiExist = false;
    this.terminalForm.reset();
  }

  ngOnDestroy() {}
}
