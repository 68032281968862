import {Component} from '@angular/core';
import {RouterService} from '@app/services';
import {HeaderSetterHelper, NavigationHelper} from '@app/helpers';
import {ActivatedRouteSnapshot} from '@angular/router';
import {ROUTES} from '@app/config';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  hasLayout: boolean;
  is404: boolean;

  constructor(private _route: RouterService,
              public _header: HeaderSetterHelper,
              private _navigationHelper: NavigationHelper
  ) {
    this._header.setTitleAndHeader();
    this.clearComponentsForAuth();
  }

  clearComponentsForAuth() {
    this._route.onRouterChangeOrLoad().subscribe(
      (route: ActivatedRouteSnapshot) => {
        this.removeBackdrop();
        if (route.data.route !== undefined) {
          this.is404 = (route.data.route === ROUTES['404'].name);
          this.hasLayout = ([
            ROUTES['login'].name,
            ROUTES['send'].name,
            ROUTES['reset'].name,
            ROUTES['rdv.client'].name,
            ROUTES['rdv.aquity'].name,
            ROUTES['rdv.address'].name
          ].indexOf(route.data.route) !== -1);
        }
      });
  }

  redirectToHome() {
    this._navigationHelper.navigateTo(ROUTES['tour'].name);
  }

  removeBackdrop() {
    $('.modal').modal('hide');
    $( ".modal-backdrop" ).remove();
    $( ".in" ).remove();
    $( ".dhx_cal_cover" ).remove();
  }
}
