// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
     //url:'https://recycle-api.devcorp.fr/api/',
    //url:'https://recycle-api.valleesud.fr/api/', //PROD
    url:'http://valleesud.lc/api/', //DEV
    menuGardienVisible : true,
    cryptoKey: 'DEVCORPTvbsn)NqAa95^3Ud',
    GoogleMapApiKey : 'AIzaSyBzUas_w6hqJ20KjCOCazpW8FoTDk35wAg',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
