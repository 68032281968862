import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config, MENU, TOKEN_SlUG, USER_SlUG} from '@app/config';
import {Observable} from 'rxjs';
import {LocalStorageHelper} from '@app/helpers';
import {UserModel} from '@app/models';

@Injectable()

export class AuthService {

  constructor(private _http: HttpClient) {
  }

  login(params: any): Observable<any> {
    return this._http.post(Config.api.auth.login, params);
  }

  setToken(token: string) {
    LocalStorageHelper.setItem(TOKEN_SlUG, token);
  }

  setUser(user: UserModel) {
    LocalStorageHelper.setItem(USER_SlUG, user);
  }

  getToken(): string {
    return LocalStorageHelper.getItem(TOKEN_SlUG);
  }

  getAccount(): Observable<any> {
    return this._http.post(Config.api.user.get, {});
  }

  getUser(): UserModel {
    return new UserModel(LocalStorageHelper.getItem(USER_SlUG));
  }

  canAccess(roles: any[]): boolean {
    const user = this.getUser();
    return roles.indexOf(user.role) !== -1;
  }
}
