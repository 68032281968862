import { formatCurrency } from '@angular/common';
import { Output,EventEmitter, Component,Input } from '@angular/core';
import * as moment from 'moment';
import { RdvService } from '../../../services/api/rdv.service';

declare var $: any;

@Component({
  selector: 'app-duplicate-dispo-modal',
  templateUrl: './duplicate-dispo-modal.component.html',
  styleUrls: ['./duplicate-dispo-modal.component.css']
})
export class DuplicateDispoModalComponent {
  @Input() minDate;
  checkedDispos=[];
  dateDebut;
  dateFin;
  type;
  errorDateDebutAndDateFin = false;
  errorDateDebutAndMaxDateSelected = false;
  excludedNames = [];
  confirmButton = false;
  msgMaxDate;
  @Output() eventDuplicate = new EventEmitter();

  ngOnInit() {
  }

  constructor(private rdvService: RdvService){

  }

  setDayCheck(day:number){
     return this.minDate.clone().add(day, 'days').format('YYYY-MM-DD');
  }

  saveChecked(day){
    if(this.checkedDispos.indexOf(day)==-1){
      this.checkedDispos.push(day);
    }else {
      const indexDispo = this.checkedDispos.indexOf(day);
      this.checkedDispos.splice(indexDispo, 1);
    }
  }

  checkDates(){
    this.checkedDispos = this.checkedDispos.sort();
    let maxDate = moment(this.checkedDispos[this.checkedDispos.length-1]).format('YYYY-MM-DD');

    let dateDebut=moment(this.dateDebut);
    let dateFin=moment(this.dateFin);
    this.errorDateDebutAndDateFin = false;
    if(dateDebut >= dateFin){
      this.errorDateDebutAndDateFin =  true;
    }else{
      this.errorDateDebutAndDateFin =  false;
    }
    let now = moment();
   if(dateDebut.diff(this.setDayCheck(6), 'days') <= 0 || dateDebut.diff(now, 'days') < 0){
    this.errorDateDebutAndMaxDateSelected = true
    console.log(dateDebut.diff(maxDate, 'days'));
    console.log(dateDebut.diff(now, 'days'));
    
    console.log(this.checkedDispos.sort());
    console.log('CHECK', now.diff(maxDate, 'days'));
    this.msgMaxDate = (now.diff(this.setDayCheck(6), 'days') <= 0 ) ? moment(this.setDayCheck(6)).format('DD MMM YYYY') : "d'aujourd'hui";
   }else{
    this.errorDateDebutAndMaxDateSelected = false;
   }

   console.log("hhh",this.errorDateDebutAndDateFin && this.errorDateDebutAndMaxDateSelected)
   console.log(this.validDates());

    return this.errorDateDebutAndDateFin && this.errorDateDebutAndMaxDateSelected;
  }

  getExcludedDates(){

    var distinationNames = [];
    var selectedNames = [];

    var currDate = moment(this.dateDebut).startOf('day');
    var lastDate = moment(this.dateFin).startOf('day');
    distinationNames.push(moment(this.dateDebut).format('dddd'));

    while(currDate.add(1, 'days').diff(lastDate) < 0) {
      distinationNames.push(currDate.format('dddd'));
    }

    distinationNames.push(moment(this.dateFin).format('dddd'));
    console.log(distinationNames);

    this.checkedDispos.forEach(date => {
      selectedNames.push(moment(date).format('dddd'));
    });

    selectedNames.forEach(selected => {
      if(distinationNames.indexOf(selected) == -1){
        this.excludedNames.push(selected);
      }
    });

    // distinationNames.forEach(distination => {
    //   if(selectedNames.indexOf(distination) == -1){
    //     this.excludedNames.push(distination);
    //   }
    // });
  }

  validDates(){
    return true;
    // let dateDebut=moment(this.dateDebut);
    // let dateFin=moment(this.dateFin);
    // return dateDebut.format('YYYY-MM-DD')<=dateFin.format('YYYY-MM-DD') && this.checkedDispos && (dateFin.diff(dateDebut, 'days')+1)>=this.checkedDispos.length && dateDebut.format('YYYY-MM-DD')>moment().format('YYYY-MM-DD') && dateFin.diff(dateDebut, 'months')<=2;
  }

  duplicateDispos(fromConfirm){
    this.getExcludedDates();

    if(this.excludedNames.length && !fromConfirm){
      this.confirmButton = true;
    }else{
      let params={
        'start_date':moment(this.dateDebut).format('YYYY-MM-DD'),
        'end_date':moment(this.dateFin).format('YYYY-MM-DD'),
        'days':this.checkedDispos
     };
     this.rdvService.duplicateDisponibilites(params).subscribe(
       response => {
         this.eventDuplicate.emit(true);
         this.initialize();
       },error => {
         this.eventDuplicate.emit(false);
         this.initialize();
       }
     );
    }

    
  }

  capitaliseDate(date:string){
      let dateArray=date.split(' ');
      if(dateArray[2]){
        dateArray[2]=dateArray[2].charAt(0).toUpperCase() + dateArray[2].substr(1,2);
        date=dateArray.join(' ');
      }
      return date.charAt(0).toUpperCase() + date.substr(1);
  }

  getType(event){
    if(event.srcElement.checked){
      $(event.target.value=='PLANIFIEE'?'#fixeCheckbox':'#planifieeCheckbox')[0].checked=false;
      this.type=event.target.value;
    }else{
      this.type=null;
    }
  }

  initialize(){
    this.confirmButton = false;
    this.excludedNames = [];
    this.checkedDispos=[];
    this.dateDebut=null;
    this.dateFin=null;
    this.type=null;
    this.msgMaxDate=null;
    /*$('#planifieeCheckbox')[0].checked=false;
    $('#fixeCheckbox')[0].checked=false;*/
    let dispos=[0,1,2,3,4,5,6];
    dispos.forEach(key=>{
      $('#day'+key)[0].checked=false;
    });
  }
  
}